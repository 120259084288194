import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { storage } from "../firebase";

/**
 * Retrieves the download URLs for the given image.
 * @param {Object} image - The image object.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the download URLs.
 */
export async function getDownloadUrlsFromImage(image) {
  if (image && image.url) {
    try {
      const imageRef = ref(storage, image.url); // Create a reference to the image
      try {
        await getMetadata(imageRef); // Check if the image reference is valid
      } catch (error) {
        console.error(
          `Error getting download URL for image path ${image.url}: Image reference is invalid.`,
          error
        );
        return { success: false, failureType: "invalidRef" };
      }
      const downloadUrl = await getDownloadURL(imageRef);
      const thumbnailUrl = getImageThumbnailUrl(image.url, "small");
      const thumbnailRef = ref(storage, thumbnailUrl);
      let thumbnailDownloadUrl;
      while (true) {
        try {
          thumbnailDownloadUrl = await getDownloadURL(thumbnailRef);
          break; // If the thumbnail exists, break the loop
        } catch (error) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before trying again
        }
      }
      const sessionThumbnailUrl = getImageThumbnailUrl(image.url, "large");
      const sessionThumbnailRef = ref(storage, sessionThumbnailUrl);
      let sessionThumbnailDownloadUrl;
      while (true) {
        try {
          sessionThumbnailDownloadUrl = await getDownloadURL(
            sessionThumbnailRef
          );
          break; // If the session thumbnail exists, break the loop
        } catch (error) {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before trying again
        }
      }
      return {
        success: true,
        downloadUrl,
        thumbnailDownloadUrl,
        sessionThumbnailDownloadUrl,
      };
    } catch (error) {
      console.error(
        `Error getting download URL for image path ${image.url}:`,
        error
      );
      return { success: false, failureType: "fetchError" };
    }
  }
}

/**
 * Generates the URL for a thumbnail image based on the original image URL.
 * @param {string} url - The URL of the original image.
 * @param {string} [thumbnailType="small"] - The type of thumbnail to generate. Possible values are "small" and "large".
 * @returns {string} The URL of the thumbnail image.
 */
export function getImageThumbnailUrl(url, thumbnailType = "small") {
  const index = url.lastIndexOf("/");
  const filename = url.slice(index + 1);
  const extensionIndex = filename.lastIndexOf(".");
  const extension = filename.slice(extensionIndex);
  const thumbnailSize = thumbnailType === "small" ? "200x204" : "400x350";
  const thumbnailsFolder =
    thumbnailType === "small" ? "thumbnails" : "thumbnails-session";
  const thumbnailUrl = `${url.slice(
    0,
    index + 1
  )}${thumbnailsFolder}/${filename.replace(
    extension,
    `_${thumbnailSize}${extension}`
  )}`;
  return thumbnailUrl;
}

export default {
  getDownloadUrlsFromImage,
  getImageThumbnailUrl,
};
