import logo from "../images/sidebar/logo.svg";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth, analytics } from "../context/AuthContext";
import { logEvent } from "@firebase/analytics";
import logo_small from "../images/sidebar/logo_small.svg";
import storage from "../images/sidebar/storage.svg";
import storageActive from "../images/sidebar/storageActive.svg";
import expand from "../images/sidebar/expand.svg";
import dashboard from "../images/sidebar/dashboard.svg";
import dashboardActive from "../images/sidebar/dashboardActive.svg";
import collapse from "../images/sidebar/collapse.svg";
import exit from "../images/login/exit.svg";
import profile from "../images/login/profile.svg";
import profileActive from "../images/sidebar/profileActive.svg";
import subscription from "../images/sidebar/Star.svg";
import { UploadContext } from "./App";
import { Link } from "react-router-dom";
import UploadModal from "./modals/uploadModal";
import "../style/feedback_modal.css";
import DownloadModal from "./modals/ModalDownloadAllSessionFiles";
import { VERSION_NUMBER } from "../version_number";
import firestoreRepository, {
  getUserData,
} from "../repositories/firestoreRepository";

const iconStyle = {
  height: "30px",
  width: "30px",
  marginLeft: "1.5em",
};

const iconStyleCollapsed = {
  height: "30px",
  width: "30px",
};

const Layout = ({ children }) => {
  const [expanded, setExpanded] = useState(() => {
    const storedValue = localStorage.getItem("isExpanded");
    return storedValue ? JSON.parse(storedValue) : true;
  });
  const { downloadProgress, setDownloadProgress } = useContext(UploadContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  const [userData, setUserData] = useState([]);
  const { logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  const { sideBarWidth, setSideBarWidth } = useContext(UploadContext);
  const { uploadProgress, setUploadProgress } = useContext(UploadContext);

  useEffect(() => {
    setSideBarWidth(expanded ? "70px" : "200px");
  }, [expanded]);

  useEffect(() => {
    localStorage.setItem("isExpanded", JSON.stringify(expanded));
  }, [expanded]);

  useEffect(() => {
    loadUserData(currentUser.uid).then((data) => {
      setUserData(data);
    });
    const handleResize = () => {
      setIsMobile(window.innerWidth < 850);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const isActive = (path) => {
    return location.pathname === path ||
      location.pathname.startsWith(`${path}/`)
      ? "active"
      : "";
  };

  const getIconDashboard = (path) => {
    return isActive("/") || isActive("/sessions")
      ? activeIconDashboard
      : defaultIconDashboard;
  };

  const getIconStorage = (path) => {
    return isActive(path) ? activeIconStorage : defaultIconStorage;
  };

  const getIconProfile = (path) => {
    return isActive(path) ? activeIconProfile : defaultIconProfile;
  };

  async function handleAccount() {
    try {
      logEvent(analytics, "user_logout", {
        button_name: "user_logout",
      });

      await logout();
      sessionStorage.removeItem("scrollPosition");
      sessionStorage.removeItem("startSessionIdx");
      sessionStorage.removeItem("endSessionIdx");
      sessionStorage.removeItem("persistSearchParams");
      sessionStorage.removeItem("persistQueryIdx");
      localStorage.removeItem("isClosed");
      localStorage.removeItem("isCollapsed");
      setUploadProgress([]);
      setDownloadProgress([]);
      navigate("/login");
    } catch {}
  }

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const defaultIconDashboard = (
    <img
      src={dashboard}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconDashboard = (
    <img
      src={dashboardActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  const defaultIconStorage = (
    <img
      src={storage}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconStorage = (
    <img
      src={storageActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  const defaultIconProfile = (
    <img
      src={profile}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Default Icon"
    />
  );
  const activeIconProfile = (
    <img
      src={profileActive}
      style={expanded ? iconStyle : iconStyleCollapsed}
      alt="Active Icon"
    />
  );

  return (
    <div className="global-wrapper">
      <header style={!expanded ? { width: "70px" } : {}}>
        {expanded ? (
          <>
            <div className="full_width">
              <div className="logo-container">
                <Link to="/">
                  <img src={logo} className="logo-image" />
                </Link>
                <button
                  variant="link"
                  className="button_no_style"
                  onClick={() => {
                    setTimeout(() => {
                      setExpanded(false);
                    }, 300);
                  }}
                >
                  <img className="fixed-width-img" src={collapse}></img>
                </button>
              </div>
              <Link
                to="/"
                className={`sidebar ${
                  isActive("/") || isActive("/sessions")
                } link-margin-top`}
              >
                {getIconDashboard("/")}
                <div className="add_margin_left">Dashboard</div>
              </Link>
              <Link to="/storage" className={`sidebar ${isActive("/storage")}`}>
                {getIconStorage("/storage")}
                <div className="add_margin_left">Cloud Storage</div>
              </Link>
            </div>
            <div className="full_width ">
              <hr className="dark-hr"></hr>
              <Link to="/account" className={`sidebar ${isActive("/account")}`}>
                {getIconProfile("/account")}
                <div className="add_margin_left">My Profile</div>
              </Link>
              <Link to="/manage" className={`sidebar ${isActive("/manage")}`}>
                <img src={subscription} style={iconStyle} alt="Default Icon" />
                <div className="add_margin_left">Subscription</div>
              </Link>
              <button
                variant="link"
                className="button_no_style sidebar button-margin-padding"
                onClick={() => {
                  handleAccount();
                }}
              >
                <img style={iconStyle} src={exit}></img>
                <div className="add_margin_left">Log out</div>
              </button>
              <div className="version_text">version {VERSION_NUMBER}</div>{" "}
            </div>
          </>
        ) : (
          <>
            <div className="full_width">
              <div className="sidebar sidebar-centered">
                <Link to="/">
                  <img src={logo_small} className="fixed-dimension-img"></img>
                </Link>
              </div>{" "}
              <button
                variant="link"
                className="button_no_style sidebar sidebar-centered"
                onClick={() => {
                  setTimeout(() => {
                    setExpanded(true);
                  }, 300);
                }}
              >
                <img className="fixed-dimension-img-40" src={expand}></img>
              </button>
              <Link
                to="/"
                className={`sidebar sidebar-centered ${
                  isActive("/") || isActive("/sessions")
                }`}
              >
                {getIconDashboard("/")}
              </Link>
              <Link
                to="/storage"
                className={`sidebar ${isActive("/storage")}`}
                style={{ justifyContent: "center" }}
              >
                {getIconStorage("/storage")}
              </Link>
            </div>
            <div className="full_width">
              <hr className="dark-hr"></hr>
              <Link
                to="/account"
                className={`sidebar ${isActive("/account")}`}
                style={{ justifyContent: "center" }}
              >
                {getIconProfile("/account")}
              </Link>
              <Link
                to="/manage"
                className={`sidebar ${isActive("/manage")}`}
                style={{ justifyContent: "center" }}
              >
                <img
                  src={subscription}
                  style={iconStyleCollapsed}
                  alt="Default Icon"
                />
              </Link>
              <button
                variant="link"
                className="button_no_style sidebar  button-margin-center"
                onClick={() => {
                  handleAccount();
                }}
              >
                <img style={iconStyleCollapsed} src={exit}></img>
              </button>
            </div>
          </>
        )}
      </header>
      <main
        style={{ marginLeft: isMobile ? "70px" : expanded ? "200px" : "70px" }}
      >
        {children}

        <div className="fixed-div">
          <UploadModal />
          <DownloadModal />
        </div>
      </main>
    </div>
  );
};

export default Layout;
