import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../context/AuthContext";

export default function PrivateRoute({ component: Component, ...rest }) {
  const { currentUser } = useAuth();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  if (currentUser) {
    return <Outlet />;
  } else {
    const loginUrl = code ? `/login?code=${code}` : "/login";
    return <Navigate to={loginUrl} />;
  }
}
