import { db } from "../firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";

import { getDownloadUrlsFromImage } from "../utils/imageUtils";
import { getDownloadUrlsFromVideo } from "../utils/videoUtils";
import { getCanonicalPatientId } from "../utils/patientUtils";

const __getSessionsRef = (uid) => {
  return collection(db, `usersV2/${uid}/sessions/`);
};
const __getPatientsRef = (uid) => {
  return collection(db, `usersV2/${uid}/patients/`);
};
const __getImagesRef = (uid) => {
  return collection(db, `usersV2/${uid}/images/`);
};
const __getVideosRef = (uid) => {
  return collection(db, `usersV2/${uid}/videos/`);
};

/************** SESSIONS ******************/
export function querySessionsByQueryIdx(
  uid,
  queryIdx,
  numSessions,
  searchParams
) {
  let endDate =
    searchParams.dateRange && searchParams.dateRange[0].endDate
      ? new Date(searchParams.dateRange[0].endDate)
      : null;
  if (endDate) {
    endDate.setDate(endDate.getDate() + 1);
  }
  switch (queryIdx) {
    case 0:
      return queryUnfilteredSessions(uid, numSessions);
    case 1:
      return querySessionByPatientId(uid, numSessions, searchParams.patientId);
    case 2:
      return querySessionsByDate(
        uid,
        numSessions,
        searchParams.dateRange[0].startDate,
        endDate
      );
    case 3:
      return querySessionsByTag(uid, numSessions, searchParams.tags);
    case 4:
      return querySessionByDateAndPatientId(
        uid,
        numSessions,
        searchParams.patientId,
        searchParams.dateRange[0].startDate,
        endDate
      );
    case 5:
      return querySessionsByDateAndTag(
        uid,
        numSessions,
        searchParams.dateRange[0].startDate,
        endDate,
        searchParams.tags
      );
    case 6:
      return querySessionsByPatientIdAndTag(
        uid,
        numSessions,
        searchParams.patientId,
        searchParams.tags
      );
    case 7:
      return querySessionsByDateAndPatientIdAndTag(
        uid,
        numSessions,
        searchParams.patientId,
        searchParams.dateRange[0].startDate,
        endDate,
        searchParams.tags
      );

    default:
      return queryUnfilteredSessions(uid, numSessions);
  }
}

export function queryUnfilteredSessions(uid, numSessions) {
  const sessionRef = __getSessionsRef(uid);
  return query(
    sessionRef,
    where("deleted", "==", false),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

export async function getSessionById(uid, sessionId) {
  const sessionRef = doc(
    db,
    "usersV2/" + `${uid}` + "/sessions/" + `${sessionId}`
  );
  // const sessionDocRef = doc(sessionRef, session.patientGlobalId);
  const sessionDoc = await getDoc(sessionRef);
  return sessionDoc.data();
}

export function querySessionsByDate(uid, numSessions, startDate, endDate) {
  const sessionsRef = __getSessionsRef(uid);
  return query(
    sessionsRef,
    where("deleted", "==", false),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

export function querySessionsByTag(uid, numSessions, filterTags) {
  const sessionsRef = __getSessionsRef(uid);
  return query(
    sessionsRef,
    where("deleted", "==", false),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

export async function querySessionByPatientId(uid, numSessions, patientId) {
  const patientQuery = await getDocs(
    queryPatientByCanonicalPatientId(uid, patientId)
  );
  let patientGlobalId = "";
  const sessionRef = __getSessionsRef(uid);
  if (patientQuery.docs.length === 0) {
    return null;
  } else {
    patientGlobalId = patientQuery.docs[0].id;
    return query(
      sessionRef,
      where("deleted", "==", false),
      where("patientGlobalId", "==", patientGlobalId),
      orderBy("createdAt", "desc"),
      limit(numSessions)
    );
  }
}

export function queryPatientByCanonicalPatientId(uid, patientId) {
  const canonicalPatientId = getCanonicalPatientId(patientId);
  const patientRef = __getPatientsRef(uid);
  return query(
    patientRef,
    where("canonicalPatientId", "==", canonicalPatientId),
    orderBy("createdAt", "desc")
  );
}
export async function querySessionByDateAndPatientId(
  uid,
  numSessions,
  patientId,
  startDate,
  endDate
) {
  const patientQuery = await getDocs(
    queryPatientByCanonicalPatientId(uid, patientId)
  );
  let patientGlobalId = "";
  if (patientQuery.docs.length === 0) {
    return null;
  } else {
    patientGlobalId = patientQuery.docs[0].id;
  }
  const sessionsRef = __getSessionsRef(uid);

  return query(
    sessionsRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

export function querySessionsByDateAndTag(
  uid,
  numSessions,
  startDate,
  endDate,
  filterTags
) {
  const sessionsRef = __getSessionsRef(uid);
  return query(
    sessionsRef,
    where("deleted", "==", false),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

export async function querySessionsByPatientIdAndTag(
  uid,
  numSessions,
  patientId,
  filterTags
) {
  const patientQuery = await getDocs(
    queryPatientByCanonicalPatientId(uid, patientId)
  );
  let patientGlobalId = "";

  if (patientQuery.docs.length === 0) {
    return null;
  } else {
    patientGlobalId = patientQuery.docs[0].id;
  }
  const sessionsRef = __getSessionsRef(uid);

  return query(
    sessionsRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

export async function querySessionsByDateAndPatientIdAndTag(
  uid,
  numSessions,
  patientId,
  startDate,
  endDate,
  filterTags
) {
  const patientQuery = await getDocs(
    queryPatientByCanonicalPatientId(uid, patientId)
  );
  let patientGlobalId = "";

  if (patientQuery.docs.length === 0) {
    return null;
  } else {
    patientGlobalId = patientQuery.docs[0].id;
  }
  const sessionsRef = __getSessionsRef(uid);

  return query(
    sessionsRef,
    where("deleted", "==", false),
    where("patientGlobalId", "==", patientGlobalId),
    where("createdAt", ">=", startDate),
    where("createdAt", "<=", endDate),
    where("tags", "array-contains-any", filterTags),
    orderBy("createdAt", "desc"),
    limit(numSessions)
  );
}

/************** IMAGES ******************/
export async function getImagesBySession(uid, session) {
  const imagesRef = __getImagesRef(uid);
  let images = [];
  await Promise.all(
    session.imageGlobalIds.map(async (imageId) => {
      const imageDocRef = doc(imagesRef, imageId);
      const imageDoc = await getDoc(imageDocRef);
      if (imageDoc.exists()) {
        let imageData = imageDoc.data();
        if (imageData && !imageData.deleted && imageData.isInCloudStorage) {
          try {
            if (
              !imageData.downloadURL ||
              !imageData.thumbnailURL ||
              !imageData.thumbnailSessionURL
            ) {
              imageData = await refetchDownloadUrls(uid, imageData);
            }
            if (imageData) images.push(imageData);
          } catch (error) {
            console.error("Error getting download URLs for image", error);
          }
        }
      }
    })
  );
  return images;
}

/************** VIDEOS ******************/
export async function getVideosBySession(uid, session) {
  const videosRef = __getVideosRef(uid);
  let videos = [];
  await Promise.all(
    session.videoGlobalIds.map(async (videoId) => {
      const videoDocRef = doc(videosRef, videoId);
      const videoDoc = await getDoc(videoDocRef);
      if (videoDoc.exists()) {
        let videoData = videoDoc.data();
        if (videoData && !videoData.deleted && videoData.isInCloudStorage) {
          try {
            if (
              !videoData.downloadURL ||
              !videoData.thumbnailURL ||
              !videoData.thumbnailSessionURL
            ) {
              videoData = await refetchDownloadUrls(uid, videoData);
            }
            if (videoData) videos.push(videoData);
          } catch (error) {
            console.error("Error getting download URLs for video", error);
          }
        }
      }
    })
  );
  return videos;
}

/************** PATIENTS ******************/
export async function getPatientBySession(uid, session) {
  const patientRef = __getPatientsRef(uid);
  const patientDocRef = doc(patientRef, session.patientGlobalId);
  const patientDoc = await getDoc(patientDocRef);
  return patientDoc.data();
}

export async function getAllPatientNames(uid) {
  const patientRef = __getPatientsRef(uid);
  const patientSnapshot = await getDocs(patientRef);
  const patientData = patientSnapshot.docs.map((doc) => doc.data());
  return patientData;
}

/************** TAGS ******************/
export async function getAllTags(uid) {
  const sessionsRef = __getSessionsRef(uid);
  const sessions = await getDocs(sessionsRef);
  let tags = [];
  sessions.forEach((session) => {
    const sessionData = session.data();
    if (sessionData.tags) {
      tags = tags.concat(sessionData.tags);
    }
  });
  return [...new Set(tags.filter((tag) => tag !== ""))];
}

/************** USERDATA ******************/
export async function getUserData(uid) {
  const userRef = doc(db, `usersV2/${uid}`);
  const userDoc = await getDoc(userRef);
  return userDoc.data();
}

export async function listenDeviceSyncChanges(
  uid,
  debounceTimerRef,
  lastDeviceSyncRef,
  setLastDeviceSync,
  loadData,
  queryIdx,
  startSessionIdx,
  endSessionIdx,
  searchParams
) {
  const userRef = doc(db, `usersV2/${uid}`);
  const unsubscribe = onSnapshot(userRef, (doc) => {
    if (doc.exists) {
      const userData = doc.data();
      const userSyncData = userData.lastDeviceSync;

      let hasChanged = false;

      // If a bad initialization causes lastDeviceSync to be undefined, we set it to the current userSyncData
      if (!lastDeviceSyncRef.current) {
        setLastDeviceSync(userSyncData);
      } else {
        // Check if any key-value pair has changed
        hasChanged = Object.keys(userSyncData).some((key) => {
          const userTimestamp = userSyncData[key]?.toDate().getTime();
          const lastTimestamp = lastDeviceSyncRef.current[key]
            ?.toDate()
            .getTime();
          return userTimestamp !== lastTimestamp;
        });
      }

      if (hasChanged) {
        // Clear the existing debounce timer if it exists
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        // Set a new debounce timer
        debounceTimerRef.current = setTimeout(() => {
          setLastDeviceSync(userSyncData);
          loadData(
            uid,
            queryIdx.current,
            startSessionIdx.current,
            endSessionIdx.current,
            searchParams.current
          );
        }, 3000); // Wait 3 seconds. Delay time defined heuristically to ensure thumbnails automatically display after upload
      }
    }
  });

  // Return the unsubscribe function to be called when needed
  return unsubscribe;
}

export async function listenDeviceSyncChangesSession(
  uid,
  id,
  debounceTimerRef,
  lastDeviceSyncRef,
  setLastDeviceSync,
  loadData
) {
  const userRef = doc(db, `usersV2/${uid}`);
  const unsubscribe = onSnapshot(userRef, (doc) => {
    if (doc.exists) {
      const userData = doc.data();
      const userSyncData = userData.lastDeviceSync;

      let hasChanged = false;

      // If a bad initialization causes lastDeviceSync to be undefined, we set it to the current userSyncData
      if (!lastDeviceSyncRef.current) {
        setLastDeviceSync(userSyncData);
      } else {
        // Check if any key-value pair has changed
        hasChanged = Object.keys(userSyncData).some((key) => {
          const userTimestamp = userSyncData[key]?.toDate().getTime();
          const lastTimestamp = lastDeviceSyncRef.current[key]
            ?.toDate()
            .getTime();
          return userTimestamp !== lastTimestamp;
        });
      }

      if (hasChanged) {
        // Clear the existing debounce timer if it exists
        if (debounceTimerRef.current) {
          clearTimeout(debounceTimerRef.current);
        }

        // Set a new debounce timer
        debounceTimerRef.current = setTimeout(() => {
          setLastDeviceSync(userSyncData);
          loadData(uid, id);
        }, 3000); // Wait 3 seconds. Delay time defined heuristically to ensure thumbnails automatically display after upload
      }
    }
  });

  // Return the unsubscribe function to be called when needed
  return unsubscribe;
}

/************** ACCESSCONNECT ******************/
export async function setConnectAccessed(uid) {
  const updateLastAccessed = async () => {
    try {
      const documentSnapshot = await getDoc(doc(db, "usersV2", uid));
      const data = documentSnapshot.data();
      if (data) {
        const {
          connectLastAccessed,
          connectFirstAccessed,
          connectAccessCount,
        } = data;
        if (
          (!connectFirstAccessed, !connectLastAccessed, !connectAccessCount)
        ) {
          await updateDoc(
            doc(db, "usersV2", uid),
            {
              connectLastAccessed: serverTimestamp(),
              connectAccessCount: 1,
              connectFirstAccessed: serverTimestamp(),
            },
            { merge: true }
          );
        }

        const lastAccessedPlusTwelveHours = new Date(
          connectLastAccessed.toDate()
        );
        lastAccessedPlusTwelveHours.setHours(
          lastAccessedPlusTwelveHours.getHours() + 12
        );

        if (lastAccessedPlusTwelveHours < new Date()) {
          await updateDoc(doc(db, "usersV2", uid), {
            connectLastAccessed: serverTimestamp(),
            connectAccessCount: data.connectAccessCount + 1,
          });
        }
      }
    } catch (error) {
      console.error("Error updating last accessed timestamp:", error);
    }
  };

  return updateLastAccessed();
}

/************** LAST DEVICE SYNC ******************/
export async function updateLastDeviceSync(uid) {
  const userRef = doc(db, "usersV2/" + uid);
  // Fetch the current document
  const docSnap = await getDoc(userRef);

  // If the document exists
  if (docSnap.exists()) {
    // Get the current data
    let data = docSnap.data();

    // If lastDeviceSync exists
    if (data.lastDeviceSync) {
      // Update the WEB timestamp
      data.lastDeviceSync.WEB = serverTimestamp();
    } else {
      // If lastDeviceSync does not exist, create it
      data.lastDeviceSync = {
        WEB: serverTimestamp(),
      };
    }
    // Update the document with the new data
    await updateDoc(userRef, data);
  }
}

/************** HELPERS ******************/
/**
 * Refetches the download URLs for the given asset and updates the Firestore document.
 * @param {string} uid - The user ID.
 * @param {Object} assetData - The asset data.
 * @returns {Promise<Object>} - A promise that resolves to the updated asset data.
 */
export async function refetchDownloadUrls(uid, assetData) {
  // Get the global ID of the asset
  const assetGlobalId = assetData.globalId;
  // Check if the asset is an image
  const isImage = assetGlobalId.includes("IMAGE");
  // Get the reference to the asset's collection
  const assetRef = isImage ? __getImagesRef(uid) : __getVideosRef(uid);
  // Get the reference to the asset's document
  const assetDocRef = doc(assetRef, assetGlobalId);
  // Get the download URLs for the asset
  const response = isImage
    ? await getDownloadUrlsFromImage(assetData)
    : await getDownloadUrlsFromVideo(assetData);

  if (response.success) {
    const updatedAssetData = {
      ...assetData,
      downloadURL: response.downloadUrl,
      thumbnailURL: response.thumbnailDownloadUrl,
      thumbnailSessionURL: response.sessionThumbnailDownloadUrl,
      modifiedAt: serverTimestamp(),
    };
    // Update the asset data in Firestore
    updateDoc(assetDocRef, updatedAssetData);
    return updatedAssetData;
  } else {
    return null; // Return null to skip rendering the asset
  }
}

export default {
  querySessionsByQueryIdx,
  getImagesBySession,
  getVideosBySession,
  getPatientBySession,
  getAllTags,
  getUserData,
  setConnectAccessed,
  getAllPatientNames,
  updateLastDeviceSync,
  listenDeviceSyncChanges,
  refetchDownloadUrls,
  getSessionById,
  listenDeviceSyncChangesSession,
};
