import React, { useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../context/AuthContext";
import bg1 from "../../images/login/bg1.svg";
import bg2 from "../../images/login/bg2.svg";
import logo from "../../images/dashboard/MicroREC Connect Logo (1).png";
import "../../style/login.css";

export default function Forgot() {
  const emailRef = useRef();
  const { resetPassword } = useAuth();
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setMessage("");
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current.value);
      setMessage("Check your inbox for further instructions");
    } catch {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  return (
    <div className="login_main">
      <div className="login">
        <div className="bg_login">
          <img src={bg1}></img>
          <img src={bg2}></img>
        </div>
        <div className="login_content">
          <img src={logo} className="logo_login"></img>
          <h2
            style={{
              textAlign: "center",
              marginBottom: "1em",
              fontWeight: "600",
              color: "#3D3838",
            }}
          >
            Reset Your Password
          </h2>
          {error && <Alert severity="error">{error}</Alert>}
          {message && <Alert severity="warning">{message}</Alert>}
          <form onSubmit={handleSubmit}>
            <div id="email">
              <input
                type="email"
                ref={emailRef}
                placeholder="Email"
                required
                className="email-input"
              ></input>
            </div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <button disabled={loading} type="submit" className="login-button">
                Continue
              </button>
            </div>
            <div className="link_forgot">
              {code ? (
                <a href={`/login?code=${code}`}>Login into your account</a>
              ) : (
                <a href="/login">Login into your account</a>
              )}{" "}
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
