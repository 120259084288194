import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem } from "@mui/material";
import {
  doc,
  getDocs,
  serverTimestamp,
  updateDoc,
  setDoc,
  collection,
} from "@firebase/firestore";
import { db } from "../../firebase";
import ModeEditIcon from "../../images/dashboard/edit.svg";
import { ClientJS } from "clientjs";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository from "../../repositories/firestoreRepository";
import { useLocation } from "react-router-dom";
import EditIcon from "../../images/gallery/edit.svg";
import Intercom from "@intercom/messenger-js-sdk";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalEditSession = (props) => {
  const {
    patientName,
    description,
    selectedTags,
    tags,
    userId,
    sessionId,
    reloadCallback,
  } = props;
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [tagsSelected, setTagsSelected] = useState(selectedTags);
  const [newTag, setNewTag] = useState("");
  const [updatedPatientName, setUpdatedPatientName] = useState(patientName);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [allTags, setAllTags] = useState(tags);
  const [patientIdDuplicate, setPatientIdDuplicate] = useState();

  const handleTextareaChangePatient = (e) => {
    setUpdatedPatientName(e.target.value);
  };

  const handleTextareaChangeDesc = (e) => {
    setUpdatedDescription(e.target.value);
  };

  const handleOptionClick = (option) => {
    const index = tagsSelected.indexOf(option);

    if (index === -1) {
      setTagsSelected([...tagsSelected, option]);
    } else {
      let newTagsSelected = [...tagsSelected]; // create a copy of the array
      newTagsSelected.splice(index, 1); // remove the item at the given index
      setTagsSelected(newTagsSelected); // update the state
    }
  };

  // useEffect(() => {
  //   setTagsSelected(selectedTags);
  //   setUpdatedPatientName(patientName);
  //   setUpdatedDescription(description);
  //   setAllTags(tags);
  // }, [selectedTags, patientName, description, tags]);

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(
        collection(db, "usersV2/" + `${userId}` + "/patients/")
      );
      var tempPatients = {};
      querySnapshot.forEach((patient) => {
        const globalId = patient.data().globalId;
        const canonicalId = patient.data().canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      setPatientIdDuplicate(tempPatients);
    };
    fetchData();
  }, []);

  function getNewPatientGlobalId() {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint().toString();
    const slicedFingerpring = fingerprint.slice(0, 8);
    const str = `${userId}`;
    const first2 = str.slice(0, 5);
    const d = new Date();
    const datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      "_" +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2) +
      ":" +
      ("0" + d.getSeconds()).slice(-2) +
      ":" +
      ("0" + d.getMilliseconds()).slice(-3);

    return (
      "PATIENT_WEB_" + `${first2}` + "-" + slicedFingerpring + "_" + datestring
    );
  }

  function updateAllFields() {
    const updateTime = serverTimestamp();
    // First we handle the update to the patientId.
    // If the new canonicalPatientId is already in the database, we update the session with the existing patientGlobalId.
    // If the new canonicalPatientId is not in the database, we create a new patient entry and update the session with the new patientGlobalId.
    const canonicalId = getCanonicalPatientId(updatedPatientName);
    const duplicatedPatient = Object.entries(patientIdDuplicate).find(
      ([_, value]) => value === canonicalId
    );
    if (duplicatedPatient) {
      const [duplicatedPatientGlobalId, _] = duplicatedPatient;
      const sessionRef = doc(
        db,
        "usersV2/" + `${userId}` + "/sessions/" + sessionId
      );
      const patientRef = doc(
        db,
        "usersV2/" + `${userId}` + "/patients/" + duplicatedPatientGlobalId
      );
      const sessionData = {
        patientGlobalId: duplicatedPatientGlobalId,
        description: updatedDescription,
        tags: tagsSelected,
        modifiedAt: updateTime,
      };
      updateDoc(sessionRef, sessionData, { merge: true });
      const patientData = {
        patientId: updatedPatientName,
        modifiedAt: updateTime,
      };
      updateDoc(patientRef, patientData, { merge: true });
    } else {
      // Create a new patient entry
      const newPatientGlobalId = getNewPatientGlobalId();
      const newPatientData = {
        patientId: updatedPatientName,
        modifiedAt: updateTime,
        createdAt: updateTime,
        globalId: newPatientGlobalId,
        deleted: false,
        canonicalPatientId: canonicalId,
      };
      const patientRef = doc(
        db,
        "usersV2/" + `${userId}` + "/patients/" + newPatientGlobalId
      );
      setDoc(patientRef, newPatientData);
      const sessionRef = doc(
        db,
        "usersV2/" + `${userId}` + "/sessions/" + sessionId
      );
      const sessionData = {
        patientGlobalId: newPatientGlobalId,
        description: updatedDescription,
        tags: tagsSelected,
        modifiedAt: updateTime,
      };
      updateDoc(sessionRef, sessionData);
    }
    firestoreRepository.updateLastDeviceSync(userId);
    window.Intercom("trackEvent", "edit-session");
    reloadCallback();
    handleClose(); // Close the modal
  }

  function addNewTag() {
    window.Intercom("trackEvent", "add-new-tag");

    const regex = /^\s+$/;
    if (regex.test(newTag) !== true && newTag !== "") {
      setAllTags((prevTags) => [...prevTags, newTag]);
      setTagsSelected([...tagsSelected, newTag]);
      setNewTag("");
    }
  }

  const optionStylesActive = {
    backgroundColor: "#8F4FFF",
    color: "white",
    fontSize: "15px",
    padding: "5px 10px",
    borderRadius: "10px",
    border: "1px solid #8F4FFF",
  };
  const optionStyles = {
    backgroundColor: "white",
    color: "#8F4FFF",
    fontSize: "15px",
    padding: "5px 10px",
    borderRadius: "10px",
    border: "1px solid #8F4FFF",
  };

  const handleTagStyle = (tags) => {
    if (tagsSelected.includes(tags)) {
      return optionStylesActive;
    } else {
      return optionStyles;
    }
  };

  const location = useLocation();
  const isSessionUrl = location.pathname.includes("sessions");

  function handleKeyDownTag(event) {
    const regex = /^\s+$/;
    if (regex.test(newTag) !== true && newTag !== "") {
      if (event.key === "Enter") {
        event.preventDefault();
        addNewTag();
      }
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  function handleEditSession() {
    setOpen(true);
  }

  return (
    <>
      <div>
        {isSessionUrl ? (
          <button
            className="button_no_style edit_session_button"
            onClick={() => handleEditSession()}
          >
            <img src={EditIcon} className="edit_session_icon" />
          </button>
        ) : (
          <MenuItem
            style={{
              display: "flex",
              borderTop: "1px solid #C4C4C4",
              justifyContent: "space-between",
            }}
            onClick={() => {
              handleEditSession();
            }}
          >
            <div className="menu_edit_button_text">Edit</div>
            <img src={ModeEditIcon} className="menu_edit_button_icon" />
          </MenuItem>
        )}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Edit Session
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "1em",
                      padding: "30px",
                    }}
                  >
                    <label className="label">Patient Id</label>
                    <div>
                      <textarea
                        value={updatedPatientName}
                        onKeyDown={handleKeyDown}
                        onChange={handleTextareaChangePatient}
                        data-patient-related
                      ></textarea>
                    </div>
                    <label className="label">Comment</label>
                    <div>
                      <textarea
                        value={updatedDescription}
                        onChange={handleTextareaChangeDesc}
                      ></textarea>
                    </div>
                    <label className="label">Tags</label>
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "1em",
                        marginTop: "1em",
                        display: "flex",
                        flexWrap: "wrap",
                        boxShadow: "0px 2px 8px rgba(58, 92, 157, 0.14)",
                        padding: "0px 20px",
                      }}
                    >
                      {allTags.map((tag, index) => (
                        <div
                          style={{
                            display: "flex",
                            marginRight: "0.5em",
                            marginBottom: "20px",
                          }}
                          className="margin_top_wrap"
                        >
                          <button
                            key={index}
                            className="button_no_style"
                            onClick={() => {
                              handleOptionClick(tag);
                            }}
                            style={handleTagStyle(tag)}
                          >
                            <div key={tag}>
                              {`${tag}`
                                ? `${tag}`.length > 24
                                  ? `${tag}`.slice(0, 24) + "..."
                                  : `${tag}`
                                : ""}
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>

                    <div
                      style={{
                        textAlign: "right",
                        marginTop: "1em",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "70%",
                          marginRight: "1em",
                        }}
                      >
                        <textarea
                          style={{
                            marginBottom: "1em",
                          }}
                          value={newTag}
                          onKeyDown={handleKeyDownTag}
                          onChange={(e) => setNewTag(e.target.value)}
                        ></textarea>
                      </div>

                      <button className="button_link" onClick={addNewTag}>
                        Add New Tag
                      </button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button className="button" onClick={updateAllFields}>
                        Save Changes
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ModalEditSession;
