import firebase from "firebase/compat/app";
import "firebase/compat/auth";

import {
  GoogleAuthProvider,
  signInWithPopup,
  OAuthProvider,
} from "firebase/auth";
import { getAnalytics, setUserProperties } from "firebase/analytics";
import {
  getFirestore,
  serverTimestamp,
  getDoc,
  doc,
  setDoc,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const app = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});
export const storage = getStorage(app);
export const db = getFirestore(app);
export const analytics = getAnalytics(app);
setUserProperties(analytics, {
  app: "Connect",
});

const googleProvider = new GoogleAuthProvider();
export const SignInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    const q = doc(db, "usersV2", user.uid);
    const docs = await getDoc(q);
    if (!docs.exists()) {
      await setDoc(doc(db, "usersV2", user.uid), {
        email: user.email,
        uid: user.uid,
        country: "",
        created: serverTimestamp(),
        firstName: user.displayName,
        isPersonalInfoShowed: true,
        lastName: "",
        modified: serverTimestamp(),
        newsletter: false,
        phoneModel: "Web",
        signinMethod: "google",
        speciality: "",
        stripeSubscriptionId: 0,
        stripeCustomerId: "",
      });
    } else {
    }
  } catch (err) {
    console.log(err);
  }
};

const provider = new OAuthProvider("apple.com");
export const signInWithApple = async () => {
  try {
    const res = await signInWithPopup(auth, provider);
    const user = res.user;
    const q = doc(db, "usersV2", user.uid);
    const docs = await getDoc(q);
    if (!docs.exists()) {
      await setDoc(doc(db, "usersV2", user.uid), {
        email: user.email,
        uid: user.uid,
        country: "",
        created: serverTimestamp(),
        firstName: user.displayName,
        isPersonalInfoShowed: true,
        lastName: "",
        modified: serverTimestamp(),
        newsletter: false,
        phoneModel: "Web",
        signinMethod: "apple",
        speciality: "",
        stripeSubscriptionId: 0,
        stripeCustomerId: "",
      });
    }
  } catch (err) {}
};

export const auth = app.auth();
export default app;
