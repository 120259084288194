import React, { useState } from "react";
import share_select from "../../images/gallery/share_select.svg";
import CloseIcon from "@mui/icons-material/Close";
import "../../style/sessionDetailedView.css";
import { Box, DialogContent, IconButton, Tooltip, styled } from "@mui/material";
import PropTypes from "prop-types";
import link from "../../images/gallery/link.svg";
import Dialog from "@mui/material/Dialog";
import link_sharing_white from "../../images/gallery/Share_white.svg";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Share = ({ multiselectedUrls, galleryVisible }) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [selectedShortLinks, setSelectedShortLinks] = useState([]);
  const generateShortLinks = async (multiselectedUrls) => {
    const shortLinksArray = [];
    for (const url of multiselectedUrls) {
      const response = await fetch(
        `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            dynamicLinkInfo: {
              domainUriPrefix: process.env.REACT_APP_FIREBASE_SHORT_LINK,
              link: url,
            },
          }),
        }
      );
      const data = await response.json();
      shortLinksArray.push(data.shortLink);
    }
    setSelectedShortLinks(shortLinksArray);
  };

  const formattedLinks = selectedShortLinks.join("\n");

  const CopyShortenLinks = () => {
    navigator.clipboard.writeText(formattedLinks);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2500);
  };

  const openShareModal = () => {
    generateShortLinks(multiselectedUrls);
    window.Intercom("trackEvent", "share-file");
    setOpen(true);
  };
  return (
    <>
      {galleryVisible ? (
        <Tooltip
          title="Share"
          slotProps={{
            popper: {
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [0, -14],
                  },
                },
              ],
            },
          }}
        >
          <IconButton
            size="medium"
            sx={{
              padding: "8px",
              color: "white",
              ":hover": {
                backgroundColor: "rgba(255, 255, 255, 0.08)",
              },
            }}
          >
            <button
              className="button_no_style"
              style={{
                color: "white",
                margin: "0",
                padding: "0",
              }}
              onClick={() => {
                openShareModal();
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <img
                  src={link_sharing_white}
                  style={{
                    maxWidth: "100%",
                    width: "24px",
                  }}
                />
              </div>
            </button>
          </IconButton>
        </Tooltip>
      ) : (
        <button
          className="button_no_style"
          onClick={() => {
            openShareModal();
          }}
        >
          <div className="share_div">
            <img className="height_40" src={share_select}></img>
            <div className="select_text">Share</div>
          </div>
        </button>
      )}

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={"sm"}
        fullWidth={true}
        alignItems="center"
        className="modal-sub"
        style={{ borderRadius: "30px", margin: "0" }}
      >
        <BootstrapDialogContent
          onClose={handleClose}
          dividers
          style={{
            backgroundColor: "#fff",
            padding: "20px",
          }}
        >
          <>
            <Box>
              <button
                className="button_no_style"
                onClick={() => {
                  setSelectedShortLinks([]);
                  setOpen(false);
                }}
              >
                <CloseIcon />
              </button>
              <div className="modal_header_div_select">
                <img src={link} className="width_25"></img>
                <div className="modal_subheader_text_select">Share</div>
              </div>
              <p
                style={{
                  textAlign: "center",
                }}
              >
                When you share files, you're also giving the recipients access
                to information about when and on which device the images were
                taken.
              </p>
              <div className="modal_selection_text">
                Copy the link below to share
              </div>
              <div className="modal_selection_links">
                <div className="modal_selection_links_text">
                  {formattedLinks.slice(0, 250)}...
                </div>
                <button
                  className="button_no_style modal_selection_links_button"
                  onClick={() => {
                    CopyShortenLinks(multiselectedUrls);
                  }}
                >
                  Copy
                </button>
              </div>{" "}
              {copied && (
                <div className="modal_selection_links_copied">
                  Link copied to clipboard
                </div>
              )}
            </Box>
          </>
        </BootstrapDialogContent>
      </BootstrapDialog>
    </>
  );
};
export default Share;
