import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { Box, Container, Grid, Modal, Tooltip } from "@mui/material";
import { useAuth, analytics } from "../../context/AuthContext";
import profile from "../../images/login/profile_black.svg";
import country from "../../images/login/flag.svg";
import profession from "../../images/login/profession.svg";
import email from "../../images/login/email.svg";
import "../../style/login.css";
import { db } from "../../firebase";
import google from "./google.svg";
import apple from "./apple.svg";
import password from "./password.svg";
import {
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";
import ChangePassword from "./change_password";
import lock from "./Lock.svg";
import deleteAcc from "./delete.svg";
import DeleteAccount from "./delete_account";

const styleee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  maxHeight: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  overflow: "auto",
  p: 4,
};

const Account = () => {
  const { currentUser } = useAuth();
  const [error, setError] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userCountry, setUserCountry] = useState("");

  const updateCountry = () => {
    const data = {
      country: userCountry,
      modified: serverTimestamp(),
    };

    const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

    updateDoc(collectionRef, data);
  };

  const [userEmail, setUserEmail] = useState("");

  const [userSpeciality, setUserSpeciality] = useState("");

  const updateSpeciality = () => {
    const data = {
      speciality: userSpeciality,
      modified: serverTimestamp(),
    };

    const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

    updateDoc(collectionRef, data);
  };

  const setModalData = (usersV2) => {
    setUserFirstName(usersV2.firstName);
    setUserLastName(usersV2.lastName);
    setUserCountry(usersV2.country);
    setUserEmail(usersV2.email);
    setUserSpeciality(usersV2.speciality);
  };

  const [openModal1, setOpenModal1] = React.useState(false);
  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };

  const [openModal2, setOpenModal2] = React.useState(false);
  const handleOpenModal2 = () => setOpenModal2(true);
  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const [openModal3, setOpenModal3] = React.useState(false);
  const handleOpenModal3 = () => setOpenModal3(true);
  const handleCloseModal3 = () => {
    setOpenModal3(false);
  };

  const [openModal4, setOpenModal4] = React.useState(false);
  const handleOpenModal4 = () => setOpenModal4(true);
  const handleCloseModal4 = () => {
    setOpenModal4(false);
  };

  const [userDataName, setUserDataName] = useState();
  const [userDataCountry, setUserDataCountry] = useState();
  const [userDataEmail, setUserDataEmail] = useState();
  const [userDataSpeciality, setUserDataSpeciality] = useState();

  const [fullName, setFullName] = useState("");
  const [userDataEmailAgain, setUserDataEmailAgain] = useState("");
  useEffect(() => {
    const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);
    const unsub = onSnapshot(collectionRef, (snapshot) => {
      const firstName = snapshot.data().firstName;
      const lastName = snapshot.data().lastName;
      const fullName = `${firstName} ${lastName}`;
      setUserDataName([firstName, lastName]);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      if (firstName && lastName) {
        setFullName(`${firstName} ${lastName}`);
      } else if (firstName) {
        setFullName(firstName);
      } else if (lastName) {
        setFullName(lastName);
      } else {
        setFullName("");
      }
      setUserDataCountry(snapshot.data().country);
      setUserDataEmail(snapshot.data().email);
      setUserDataEmailAgain(snapshot.data().email);
      setUserDataSpeciality(snapshot.data().speciality);
    });
    return unsub;
  }, [
    userFirstName,
    userLastName,
    userDataCountry,
    userDataEmail,
    userDataSpeciality,
  ]);

  const updateName = () => {
    if (fullName.includes(" ")) {
      const nameArray = fullName.split(" ");
      const data = {
        firstName: nameArray[0],
        lastName: nameArray.slice(1).join(" "),
        modified: serverTimestamp(),
      };
      const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

      updateDoc(collectionRef, data);
    } else {
      const data = {
        firstName: fullName,
        lastName: "",
        modified: serverTimestamp(),
      };
      const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

      updateDoc(collectionRef, data);
    }
  };

  const [countries, setCountries] = useState([]);

  useEffect(() => {
    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const sortedCountries = response.data.sort((a, b) => {
          if (a.name.common < b.name.common) return -1;
          if (a.name.common > b.name.common) return 1;
          return 0;
        });
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  const handleCountryChange = (event) => {
    setUserCountry(event.target.value);
  };

  const specialities = [
    "Ophthalmology",
    "Dentistry",
    "Orthopedics",
    "ENT",
    "Neurology",
    "Plastic Surgery",
    "Histopathology",
    "Cardiovascular",
    "Optometrist",
    "Sales Representative",
    "Clinical Specialist",
    "Other",
  ];

  const handleSpecialityChange = (event) => {
    setUserSpeciality(event.target.value);
  };

  const [usersV2, setUsersV2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    const fetchDocument = async () => {
      setIsLoading(true);

      const docRef = doc(db, "usersV2", currentUser.uid);
      const docSnapV2 = await getDoc(docRef);
      if (docSnapV2.exists()) {
        setUsersV2(docSnapV2.data());
      }
      setIsLoading(false);
    };
    fetchDocument();
  }, [currentUser.uid]);

  if (!isMounted) {
    return null;
  }

  if (isLoading) {
    return <Layout></Layout>;
  }

  if (!usersV2) {
    return <Layout></Layout>;
  }

  return (
    <>
      <Layout>
        <Container maxWidth="lg" style={{ marginTop: "2em" }}>
          {usersV2 && (
            <>
              <>
                <div style={{ fontWeight: "700", fontSize: "20px" }}>
                  My Profile
                </div>
                <div style={{ marginTop: "2em", fontWeight: "600" }}>
                  ACCOUNT INFORMATION
                </div>
                <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <div className="profile_flex">
                    <div
                      style={{
                        padding: "20px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={profile}
                          style={{ width: "24px", marginRight: "1em" }}
                        ></img>
                        <div style={{ fontWeight: "700" }}>Name</div>
                      </div>
                      <div
                        key={usersV2.firstName}
                        style={{
                          width: "100%",
                          borderRadius: "20px",
                          boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                          marginTop: "1em",
                        }}
                      >
                        <button
                          onClick={() => {
                            setModalData(usersV2.uid);
                            setOpenModal1(true);
                          }}
                          className="button_no_style account_data"
                        >
                          {fullName}
                        </button>
                        <Modal
                          open={openModal1}
                          onClose={handleCloseModal1}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={styleee}>
                            <div
                              style={{
                                backgroundColor: "#FDFDFD",
                                padding: "30px 0px",
                              }}
                            >
                              <label className="label">Your Full Name</label>
                              <textarea
                                value={fullName}
                                onChange={(e) => setFullName(e.target.value)}
                              ></textarea>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1em",
                                }}
                              >
                                <button
                                  className="button_no_style"
                                  onClick={() => {
                                    setOpenModal1(false);
                                  }}
                                >
                                  Cancel
                                </button>
                                {fullName ? (
                                  <button
                                    className="button_no_style"
                                    style={{
                                      color: "#8F4FFF",
                                      fontWeight: "600",
                                    }}
                                    onClick={() => {
                                      updateName(usersV2.uid);
                                      setOpenModal1(false);
                                    }}
                                  >
                                    Update
                                  </button>
                                ) : (
                                  <div>
                                    <button
                                      className="button_no_style"
                                      style={{ fontWeight: "600" }}
                                      onClick={() => {
                                        setError("Invalid full name!");
                                      }}
                                    >
                                      Update
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>{" "}
                            {!fullName && (
                              <div>
                                {error && (
                                  <div
                                    style={{
                                      marginTop: "0.5em",
                                      textAlign: "center",
                                      color: "red",
                                    }}
                                  >
                                    {error}
                                  </div>
                                )}
                              </div>
                            )}
                          </Box>
                        </Modal>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "20px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={country}
                          style={{ width: "24px", marginRight: "0.5em" }}
                        ></img>
                        <div style={{ fontWeight: "700" }}>Country</div>{" "}
                      </div>
                      <div
                        style={{
                          width: "100%",
                          borderRadius: "20px",
                          boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                          marginTop: "1em",
                        }}
                      >
                        <button
                          onClick={() => {
                            setModalData(usersV2.uid);
                            setOpenModal2(true);
                          }}
                          className="button_no_style account_data"
                        >
                          {userDataCountry}
                        </button>
                        <Modal
                          open={openModal2}
                          onClose={handleCloseModal2}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                          key={userDataCountry}
                        >
                          <Box sx={styleee}>
                            <div
                              style={{
                                backgroundColor: "#FDFDFD",
                                padding: "30px 0px",
                              }}
                            >
                              <label className="label">Your Country</label>
                              <div>
                                <select
                                  style={{
                                    padding: "8px",
                                    fontSize: "16px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    width: "100%",
                                    backgroundColor: "#FDFDFD",
                                    color: "#333",
                                    marginTop: "1em",
                                  }}
                                  onChange={handleCountryChange}
                                  value={userCountry}
                                >
                                  <option value={userDataCountry}>
                                    {userDataCountry}
                                  </option>
                                  {countries.map((country, index) => (
                                    <option
                                      key={index}
                                      value={country.name.common}
                                    >
                                      {country.name.common}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1em",
                                }}
                              >
                                <button
                                  className="button_no_style"
                                  onClick={() => {
                                    setOpenModal2(false);
                                  }}
                                >
                                  Cancel
                                </button>

                                <button
                                  className="button_no_style"
                                  style={{
                                    color: "#8F4FFF",
                                    fontWeight: "600",
                                  }}
                                  onClick={() => {
                                    updateCountry(usersV2.uid);
                                    setOpenModal2(false);
                                  }}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </Box>
                        </Modal>{" "}
                      </div>
                    </div>
                  </div>
                  <div className="profile_flex">
                    <div
                      style={{
                        padding: "20px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={email}
                          style={{ width: "24px", marginRight: "0.5em" }}
                        ></img>
                        <div style={{ fontWeight: "700" }}>Email</div>{" "}
                      </div>

                      <div
                        style={{
                          width: "100%",
                          borderRadius: "20px",
                          boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                          marginTop: "1em",
                        }}
                      >
                        <Tooltip
                          title="You can not change your email address"
                          arrow
                          placement="top"
                        >
                          <div
                            style={{
                              padding: "10px 20px",
                              overflowWrap: "break-word",
                              fontSize: "16px",
                            }}
                          >
                            {userDataEmail}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div
                      style={{
                        padding: "20px 0px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={profession}
                          style={{ width: "24px", marginRight: "0.5em" }}
                        ></img>
                        <div style={{ fontWeight: "700" }}>Occupation</div>
                      </div>
                      <div
                        key={usersV2.speciality}
                        style={{
                          width: "100%",
                          borderRadius: "20px",
                          boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                          marginTop: "1em",
                        }}
                      >
                        <button
                          onClick={() => {
                            setModalData(usersV2.uid);
                            setOpenModal4(true);
                          }}
                          className="button_no_style account_data"
                        >
                          {userDataSpeciality}
                        </button>

                        <Modal
                          open={openModal4}
                          onClose={handleCloseModal4}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box sx={styleee}>
                            <div
                              style={{
                                backgroundColor: "#FDFDFD",
                                padding: "30px 0px",
                              }}
                            >
                              <label className="label">Your speciality</label>
                              <div>
                                <select
                                  style={{
                                    padding: "8px",
                                    fontSize: "16px",
                                    borderRadius: "4px",
                                    border: "1px solid #ccc",
                                    outline: "none",
                                    width: "100%",
                                    backgroundColor: "#FDFDFD",
                                    color: "#333",
                                    marginTop: "1em",
                                  }}
                                  onChange={handleSpecialityChange}
                                  value={userSpeciality}
                                >
                                  <option value={userDataSpeciality}>
                                    {userDataSpeciality}
                                  </option>
                                  {specialities.map((speciality, index) => (
                                    <option key={index} value={speciality}>
                                      {speciality}
                                    </option>
                                  ))}
                                </select>
                              </div>

                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  marginTop: "1em",
                                }}
                              >
                                <button
                                  className="button_no_style"
                                  onClick={() => {
                                    setOpenModal4(false);
                                  }}
                                >
                                  Cancel
                                </button>

                                <button
                                  className="button_no_style"
                                  style={{
                                    color: "#8F4FFF",
                                    fontWeight: "600",
                                  }}
                                  onClick={() => {
                                    updateSpeciality(usersV2.uid);
                                    setOpenModal4(false);
                                  }}
                                >
                                  Update
                                </button>
                              </div>
                            </div>
                          </Box>
                        </Modal>
                      </div>
                    </div>
                  </div>{" "}
                  <div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      You login using{" "}
                      {usersV2.signinMethod === "google" ? (
                        <>
                          Google <img src={google}></img>
                        </>
                      ) : usersV2.signinMethod === "apple" ? (
                        <>
                          Apple ID{" "}
                          <img
                            src={apple}
                            style={{ marginLeft: "0.5em" }}
                          ></img>
                        </>
                      ) : (
                        <>
                          email & password
                          <img
                            src={password}
                            style={{ marginLeft: "0.5em" }}
                          ></img>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                {usersV2.signinMethod === "apple" ||
                usersV2.signinMethod === "google" ? (
                  ""
                ) : (
                  <div>
                    <div
                      style={{
                        marginTop: "2.5em",
                        fontWeight: "600",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <img src={lock} style={{ marginRight: "0.5em" }}></img>
                      <div>CHANGE PASSWORD</div>
                    </div>
                    <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                    <Grid
                      container
                      justifyContent={"space-between"}
                      style={{ marginTop: "1em" }}
                    >
                      <Grid item xs={12} md={5}>
                        <div>Do you need to update your password?</div>
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <ChangePassword />
                      </Grid>
                    </Grid>
                  </div>
                )}
                {/* <div>
                  <div
                    style={{
                      marginTop: "2.5em",
                      fontWeight: "600",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img src={deleteAcc} style={{ marginRight: "0.5em" }}></img>
                    <div>DELETE ACCOUNT</div>
                  </div>
                  <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    style={{ marginTop: "1em" }}
                  >
                    <Grid item xs={12} md={5}>
                      <div>
                        Would you like to delete your account? This action can
                        not be undone.
                      </div>
                    </Grid>
                    <Grid item xs={12} md={5}>
                      <DeleteAccount usersV2={usersV2} />
                    </Grid>
                  </Grid>
                </div> */}
              </>
            </>
          )}
        </Container>
      </Layout>
    </>
  );
};
export default Account;
