export function getCanonicalPatientId (patientId){
    const canonicalPatientId = patientId
        // Remove preceding and trailing white spaces
        .trim()
        // Remove capitalization
        .toLowerCase()
        // Replace multiple whitespaces in-between words to only one
        .replace(/\s+/g, " ");

    // Return the cleaned string
    return canonicalPatientId;
};

export default {
    getCanonicalPatientId,
};