import React, { useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import bg1 from "../../images/login/bg1.svg";
import bg2 from "../../images/login/bg2.svg";
import logo from "../../images/dashboard/MicroREC Connect Logo (1).png";
import "../../style/login.css";
import { Box, Modal } from "@mui/material";
import { fetchSignInMethodsForEmail } from "firebase/auth";
import { auth } from "../../firebase";

const styleee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  maxHeight: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  overflow: "auto",
  p: 4,
};

export default function SignUp() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const confirmRef = useRef();
  const { signup, logout } = useAuth();
  const [error, setError] = useState("");
  const [verifyError, setVerifyError] = useState("");
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");

  const [openModal1, setOpenModal1] = useState(false);
  const handleOpenModal1 = () => setOpenModal1(true);
  const handleCloseModal1 = async () => {
    await auth.currentUser.reload();

    if (auth.currentUser.emailVerified) {
      navigate(code ? `/information?code=${code}` : "/information");
    } else {
      setVerifyError("Email not verified. Please check your email.");
    }
  };

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");

      const userExists = await fetchSignInMethodsForEmail(
        auth,
        emailRef.current.value
      );

      const regularExpression = /^(?=.*[0-9\W])[a-zA-Z0-9\W]{6,32}$/;

      if (userExists.length > 0) {
        setError("A user with this email already exists. Please log in.");
      } else if (!regularExpression.test(confirmRef.current.value)) {
        setError(
          "The password must be from 6 to 32 characters long and contain at least 1 symbol or number."
        );
      } else if (confirmRef.current.value !== passwordRef.current.value) {
        setError("Passwords do not match. Please try again.");
      } else {
        await signup(emailRef.current.value, passwordRef.current.value);
        handleOpenModal1();
        setError("");
      }
    } catch (error) {
      setError("Failed to create account: " + error.message);
    }
  }

  return (
    <>
      <div className="login_main">
        <div className="login">
          <div className="bg_login">
            <img src={bg1}></img>
            <img src={bg2}></img>
          </div>
          <div className="login_content">
            <img src={logo} className="logo_login"></img>
            <h2
              style={{
                textAlign: "center",
                marginBottom: "1em",
                fontWeight: "600",
                color: "#3D3838",
              }}
            >
              Sign Up to see all of the benefits
            </h2>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <div id="email">
                <input
                  type="email"
                  ref={emailRef}
                  placeholder="Email"
                  required
                  className="email-input"
                ></input>
              </div>
              <div id="password">
                <input
                  type="password"
                  ref={passwordRef}
                  placeholder="Password"
                  required
                  className="email-input"
                ></input>
              </div>
              <div id="password">
                <input
                  type="password"
                  ref={confirmRef}
                  placeholder="Confirm password"
                  required
                  className="email-input"
                ></input>
              </div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <button
                  type="submit"
                  className="login-button"
                  // onClick={handleSubmit}
                >
                  Sign Up
                </button>
              </div>
            </form>{" "}
            <div
              style={{
                marginTop: "3em",
                textAlign: "center",
                color: "#797979",
              }}
            >
              Already have account?{" "}
              {code ? (
                <a href={`/login?code=${code}`} style={{ color: "#8F4FFF" }}>
                  Log In
                </a>
              ) : (
                <a href="/login" style={{ color: "#8F4FFF" }}>
                  Log In
                </a>
              )}{" "}
            </div>
          </div>
        </div>{" "}
        <Modal
          open={openModal1}
          onClose={handleCloseModal1}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={styleee}>
            <div
              style={{
                backgroundColor: "#FDFDFD",
                padding: "0px 0px",
              }}
            >
              <h3>Verification email sent</h3>
              <button
                onClick={handleCloseModal1}
                className="button_no_style"
                style={{ padding: "0", marginTop: "0em", marginBottom: "1em" }}
              >
                <a
                  style={{
                    color: "#8F4FFF",
                    textDecoration: "none",
                    fontSize: "16px",
                  }}
                >
                  I have verified my email
                </a>
              </button>{" "}
              {verifyError && (
                <Alert severity="error" style={{ backgroundColor: "none" }}>
                  {verifyError}
                </Alert>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
}
