import React from "react";
import "../../style/dashboard.css";
import success from "../../images/subs/success.png";
import success_mob from "../../images/subs/success_mob.png";
import connect from "../../images/subs/connect.svg";
import { Grid } from "@mui/material";
import GooglePlay from "../../images/subs/GooglePlay.png";
import AppStore from "../../images/subs/AppStore.png";
import gif from "../../images/subs/gif.gif";
import qr from "../../images/subs/qr.png";
import logo from "../../images/subs/MicroREC App.png";
import Layout from "../Layout";

const SuccessPage = () => {
  return (
    <Layout>
      <Grid item sm={12} xs={12} display={{ xs: "none", sm: "block" }}>
        <div className="container">
          <a
            href="/"
            style={{
              padding: "0px 17px",
              height: "35px",
              color: "white",
              backgroundColor: "#8F4FFF",
              borderRadius: "20px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              fontWeight: "600",
              marginRight: "0.5em",
              justifyContent: "center",
              width: "fitContent",
              position: "fixed",
              zIndex: "10",
              top: "10px",
              right: "10px",
              border: "solid 1px white",
            }}
          >
            START NOW
          </a>
          <div className="content-overlay">
            <div className="content">
              <h1>Welcome to</h1>
              <img src={connect} style={{ maxWidth: "100%" }}></img>
              <p>
                Thank you for choosing MicroREC Connect. We trust you'll find
                great value in documenting patient visits, managing vital data,
                and tracking pathology progression, all at your fingertips.
              </p>
            </div>
          </div>
          <img
            src={success}
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></img>
        </div>
      </Grid>
      <Grid item sm={12} xs={12} display={{ xs: "block", sm: "none" }}>
        <div className="container">
          {" "}
          <a
            href="/"
            style={{
              padding: "0px 17px",
              height: "35px",
              color: "white",
              backgroundColor: "#8F4FFF",
              borderRadius: "20px",
              fontSize: "14px",
              display: "flex",
              alignItems: "center",
              textDecoration: "none",
              fontWeight: "600",
              marginRight: "0.5em",
              justifyContent: "center",
              width: "fitContent",
              position: "fixed",
              zIndex: "10",
              top: "10px",
              right: "10px",
              border: "solid 1px white",
            }}
          >
            START NOW
          </a>
          <div className="content-overlay">
            <div className="content">
              <h1>Welcome to</h1>
              <img src={connect} style={{ maxWidth: "100%" }}></img>
              <p>
                Thank you for choosing MicroREC Connect. We trust you'll find
                great value in documenting patient visits, managing vital data,
                and tracking pathology progression, all at your fingertips.
              </p>
            </div>
          </div>
          <img
            src={success_mob}
            style={{ width: "100%", height: "100vh", objectFit: "cover" }}
          ></img>
        </div>
      </Grid>
      <div style={{ padding: "20px" }}>
        <h1 style={{ textAlign: "center", marginTop: "0em" }}>
          What are the next steps?
        </h1>
        <Grid
          container
          xs={12}
          style={{
            justifyContent: "center",
            position: "relative",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Grid item md={6} sm={6} xs={12}>
            <div>
              <div style={{ fontWeight: "600", fontSize: "20px" }}>
                If you’re already a MicroREC App user:
              </div>
              <div
                style={{
                  color: "#8F4FFF",
                  fontWeight: "600",
                  fontSize: "20px",
                  marginTop: "1em",
                  marginBottom: "1em",
                }}
              >
                Important: You need to use the same account for MicroREC Connect
                and MicroREC App!
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <div>
                  <div className="circle">1</div>
                </div>
                <div>
                  Open the MicroREC App on your smartphone and log in to your
                  account.
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <div>
                  <div className="circle">2</div>
                </div>
                <div>Tap the cloud icon on the Gallery Screen.</div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <div>
                  {" "}
                  <div className="circle">3</div>
                </div>
                <div>Tap the Night Upload button. </div>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1em",
                }}
              >
                <div>
                  <div className="circle">4</div>
                </div>
                <div>
                  The app will automatically lock your screen once everything is
                  uploaded, allowing you to enjoy your time while saving battery
                  power.{" "}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <img style={{ width: "100%" }} src={gif}></img>
          </Grid>
        </Grid>
      </div>
      <Grid
        container
        md={5}
        sm={8}
        lg={4}
        xs={12}
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          marginLeft: "auto",
          marginRight: "auto",
          padding: "10px",
        }}
      >
        <div
          style={{
            fontWeight: "600",
            textAlign: "center",
            marginTop: "2em",
            fontSize: "23px",
          }}
        >
          If you’re still not using the MicroREC App, you can download it for
          free by clicking:
        </div>
        <Grid
          container
          xs={6}
          style={{
            justifyContent: "center",
            flexDirection: "column",
            position: "relative",
            alignItems: "center",
            marginTop: "2em",
          }}
        >
          <Grid item sm={6} xs={6}>
            <a
              href="https://play.google.com/store/apps/details?id=com.app_microrec&gl=AT"
              style={{
                display: "flex",
                justifyContent: "space-around",
                textDecoration: "none",
              }}
              target="_blank"
            >
              <button
                href="https://play.google.com/store/apps/details?id=com.app_microrec&gl=AT"
                className="download_button"
              >
                <img
                  src={GooglePlay}
                  style={{ width: "20px", marginRight: "0.5em" }}
                ></img>
                <div className="download_text">
                  <div style={{ fontSize: "10px" }}>GET&nbsp;IT&nbsp;ON</div>
                  <div style={{ fontWeight: "700" }}>Google&nbsp;Play</div>
                </div>
              </button>
            </a>
          </Grid>
          <Grid item sm={6} xs={6} marginTop={{ xs: 1, sm: 1 }}>
            <form
              action="https://apps.apple.com/app/microrec/id1524787428"
              style={{ display: "flex", justifyContent: "space-around" }}
              target="_blank"
            >
              <button type="submit" className="download_button">
                <img
                  src={AppStore}
                  style={{ width: "20px", marginRight: "0.5em" }}
                ></img>
                <div className="download_text">
                  <div style={{ fontSize: "10px" }}>GET&nbsp;IT&nbsp;ON</div>
                  <div style={{ fontWeight: "700" }}>App&nbsp;Store</div>
                </div>
              </button>
            </form>
          </Grid>
        </Grid>
        <div
          style={{
            fontWeight: "600",
            textAlign: "center",
            marginTop: "1em",
            fontSize: "20px",
          }}
        >
          or
        </div>
        <div
          style={{
            fontWeight: "600",
            textAlign: "center",
            marginTop: "1em",
            fontSize: "20px",
          }}
        >
          by scanning the QR code:
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "1em",
            marginBottom: "1em",
          }}
        >
          <img
            src={qr}
            style={{ maxHeight: "150px", marginRight: "0.5em" }}
          ></img>
        </div>{" "}
        <img src={logo} style={{ maxWidth: "230px" }}></img>
      </Grid>

      <div
        style={{
          textAlign: "center",
          backgroundColor: "#8E4FFF",
          color: "white",
          padding: "10px",
          paddingTop: "5em",
          paddingBottom: "5em",
          marginTop: "3em",
        }}
      >
        {" "}
        <Grid
          container
          md={8}
          sm={10}
          lg={5}
          xs={12}
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            justifyContent: "center",
          }}
        >
          <h1>Why do you need the MicroREC app?</h1>
          <h2 style={{ fontWeight: "400" }}>
            The MicroREC App seamlessly complements MicroREC Connect,
            simplifying the entire process of acquiring, organizing, and
            transferring data directly from your smartphone, making it faster
            and easier.
          </h2>
          <h2 style={{ marginTop: "2em" }}>
            You can learn more about it{" "}
            <a
              href="https://customsurgical.co/microrec-app/"
              target="_blank"
              style={{ color: "white" }}
            >
              here.
            </a>
          </h2>{" "}
        </Grid>
      </div>
    </Layout>
  );
};

export default SuccessPage;
