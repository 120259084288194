import React, { useEffect, useState } from "react";
import { Box, Modal } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";
import "../../style/login.css";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from "firebase/firestore";
import axios from "axios";

const styleee = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 300,
  maxWidth: 500,
  maxHeight: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: 4,
  overflow: "auto",
  p: 4,
};

const Account = () => {
  const { currentUser } = useAuth();

  const specialities = [
    "Ophthalmology",
    "Dentistry",
    "Orthopedics",
    "ENT",
    "Neurology",
    "Plastic Surgery",
    "Histopathology",
    "Cardiovascular",
    "Optometrist",
    "Sales Representative",
    "Clinical Specialist",
    "Other",
  ];

  const [error, setError] = useState("");
  const [userFirstName, setUserFirstName] = useState("");
  const [userLastName, setUserLastName] = useState("");
  const [userCountry, setUserCountry] = useState("");
  const [userSpeciality, setUserSpeciality] = useState("");
  const [fullName, setFullName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [usersV2, setUsersV2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const [countries, setCountries] = useState([]);
  const [displayCountry, setDisplayCountry] = useState("");
  const [displaySpeciality, setDisplaySpeciality] = useState("");
  const [checked, setChecked] = useState(false);
  const [openModal1, setOpenModal1] = useState(false);
  const [openModal2, setOpenModal2] = useState(false);
  const [openModal4, setOpenModal4] = useState(false);

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const dashboard = code ? `/?code=${code}` : "/";

  useEffect(() => {
    const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

    const unsub = onSnapshot(collectionRef, (snapshot) => {
      const firstName = snapshot.data().firstName.trim();
      const lastName = snapshot.data().lastName.trim();
      const newFullName = `${firstName} ${lastName}`.trim();

      setFullName(newFullName);
      setUserFirstName(firstName);
      setUserLastName(lastName);
      setUserCountry(snapshot.data().country);
      setUserSpeciality(snapshot.data().speciality);
    });

    axios
      .get("https://restcountries.com/v3.1/all")
      .then((response) => {
        const sortedCountries = response.data.sort((a, b) => {
          if (a.name.common < b.name.common) return -1;
          if (a.name.common > b.name.common) return 1;
          return 0;
        });
        setCountries(sortedCountries);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });

    const fetchDocument = async () => {
      setIsLoading(true);

      const docRef = doc(db, "usersV2", currentUser.uid);
      const docSnapV2 = await getDoc(docRef);
      if (docSnapV2.exists()) {
        setUsersV2(docSnapV2.data());
      }
      setIsLoading(false);
    };
    fetchDocument();

    setIsMounted(true);

    return unsub;
  }, []);

  const updateCountry = () => {
    if (displayCountry !== undefined && displayCountry !== "") {
      const data = {
        country: displayCountry,
        modified: serverTimestamp(),
      };

      const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

      return updateDoc(collectionRef, data);
    }
  };

  const updateSpeciality = () => {
    if (displaySpeciality !== undefined && displaySpeciality !== "") {
      const data = {
        speciality: displaySpeciality,
        modified: serverTimestamp(),
      };

      const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

      return updateDoc(collectionRef, data);
    }
  };

  const updateName = () => {
    if (displayName !== undefined && displayName !== "") {
      const data = displayName.includes(" ")
        ? {
            firstName: displayName.split(" ")[0],
            lastName: displayName.split(" ").slice(1).join(" "),
            modified: serverTimestamp(),
          }
        : {
            firstName: displayName,
            modified: serverTimestamp(),
            lastName: "",
          };

      const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

      return updateDoc(collectionRef, data);
    }
  };

  const handleCountryChange = () => {
    setDisplayCountry(userCountry);
    setOpenModal2(false);
  };

  const handleSpecialityChange = () => {
    setDisplaySpeciality(userSpeciality);
    setOpenModal4(false);
  };

  const handleNameChange = () => {
    setDisplayName(fullName);
    setOpenModal1(false);
  };

  const setModalData = (usersV2) => {
    setUserFirstName(usersV2.firstName);
    setUserLastName(usersV2.lastName);
    setUserCountry(usersV2.country);
    setUserSpeciality(usersV2.speciality);
  };

  const handleCloseModal1 = () => {
    setOpenModal1(false);
  };

  const handleCloseModal2 = () => {
    setOpenModal2(false);
  };

  const handleCloseModal4 = () => {
    setOpenModal4(false);
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleNews = () => {
    if (checked === true) {
      const data = {
        newsletter: true,
      };
      const collectionRef = doc(db, "usersV2/" + `${currentUser.uid}`);

      updateDoc(collectionRef, data);
    }
  };

  async function handleAccount() {
    setError("");
    try {
      updateSpeciality();
      updateCountry();
      updateName();
      handleNews();

      navigate(dashboard);
    } catch {
      setError("Failed to register");
    }
  }

  if (!isMounted) {
    return null;
  }

  return (
    <>
      <div className="login_main">
        <div className="login" style={{ justifyContent: "initial" }}>
          <div
            style={{
              boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
              padding: "20px",
              textAlign: "center",
              fontWeight: "600",
            }}
          >
            Personal information
          </div>
          {usersV2 && (
            <>
              <div style={{ textAlign: "center", marginTop: "0em" }}>
                <h2>Tell us about yourself</h2>
                <div>This is initial information of your profile.</div>
                <div>You can change it anytime</div>
              </div>
              <div style={{ padding: "10px", textAlign: "center" }}>
                <div
                  style={{
                    width: "90%",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                    marginTop: "2em",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <button
                      onClick={() => {
                        setModalData(usersV2.uid);
                        setOpenModal1(true);
                      }}
                      className="button_no_style account_data"
                    >
                      {fullName !== "" && fullName !== undefined
                        ? fullName
                        : displayName !== "" && displayName !== undefined
                        ? displayName
                        : "Enter your full name"}
                    </button>
                  </div>
                  <Modal
                    open={openModal1}
                    onClose={handleCloseModal1}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleee}>
                      <div
                        style={{
                          backgroundColor: "#FDFDFD",
                          padding: "30px 0px",
                        }}
                      >
                        <label className="label">Your Full Name</label>
                        <textarea
                          value={fullName}
                          onChange={(e) => {
                            setFullName(e.target.value);
                          }}
                        ></textarea>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "1em",
                          }}
                        >
                          <button
                            className="button_no_style"
                            onClick={() => {
                              setOpenModal1(false);
                            }}
                          >
                            Cancel
                          </button>
                          {fullName ? (
                            <button
                              className="button_no_style"
                              style={{
                                color: "#8F4FFF",
                                fontWeight: "600",
                              }}
                              onClick={() => {
                                handleNameChange();
                              }}
                            >
                              Update
                            </button>
                          ) : (
                            <div>
                              <button
                                className="button_no_style"
                                style={{ fontWeight: "600" }}
                                onClick={() => {
                                  setError("Invalid full name!");
                                }}
                              >
                                Update
                              </button>
                            </div>
                          )}
                        </div>
                      </div>{" "}
                      {!fullName && (
                        <div>
                          {error && (
                            <div
                              style={{
                                marginTop: "0.5em",
                                textAlign: "center",
                                color: "red",
                              }}
                            >
                              {error}
                            </div>
                          )}
                        </div>
                      )}
                    </Box>
                  </Modal>
                </div>
                <div
                  style={{
                    width: "90%",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                    marginTop: "1em",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div style={{ width: "100%" }}>
                    <button
                      onClick={() => {
                        setModalData(usersV2.uid);
                        setOpenModal2(true);
                      }}
                      className="button_no_style account_data"
                    >
                      {userCountry !== "" && userCountry !== undefined
                        ? userCountry
                        : displayCountry !== "" && displayCountry !== undefined
                        ? displayCountry
                        : "Enter your country"}
                    </button>
                  </div>{" "}
                  <Modal
                    open={openModal2}
                    onClose={handleCloseModal2}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleee}>
                      <div
                        style={{
                          backgroundColor: "#FDFDFD",
                          padding: "30px 0px",
                        }}
                      >
                        <label className="label">Your Country</label>
                        <div>
                          <select
                            style={{
                              padding: "8px",
                              fontSize: "16px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              outline: "none",
                              width: "100%",
                              backgroundColor: "#FDFDFD",
                              color: "#333",
                              marginTop: "1em",
                            }}
                            onChange={(e) => {
                              setUserCountry(e.target.value);
                            }}
                            value={userCountry}
                          >
                            <option value={userCountry}>{userCountry}</option>
                            {countries.map((country, index) => (
                              <option key={index} value={country.name.common}>
                                {country.name.common}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "1em",
                          }}
                        >
                          <button
                            className="button_no_style"
                            onClick={() => {
                              setOpenModal2(false);
                            }}
                          >
                            Cancel
                          </button>

                          <button
                            className="button_no_style"
                            style={{
                              color: "#8F4FFF",
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              handleCountryChange();
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </div>
                <div
                  style={{
                    width: "90%",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                    marginTop: "1em",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }}
                >
                  <div key={usersV2.speciality} style={{ width: "100%" }}>
                    <button
                      onClick={() => {
                        setModalData(usersV2.uid);
                        setOpenModal4(true);
                      }}
                      className="button_no_style account_data"
                    >
                      {userSpeciality !== "" && userSpeciality !== undefined
                        ? userSpeciality
                        : displaySpeciality !== "" &&
                          displaySpeciality !== undefined
                        ? displaySpeciality
                        : "Enter your speciality"}
                    </button>
                  </div>
                  <Modal
                    open={openModal4}
                    onClose={handleCloseModal4}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box sx={styleee}>
                      <div
                        style={{
                          backgroundColor: "#FDFDFD",
                          padding: "30px 0px",
                        }}
                      >
                        <label className="label">Your speciality</label>

                        <div>
                          <select
                            style={{
                              padding: "8px",
                              fontSize: "16px",
                              borderRadius: "4px",
                              border: "1px solid #ccc",
                              outline: "none",
                              width: "100%",
                              backgroundColor: "#FDFDFD",
                              color: "#333",
                              marginTop: "1em",
                            }}
                            onChange={(e) => {
                              setUserSpeciality(e.target.value);
                            }}
                            value={userSpeciality}
                          >
                            <option value={userSpeciality}>
                              {userSpeciality}
                            </option>
                            {specialities.map((speciality, index) => (
                              <option key={index} value={speciality}>
                                {speciality}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "1em",
                          }}
                        >
                          <button
                            className="button_no_style"
                            onClick={() => {
                              setOpenModal4(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="button_no_style"
                            style={{
                              color: "#8F4FFF",
                              fontWeight: "600",
                            }}
                            onClick={() => {
                              handleSpecialityChange();
                            }}
                          >
                            Update
                          </button>
                        </div>
                      </div>
                    </Box>
                  </Modal>
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: "1em",
                    justifyContent: "space-around",
                  }}
                >
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    sx={{
                      "&.Mui-checked": {
                        color: "#8F4FFF",
                      },
                    }}
                    inputProps={{ "aria-label": "News" }}
                  />
                  <div style={{ textAlign: "left", width: "80%" }}>
                    I would like to receive updates from Custom Surgical
                  </div>
                </div>
                <button className="login-button" onClick={handleAccount}>
                  Continue
                </button>
                <div>
                  By creating an account you agree to our{" "}
                  <a
                    href="https://customsurgical.co/terms-conditions/"
                    target="_blank"
                    style={{
                      color: "#8F4FFF",
                      fontWeight: "600",
                    }}
                  >
                    Terms of Service
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://customsurgical.co/privacy-policy/"
                    target="_blank"
                    style={{
                      color: "#8F4FFF",
                      fontWeight: "600",
                    }}
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
export default Account;
