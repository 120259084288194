import React, { useState, useEffect, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { getDocs } from "firebase/firestore";
import { Grid } from "@mui/material";
import { Container } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Refresh from "@mui/icons-material/Refresh";
import SearchIcon from "@mui/icons-material/Search";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { Multiselect } from "multiselect-react-dropdown";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import error from "./error.svg";
import info from "./info.svg";
import error_storage from "./error_storage.svg";

import firestoreRepository, {
  listenDeviceSyncChanges,
} from "../../repositories/firestoreRepository";
import { groupSessionsByDate } from "../../utils/sessionUtils";

import Layout from "../Layout";
import "./Dashboard.css";
import ModalEditSession from "../common/ModalEditSession";
import ModalDeleteSession from "../common/ModalDeleteSession";
import { Link } from "react-router-dom";
import AddSession from "../common/addSession";
import placeHolderThumbnail from "../../images/dashboard/background_cross.png";
import { Add } from "@mui/icons-material";
import axios from "axios";
import { analytics, db } from "../../firebase";
import { logEvent } from "firebase/analytics";
import FreeUser from "./free";
import EmptyDashboard from "./empty";
import cloudFunctionRepository from "../../repositories/cloudFunctionRepository";

export default function Dashboard() {
  const { currentUser } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [sessionsByDate, setSessionsByDate] = useState([]);
  const [images, setImages] = useState({});
  const [videos, setVideos] = useState([]);
  const [patients, setPatients] = useState([]);
  const [allPatients, setAllPatients] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [userData, setUserData] = useState([]);
  const [sessionWithActiveModal, setSessionWithActiveModal] = useState(null);
  const [displaySearch, setDisplaySearch] = useState(() => {
    const storedValue = JSON.parse(
      sessionStorage.getItem("persistSearchParams")
    );
    if (
      storedValue &&
      (storedValue.patientId !== null ||
        (storedValue.tags && storedValue.tags.length !== 0) ||
        (storedValue.dateRange && storedValue.dateRange[0].startDate !== null))
    ) {
      return true;
    } else {
      return false;
    }
  });
  const [displayDatePicker, setDisplayDatePicker] = useState(false);
  const [needReload, setNeedReload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const lastDeviceSyncRef = useRef();
  const setLastDeviceSync = (data) => {
    lastDeviceSyncRef.current = data;
  };
  const [subscriptionData, setSubscriptionData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const isMobile = window.innerWidth <= 600;
  const [hide80, setHide80] = useState(() => {
    const storedValue = localStorage.getItem("isHiden80");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [hide90, setHide90] = useState(() => {
    const storedValue = localStorage.getItem("isHiden90");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const [authToken, setAuthToken] = useState(null);

  // Ratio of 1:3 increment:total leads to best UI experience
  const LOADED_SESSIONS = 150;
  const LOAD_INCREMENT = 50;

  const startSessionIdx = useRef(
    parseInt(sessionStorage.getItem("startSessionIdx") || 0, 10)
  );
  const endSessionIdx = useRef(
    parseInt(sessionStorage.getItem("endSessionIdx") || LOADED_SESSIONS, 10)
  );
  const queryIdx = useRef(
    JSON.parse(sessionStorage.getItem("persistQueryIdx")) || 0
  );
  const persistedSearchParams = JSON.parse(
    sessionStorage.getItem("persistSearchParams")
  );

  const searchParams = useRef(
    persistedSearchParams
      ? {
          ...persistedSearchParams,
          dateRange: persistedSearchParams.dateRange.map((range) => ({
            ...range,
            startDate: range.startDate ? new Date(range.startDate) : null,
            endDate: range.endDate ? new Date(range.endDate) : new Date(),
          })),
        }
      : {
          patientGlobalId: null,
          patientId: null,
          dateRange: [
            { startDate: null, endDate: new Date(), key: "selection" },
          ],
          tags: [],
        }
  );

  const onScrollInProgress = useRef(false);
  const lastScrollTime = useRef(Date.now());
  const lastScrollPosition = useRef(
    parseInt(sessionStorage.getItem("scrollPosition") || 0, 10)
  );

  const debounceTimer = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    loadUserData(currentUser.uid).then((data) => {
      setUserData(data);
      setLastDeviceSync(data.lastDeviceSync);
      setLargestSubscriptionId(
        Math.max(
          data.stripeSubscriptionId || 0,
          data.iosSubscriptionId || 0,
          data.androidSubscriptionId || 0,
          data.premiumAndroid ? 2 : 0,
          data.premiumIOS ? 2 : 0
        )
      );
    });
    countConnectAccessed(currentUser.uid);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    loadData(
      currentUser.uid,
      queryIdx.current,
      startSessionIdx.current,
      endSessionIdx.current,
      searchParams.current
    ).finally(() => setTimeout(() => setIsLoading(false), 2000));
  }, [needReload]);

  useEffect(() => {
    const fetchData = async () => {
      const unsubscribe = await listenDeviceSyncChanges(
        currentUser.uid,
        debounceTimer,
        lastDeviceSyncRef,
        setLastDeviceSync,
        loadData,
        queryIdx,
        startSessionIdx,
        endSessionIdx,
        searchParams
      );

      // Clean up the listener when the component unmounts
      return () => unsubscribe();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (currentUser) {
      currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(async (token) => {
          // Token obtained successfully
          setAuthToken(token);
          const data = await loadSubscriptionData(token); // Call loadSubscriptionData here
          setSubscriptionData(data);
        })
        .catch((error) => {
          console.error("Error getting authentication token:", error);
        });
    }
  }, [currentUser]);

  useEffect(() => {
    localStorage.setItem("isHiden80", JSON.stringify(hide80));
  }, [hide80]);

  useEffect(() => {
    localStorage.setItem("isHiden90", JSON.stringify(hide90));
  }, [hide90]);

  const createBillingPortalSession = async () => {
    if (!authToken) {
      console.error("User is not authenticated.");
      return;
    }

    setLoading(true);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/createStripeBillingPortalConfig`,

        {
          // Any data you want to send to the Cloud Function
          // For example, you can send an empty object if no data is needed.
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      handleBillingPortalClick(response.data.portalSession);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleBillingPortalClick = (billingPortalUrl) => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  };

  const loadData = async (userId, queryIdx, startIdx, endIdx, searchParams) => {
    loadSessions(userId, queryIdx, startIdx, endIdx, searchParams).then(
      ([sessions, queryStatus]) => {
        if (queryStatus === "empty") {
          const emptyData = () => {
            setSessions([]);
            setSessionsByDate([]);
            setImages({});
            setVideos([]);
            setPatients([]);
          };
          // User already at the end of scroll or there is no sessions, return
          return emptyData();
        }
        if (queryStatus === "endOfScroll") {
          return null;
        }
        if (queryStatus === "success") {
          setSessions(sessions);
          setSessionsByDate(groupSessionsByDate(sessions));
          // Delay scrollTo until after the page has finished rendering
          setTimeout(() => {
            window.scrollTo(0, lastScrollPosition.current);
          }, 100);
          loadImages(userId, sessions).then((images) => {
            setImages(images);
          });
          loadVideos(userId, sessions).then((videos) => {
            setVideos(videos);
          });
          loadPatients(userId, sessions).then((patients) => {
            setPatients(patients);
          });
        }
      }
    );
    // Since we need all tags, they are independent from the session query
    loadAllTags(userId).then((tags) => {
      setAllTags(tags);
    });
    loadAllPatients(userId).then((patients) => {
      setAllPatients(patients);
    });
  };

  /**
   * Loads sessions from the Firestore database.
   *
   * @param {string} userId - The ID of the user.
   * @param {number} startIdx - The starting index of the sessions to load.
   * @param {number} endIdx - The ending index of the sessions to load.
   * @returns {Promise<Array<Object>>|null} - A promise that resolves to an array of session data, or null if no more sessions need to be loaded.
   */
  const loadSessions = async (
    userId,
    queryIdx,
    startIdx,
    endIdx,
    searchParams
  ) => {
    const querySnapshot = await firestoreRepository.querySessionsByQueryIdx(
      userId,
      queryIdx,
      endIdx,
      searchParams
    );

    if (querySnapshot === null) {
      return [[], "empty"];
    }
    const queryResultSnapshot = await getDocs(querySnapshot);
    const queryData = queryResultSnapshot.docs.map((doc) => doc.data());

    if (queryData.length == endIdx - LOAD_INCREMENT) {
      // User already at the end of scroll, reset the start and end index and return null to stop loading
      startSessionIdx.current = Math.max(queryData.length - LOADED_SESSIONS, 0);
      endSessionIdx.current = queryData.length;
      return [[], "endOfScroll"];
    }
    if (queryData.length < endIdx) {
      // First time user reaches the end of scroll, correct the start and end index and return the data
      startSessionIdx.current = Math.max(queryData.length - LOADED_SESSIONS, 0);
      endSessionIdx.current = queryData.length;
      return [queryData.slice(startSessionIdx.current), "success"];
    }
    // User is in the middle of the scroll, return the data
    return [queryData.slice(startIdx), "success"];
  };

  /**
   * Loads images for a given user and session data.
   *
   * @param {string} userId - The ID of the user.
   * @param {Array} sessionData - An array of session data.
   * @returns {Object} - An object mapping session global IDs to session images.
   */
  const loadImages = async (userId, sessionData) => {
    let sessionImagesMap = {};
    await Promise.all(
      sessionData.map(async (session) => {
        const sessionImages = await firestoreRepository.getImagesBySession(
          userId,
          session
        );
        sessionImagesMap[session.globalId] = sessionImages;
      })
    );
    return sessionImagesMap;
  };

  /**
   * Loads videos for a given user and session data.
   *
   * @param {string} userId - The ID of the user.
   * @param {Array} sessionData - An array of session data.
   * @returns {Object} - An object mapping session global IDs to session videos.
   */
  const loadVideos = async (userId, sessionData) => {
    let sessionVideosMap = {};
    await Promise.all(
      sessionData.map(async (session) => {
        const sessionVideos = await firestoreRepository.getVideosBySession(
          userId,
          session
        );
        sessionVideosMap[session.globalId] = sessionVideos;
      })
    );
    return sessionVideosMap;
  };

  const loadPatients = async (userId, sessionData) => {
    let sessionPatientMap = {};
    await Promise.all(
      sessionData.map(async (session) => {
        const sessionPatient = await firestoreRepository.getPatientBySession(
          userId,
          session
        );
        sessionPatientMap[session.globalId] = sessionPatient;
      })
    );
    return sessionPatientMap;
  };

  const loadAllPatients = async (userId) => {
    const patientNames = await firestoreRepository.getAllPatientNames(userId);
    return patientNames;
  };

  const loadAllTags = async (userId) => {
    const tags = await firestoreRepository.getAllTags(userId);
    return tags;
  };

  const countConnectAccessed = async (userId) => {
    const count = await firestoreRepository.setConnectAccessed(userId);
    return count;
  };

  const loadSubscriptionData = async (authToken) => {
    const subscriptionData = await cloudFunctionRepository.getSubscriptionData(
      authToken
    );
    return subscriptionData;
  };

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const updateSearchParams = (newValue, paramField, currentSearchParams) => {
    currentSearchParams[paramField] = newValue;
    const isPatientSearchActive =
      currentSearchParams.patientId != null &&
      currentSearchParams.patientId != "";
    const isDateSearchActive =
      currentSearchParams.dateRange[0].startDate != null &&
      currentSearchParams.dateRange[0].endDate != null;
    const isTagSearchActive = currentSearchParams.tags.length > 0;
    // Reset queryIdx depending on the active search parameters
    if (isPatientSearchActive && isDateSearchActive && isTagSearchActive) {
      queryIdx.current = 7;
    } else if (isPatientSearchActive && isTagSearchActive) {
      queryIdx.current = 6;
    } else if (isDateSearchActive && isTagSearchActive) {
      queryIdx.current = 5;
    } else if (isPatientSearchActive && isDateSearchActive) {
      queryIdx.current = 4;
    } else if (isTagSearchActive) {
      queryIdx.current = 3;
    } else if (isDateSearchActive) {
      queryIdx.current = 2;
    } else if (isPatientSearchActive) {
      queryIdx.current = 1;
    } else {
      queryIdx.current = 0;
    }

    searchParams.current = currentSearchParams;
    startSessionIdx.current = 0;
    endSessionIdx.current = LOADED_SESSIONS;
    setNeedReload(!needReload);
  };

  const hideSearchBar = () => {
    setDisplaySearch(false);
    setDisplayDatePicker(false);
  };

  const clearSearchParams = () => {
    searchParams.current = {
      patientGlobalId: null,
      patientId: null,
      dateRange: [{ startDate: null, endDate: new Date(), key: "selection" }],
      tags: [],
    };

    queryIdx.current = 0;
    startSessionIdx.current = 0;
    endSessionIdx.current = LOADED_SESSIONS;
    sessionStorage.removeItem("persistSearchParams");
    sessionStorage.removeItem("persistQueryIdx");
    setNeedReload(!needReload);
  };

  const persistStorageParams = () => {
    // Save the current scroll position in sessionStorage
    sessionStorage.setItem(
      "scrollPosition",
      lastScrollPosition.current.toString()
    );
    sessionStorage.setItem(
      "startSessionIdx",
      startSessionIdx.current.toString()
    );
    sessionStorage.setItem("endSessionIdx", endSessionIdx.current.toString());
    sessionStorage.setItem(
      "persistSearchParams",
      JSON.stringify(searchParams.current)
    );
    sessionStorage.setItem("persistQueryIdx", JSON.stringify(queryIdx.current));
  };

  const handleBeforeUnload = () => {
    // only gets triggered when the user refreshes the page or closes the tab
    // Using react link method for navigation avoids resetting params
    sessionStorage.removeItem("scrollPosition");
    sessionStorage.removeItem("startSessionIdx");
    sessionStorage.removeItem("endSessionIdx");
    sessionStorage.removeItem("persistSearchParams");
    sessionStorage.removeItem("persistQueryIdx");
  };

  const handleScroll = () => {
    const now = Date.now();
    const scrollPosition = window.scrollY || document.documentElement.scrollTop;
    if (now - lastScrollTime.current > 500) {
      lastScrollTime.current = now;
      const windowHeight = window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;
      // Calculate 25% of the total scrollable height
      const scrollThreshold = totalHeight * 0.25;
      // Determine scroll direction
      const scrollDirection =
        scrollPosition > lastScrollPosition.current ? "down" : "up";

      if (
        scrollDirection === "down" &&
        windowHeight + scrollPosition >= totalHeight - scrollThreshold
      ) {
        onScrollDown();
      }
      if (
        scrollDirection === "up" &&
        scrollPosition < scrollThreshold &&
        scrollPosition > 100
      ) {
        onScrollUp();
      }
    }
    // Update last scroll position
    lastScrollPosition.current = scrollPosition;
  };

  const onScrollDown = () => {
    if (onScrollInProgress.current) {
      return;
    }
    onScrollInProgress.current = true;
    startSessionIdx.current += LOAD_INCREMENT;
    endSessionIdx.current += LOAD_INCREMENT;
    loadData(
      currentUser.uid,
      queryIdx.current,
      startSessionIdx.current,
      endSessionIdx.current,
      searchParams.current
    ).then(() => {
      onScrollInProgress.current = false;
    });
  };

  const onScrollUp = () => {
    if (onScrollInProgress.current) {
      return;
    }
    if (startSessionIdx.current == 0) {
      // User already at the top of scroll
      return;
    }
    onScrollInProgress.current = true;
    if (startSessionIdx.current < LOAD_INCREMENT) {
      // First time user reaches the top of scroll, correct the start and end index
      startSessionIdx.current = 0;
      endSessionIdx.current = LOADED_SESSIONS;
    } else {
      startSessionIdx.current -= LOAD_INCREMENT;
      endSessionIdx.current -= LOAD_INCREMENT;
    }
    loadData(
      currentUser.uid,
      queryIdx.current,
      startSessionIdx.current,
      endSessionIdx.current,
      searchParams.current
    ).then(() => {
      onScrollInProgress.current = false;
    });
  };

  //Analytics
  const addIntercomEvent = () => {
    window.Intercom("trackEvent", "open-search-bar");
  };

  const analyticsSessionSearchPatient = () => {
    logEvent(analytics, "session_search_patient", {
      button_name: "session_search_patient",
    });
  };

  const analyticsSessionSearchDate = () => {
    logEvent(analytics, "session_search_date", {
      button_name: "session_search_date",
    });
  };

  const analyticsSessionSearchTag = () => {
    logEvent(analytics, "session_search_tag", {
      button_name: "session_search_tag",
    });
  };

  const analyticsSessionOpen = () => {
    logEvent(analytics, "session_open", {
      button_name: "session_open",
    });
  };

  if (!userData) {
    return (
      <Layout>
        <FreeUser></FreeUser>
      </Layout>
    );
  }

  return (
    <Layout>
      {userData && (
        <div>
          {largestSubscriptionId > 0 ? (
            <Container>
              {subscriptionData.hasActiveSubscription === true &&
              subscriptionData.validPaymentMethod === false &&
              isMobile === false ? (
                <div
                  style={{
                    marginTop: "1em",
                    backgroundColor: "#FFF4E5",
                    padding: "20px 20px",
                    borderRadius: "10px",
                    fontWeight: "500",
                    justifyContent: "space-between",
                    display:
                      (userData &&
                        userData.storageEmailLastThreshold === 0.8 &&
                        !hide80) ||
                      (userData && userData.storageEmailLastThreshold === 1) ||
                      (userData &&
                        userData.storageEmailLastThreshold === 0.9 &&
                        !hide90)
                        ? "none"
                        : "flex",
                  }}
                >
                  <div className="flex_div">
                    <img src={error} className="payment_method_image"></img>
                    <div>
                      <div className="banner_text">Enter a Payment Method</div>
                      <div>
                        Add Payment Method by{" "}
                        <b>{subscriptionData.currentPeriodEnd}</b> to continue
                        using MicroREC Connect.
                      </div>
                    </div>
                  </div>
                  <button
                    className="billing_portal"
                    onClick={createBillingPortalSession}
                  >
                    {loading ? "Loading..." : "Add Payment Method"}
                  </button>
                </div>
              ) : (
                ""
              )}
              {userData &&
              userData.storageEmailLastThreshold === 0.8 &&
              hide80 === false ? (
                <Grid
                  container
                  xs={12}
                  justifyContent={{ xs: "space-between", md: "center" }}
                  className="top_bar_banner"
                >
                  <Grid item xs={1} md={1}>
                    <img src={info}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div>
                      <div className="banner_text">
                        Your cloud storage is 80% full.
                      </div>
                      <div>
                        Maintain seamless data uploads by upgrading to a higher
                        plan.
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className="centered_flex_div"
                    marginTop={{ xs: "1em", md: "0" }}
                  >
                    <button
                      className="button_no_style hide_button"
                      onClick={() => setHide80(true)}
                    >
                      Don't show again
                    </button>
                    <button
                      onClick={createBillingPortalSession}
                      className="button_no_style manage_subscription"
                    >
                      {loading ? "Loading..." : "Manage"}
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {userData &&
              userData.storageEmailLastThreshold === 0.9 &&
              hide90 === false ? (
                <Grid
                  container
                  xs={12}
                  justifyContent={{ xs: "space-between", md: "center" }}
                  className="top_bar_banner"
                >
                  <Grid item xs={1} md={1}>
                    <img src={info}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div>
                      <div className="banner_text">
                        Your cloud storage is 90% full.{" "}
                      </div>
                      <div>
                        Maintain seamless data uploads by upgrading to a higher
                        plan.
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className="centered_flex_div"
                    marginTop={{ xs: "1em", md: "0" }}
                  >
                    <button
                      className="button_no_style hide_button"
                      onClick={() => setHide90(true)}
                    >
                      Don't show again
                    </button>
                    <button
                      onClick={createBillingPortalSession}
                      className="button_no_style manage_subscription"
                    >
                      {loading ? " Loading..." : "Manage"}
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {userData && userData.storageEmailLastThreshold === 1 ? (
                <Grid
                  container
                  xs={12}
                  justifyContent={{ xs: "space-between", md: "center" }}
                  className="top_bar_banner"
                >
                  <Grid item xs={1} md={1}>
                    <img src={error_storage}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div>
                      <div className="banner_text">
                        You are out of cloud storage.{" "}
                      </div>
                      <div>
                        To make sure your data is uploaded, please upgrade to a
                        higher plan.
                      </div>
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={2}
                    className="centered_flex_div"
                    marginTop={{ xs: "1em", md: "0" }}
                  >
                    <button
                      onClick={createBillingPortalSession}
                      className="button_no_style manage_full_subscription"
                    >
                      {loading ? "Loading..." : "Manage"}
                    </button>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Grid container className="top_bar_container">
                <button
                  className="refresh_button button_no_style"
                  onClick={() => setNeedReload(!needReload)}
                >
                  <IconButton
                    type="button"
                    sx={{ p: "10px" }}
                    aria-label="refresh"
                  >
                    <Refresh />
                  </IconButton>
                  <div className="button_text">Refresh</div>
                </button>
                {userData && userData.storageEmailLastThreshold === 1 ? (
                  <button className="gray_new_session button_no_style ">
                    <div className="centered_items_flex_div">
                      <IconButton
                        type="button"
                        sx={{ p: "10px", color: "#999999" }}
                        aria-label="search"
                      >
                        <Add />
                      </IconButton>
                      <div className="inactive_session">New Session</div>
                    </div>
                  </button>
                ) : (
                  allTags && (
                    <div style={{ marginTop: "1em" }}>
                      <AddSession tags={allTags} userId={currentUser.uid} />
                    </div>
                  )
                )}
                <div>
                  <button
                    className="search_button button_no_style"
                    onClick={() => {
                      addIntercomEvent();
                      setDisplaySearch(!displaySearch);
                    }}
                  >
                    <IconButton
                      type="button"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon
                        style={{
                          color: queryIdx.current != 0 ? "#8F4FFF" : "#3D3838",
                        }}
                      />
                    </IconButton>

                    <div
                      className="button_text"
                      style={{
                        color: queryIdx.current != 0 ? "#8F4FFF" : "#3D3838",
                      }}
                    >
                      Search
                    </div>
                  </button>
                  {displaySearch ? (
                    <div className="search_bar_container">
                      <div className="search_bar_background">
                        <div className="search_bar">
                          <input
                            list="browsers"
                            type="text"
                            onChange={(e) =>
                              updateSearchParams(
                                e.target.value,
                                "patientId",
                                searchParams.current
                              )
                            }
                            value={searchParams.current.patientId || ""}
                            placeholder="Patient Id"
                            className="calendar_button margin_top_wrap"
                            id="patient_search"
                            onClick={analyticsSessionSearchPatient}
                          ></input>
                          <datalist id="browsers">
                            {Object.values(allPatients)
                              .reduce((unique, patient) => {
                                if (
                                  patient &&
                                  !unique.some(
                                    (obj) =>
                                      obj.canonicalPatientId ===
                                      patient.canonicalPatientId
                                  )
                                ) {
                                  unique.push(patient);
                                }

                                return unique;
                              }, [])
                              .map((patient, index) => (
                                <option
                                  key={index}
                                  value={patient.canonicalPatientId}
                                ></option>
                              ))}
                          </datalist>
                          <div>
                            <button
                              onClick={() => {
                                setDisplayDatePicker(!displayDatePicker);
                                analyticsSessionSearchDate();
                              }}
                              className="calendar_button margin_top_wrap"
                            >
                              <CalendarMonthIcon
                                sx={{
                                  color: "#8F4FFF",
                                  marginRight: "0.5em",
                                }}
                              />
                              Select dates
                            </button>
                            {displayDatePicker === true ? (
                              <DateRange
                                id="selectDate"
                                editableDateInputs={true}
                                onChange={(item) => {
                                  updateSearchParams(
                                    [item.selection],
                                    "dateRange",
                                    searchParams.current
                                  );
                                }}
                                moveRangeOnFirstSelection={false}
                                showMonthAndYearPickers={false}
                                ranges={searchParams.current.dateRange}
                                className="name"
                                rangeColors={["#8F4FFF", "#FFCD78", "#FFCD78"]}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                          <div onClick={analyticsSessionSearchTag}>
                            <Multiselect
                              options={allTags}
                              isObject={false}
                              onSelect={(selectedList) => {
                                updateSearchParams(
                                  selectedList,
                                  "tags",
                                  searchParams.current
                                );
                              }}
                              onRemove={(selectedList) => {
                                updateSearchParams(
                                  selectedList,
                                  "tags",
                                  searchParams.current
                                );
                              }}
                              selectedValues={searchParams.current.tags}
                              className="tags_button margin_top_wrap"
                              style={{
                                chips: {
                                  background: "#8F4FFF",
                                },
                                multiselectContainer: {
                                  color: "#8F4FFF",
                                },
                                searchBox: {
                                  border: "none",
                                  padding: "10px",
                                },

                                option: {
                                  color: "#8F4FFF",
                                  background: "white",
                                },
                              }}
                              placeholder="Tags"
                            />
                          </div>
                        </div>
                        <div className="flex_end_div">
                          <button
                            onClick={hideSearchBar}
                            className="button_no_style margin_1em"
                          >
                            Hide
                          </button>
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    style={{
                      backgroundColor:
                        (searchParams.current.patientId !== null ||
                          searchParams.current.tags.length !== 0 ||
                          searchParams.current.dateRange[0].startDate !==
                            null) &&
                        displaySearch
                          ? "white"
                          : displaySearch
                          ? "unset"
                          : "white",
                      paddingBottom: "10px",
                      marginTop:
                        (searchParams.current.patientId !== null ||
                          searchParams.current.tags.length !== 0 ||
                          searchParams.current.dateRange[0].startDate !==
                            null) &&
                        displaySearch
                          ? "8em"
                          : displaySearch
                          ? "8em"
                          : "0.5em",
                      width: "100%",
                      left: "0",
                      position: "absolute",
                      paddingTop:
                        searchParams.current.tags.length !== 0 ? "1em" : "0",
                    }}
                  >
                    {searchParams.current.patientId !== null ||
                    searchParams.current.tags.length !== 0 ||
                    searchParams.current.dateRange[0].startDate !== null ? (
                      <hr className="hr_style_search"></hr>
                    ) : (
                      ""
                    )}
                    <div className="filter_button filter_button_search">
                      {searchParams.current.patientId !== null ||
                      searchParams.current.tags.length !== 0 ||
                      searchParams.current.dateRange[0].startDate !== null ? (
                        <div className="margin_top_wrap font_weight_700">
                          Filter by:
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.patientId !== null ? (
                        <div className="filter_by margin_top_wrap max_width_90 ">
                          <div className="display_search_patient">
                            {searchParams.current.patientId}
                          </div>
                          <button
                            onClick={() => {
                              updateSearchParams(
                                null,
                                "patientId",
                                searchParams.current
                              );
                            }}
                            className="button_no_style white_text"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.dateRange[0].startDate !== null ? (
                        <div className="filter_by margin_top_wrap">
                          <div className="margin_right">
                            {`${new Intl.DateTimeFormat("default", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(
                              new Date(
                                searchParams.current.dateRange[0].startDate
                              )
                            )}-${new Intl.DateTimeFormat("default", {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }).format(
                              new Date(
                                searchParams.current.dateRange[0].endDate
                              )
                            )}`}
                          </div>
                          <button
                            onClick={() => {
                              updateSearchParams(
                                [
                                  {
                                    startDate: null,
                                    endDate: new Date(),
                                    key: "selection",
                                  },
                                ],
                                "dateRange",
                                searchParams.current
                              );
                            }}
                            className="button_no_style white_text"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.tags.length !== 0 ? (
                        <div className="filter_by margin_top_wrap">
                          <div className="margin_right">Tags</div>
                          <button
                            onClick={() => {
                              updateSearchParams(
                                [],
                                "tags",
                                searchParams.current
                              );
                            }}
                            className="button_no_style white_text"
                          >
                            X
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {searchParams.current.patientId !== null ||
                      searchParams.current.tags.length !== 0 ||
                      searchParams.current.dateRange[0].startDate !== null ? (
                        <div className="margin_top_wrap flex_centered_div">
                          <button
                            onClick={clearSearchParams}
                            className="button_no_style"
                          >
                            Clear all
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>{" "}
              </Grid>
              <div
                style={{
                  marginTop:
                    (searchParams.current.patientId !== null ||
                      searchParams.current.tags.length !== 0 ||
                      searchParams.current.dateRange[0].startDate !== null) &&
                    displaySearch
                      ? "14em"
                      : displaySearch
                      ? "9em"
                      : "2em",
                }}
              ></div>
              <div>
                {Object.keys(sessionsByDate).length !== 0 || !displaySearch ? (
                  ""
                ) : (
                  <div className="no_filtered_session">
                    There is no sessions matching the current filter.
                  </div>
                )}
              </div>
              {!isLoading &&
                Object.keys(sessionsByDate).length === 0 &&
                !displaySearch && <EmptyDashboard />}
              <Grid>
                {Object.keys(sessionsByDate).map((date, _) => (
                  <div key={date}>
                    <div className="date_separator">{date}:</div>
                    <Grid container>
                      {sessionsByDate[date].map((session, index) => [
                        <Grid
                          className="grid_session_item"
                          key={session.globalId}
                          sm={4}
                          item
                          xs={6}
                          md={3}
                          lg={2}
                          onMouseLeave={(e) => {
                            setSessionWithActiveModal(null);
                          }}
                          style={{ marginBottom: "1.5em" }}
                        >
                          {largestSubscriptionId !== 1 ? (
                            <div className="grid">
                              {(() => {
                                const mediaArray =
                                  largestSubscriptionId > 1
                                    ? [
                                        ...(images[session.globalId] || []),
                                        ...(videos[session.globalId] || []),
                                      ]
                                    : [...(images[session.globalId] || [])];
                                const sortedMediaArray = mediaArray.sort(
                                  (a, b) =>
                                    new Date(b.createdAt.toDate()) -
                                    new Date(a.createdAt.toDate())
                                );
                                return [...Array(4)].map((_, index) => {
                                  const media = sortedMediaArray[index];
                                  return (
                                    <React.Fragment
                                      key={`${session.globalId}_${index}`}
                                    >
                                      {media ? (
                                        <img
                                          src={media.thumbnailURL}
                                          className="grid_image"
                                          quality="50"
                                          loading="lazy"
                                          onError={async (e) => {
                                            try {
                                              const refetchedMedia =
                                                await firestoreRepository.refetchDownloadUrls(
                                                  currentUser.uid,
                                                  media
                                                );
                                              if (refetchedMedia) {
                                                const isImage =
                                                  refetchedMedia.globalId.includes(
                                                    "IMAGE"
                                                  );
                                                e.target.src =
                                                  refetchedMedia.thumbnailURL;
                                                if (isImage) {
                                                  setImages((prevImages) => ({
                                                    ...prevImages,
                                                    [session.globalId]: [
                                                      ...prevImages[
                                                        session.globalId
                                                      ].filter(
                                                        (image) =>
                                                          image.globalId !==
                                                          media.globalId
                                                      ),
                                                      refetchedMedia,
                                                    ],
                                                  }));
                                                } else {
                                                  setVideos((prevVideos) => ({
                                                    ...prevVideos,
                                                    [session.globalId]: [
                                                      ...prevVideos[
                                                        session.globalId
                                                      ].filter(
                                                        (video) =>
                                                          video.globalId !==
                                                          media.globalId
                                                      ),
                                                      refetchedMedia,
                                                    ],
                                                  }));
                                                }
                                              }
                                            } catch (error) {
                                              console.error(
                                                "Error refreshing URL:",
                                                error
                                              );
                                            }
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={placeHolderThumbnail}
                                          className="grid_image"
                                          quality="50"
                                          loading="lazy"
                                        />
                                      )}
                                    </React.Fragment>
                                  );
                                });
                              })()}
                            </div>
                          ) : (
                            <div className="grid">
                              {(() => {
                                const mediaArray = [
                                  ...(images[session.globalId] || []),
                                ].sort(
                                  (a, b) =>
                                    new Date(b.createdAt.toDate()) -
                                    new Date(a.createdAt.toDate())
                                );
                                return [...Array(4)].map((_, index) => {
                                  const media = mediaArray[index];
                                  return (
                                    <React.Fragment
                                      key={`${session.globalId}_${index}`}
                                    >
                                      {media ? (
                                        <img
                                          src={media.thumbnailURL}
                                          className="grid_image"
                                          quality="50"
                                          loading="lazy"
                                          onError={async (e) => {
                                            try {
                                              const refetchedMedia =
                                                await firestoreRepository.refetchDownloadUrls(
                                                  currentUser.uid,
                                                  media
                                                );

                                              setImages((prevImages) => ({
                                                ...prevImages,
                                                [session.globalId]: [
                                                  ...prevImages[
                                                    session.globalId
                                                  ].filter(
                                                    (image) =>
                                                      image.globalId !==
                                                      media.globalId
                                                  ),
                                                  refetchedMedia,
                                                ],
                                              }));
                                            } catch (error) {
                                              console.error(
                                                "Error refreshing URL:",
                                                error
                                              );
                                            }
                                          }}
                                        />
                                      ) : (
                                        <img
                                          src={placeHolderThumbnail}
                                          className="grid_image"
                                          quality="50"
                                          loading="lazy"
                                        />
                                      )}
                                    </React.Fragment>
                                  );
                                });
                              })()}
                            </div>
                          )}
                          <Link
                            className={`hover_session ${
                              sessionWithActiveModal === session.globalId
                                ? "show"
                                : ""
                            }`}
                            to={`/sessions/${session.globalId}`}
                            onClick={() => {
                              analyticsSessionOpen();
                              persistStorageParams();
                            }}
                          ></Link>
                          <div className="dots_session">
                            <div
                              className="session_dots_container"
                              key={session.globalId}
                            >
                              <IconButton
                                className="session_dots_button"
                                key={session.globalId}
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                onClick={() =>
                                  sessionWithActiveModal == session.globalId
                                    ? setSessionWithActiveModal(null)
                                    : setSessionWithActiveModal(
                                        session.globalId
                                      )
                                }
                              >
                                <MoreVertIcon />
                              </IconButton>
                              {
                                // TODO: Give meaningful names to the Modal and ModalDeletion components
                                // TODO: Refactor modals to receive session and patient data as props
                                // TODO: Review modals logic and data flow
                                session &&
                                  sessionWithActiveModal ==
                                    session.globalId && (
                                    <>
                                      <ModalEditSession
                                        patientName={
                                          patients && patients[session.globalId]
                                            ? patients[session.globalId]
                                                .patientId
                                            : undefined
                                        }
                                        description={session.description}
                                        selectedTags={session.tags}
                                        tags={allTags}
                                        sessionId={session.globalId}
                                        userId={currentUser.uid}
                                        reloadCallback={() =>
                                          setNeedReload(!needReload)
                                        }
                                      />
                                      <ModalDeleteSession
                                        imageId={images[session.globalId]}
                                        videoId={videos[session.globalId]}
                                        sessionId={session.globalId}
                                        userId={currentUser.uid}
                                        reloadCallback={() =>
                                          setNeedReload(!needReload)
                                        }
                                      />
                                    </>
                                  )
                              }
                            </div>
                          </div>
                          <div className="session_info_container">
                            <div className="patient">
                              {
                                // Add patient name with ellipsis if it's too long
                                patients[session.globalId]
                                  ? patients[session.globalId].patientId
                                      .length > 12
                                    ? patients[
                                        session.globalId
                                      ].patientId.slice(0, 12 / 2) +
                                      "..." +
                                      patients[
                                        session.globalId
                                      ].patientId.slice(-12 / 2)
                                    : patients[session.globalId].patientId
                                  : ""
                              }
                            </div>
                            <div
                              className="timestamp"
                              style={{ marginLeft: "auto" }}
                            >
                              {session.createdAt
                                ?.toDate()
                                .toLocaleTimeString("en-GB", {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })}
                            </div>
                          </div>
                          <div className="timestamp">
                            {`${session.tags}`
                              ? `${session.tags}`.length > 24
                                ? `${session.tags}`.slice(0, 24) + "..."
                                : `${session.tags}`
                              : ""}
                          </div>
                        </Grid>,
                      ])}
                    </Grid>
                  </div>
                ))}
              </Grid>
            </Container>
          ) : (
            <FreeUser />
          )}
        </div>
      )}
    </Layout>
  );
}
