import React, { useEffect, useState } from "react";
import "../../style/dashboard.css";
import { useAuth } from "../../context/AuthContext";
import firestoreRepository from "../../repositories/firestoreRepository";
import AddSession from "../common/addSession";
import devices from "../../images/dashboard/empty_devices.svg";
import qr from "../../images/dashboard/qr.svg";

const EmptyDashboard = () => {
  const [allTags, setAllTags] = useState([]);
  const { currentUser } = useAuth();

  const loadAllTags = async (userId) => {
    const tags = await firestoreRepository.getAllTags(userId);
    return tags;
  };

  useEffect(() => {
    loadAllTags(currentUser.uid).then((tags) => {
      setAllTags(tags);
    });
  }, [currentUser.uid]);

  return (
    <>
      <div className="empty_sessions_div">
        <img src={devices} style={{ width: "320px" }}></img>
        <div className="empty_h1">Ready to begin your Connect experience?</div>
        <div className="new_session_div">
          <div className="margin_right_1 empty_text">Click on </div>
          <AddSession tags={allTags} userId={currentUser.uid} />
          <div className="empty_text">to start</div>
        </div>
        <div className="qr_text">
          Scan the QR code to get the MicroREC app and complete your setup:{" "}
        </div>
        <img src={qr} className="margin_top_1_qr"></img>
      </div>
    </>
  );
};

export default EmptyDashboard;
