import React, { useState, useEffect, useContext, useRef } from "react";
import { useAuth } from "../../context/AuthContext";
import { analytics } from "../../firebase";
import "../../style/dashboard.css";
import DownloadIconBlack from "../../images/dashboard/download.svg";
import { Container } from "@mui/system";
import Layout from "../Layout";
import Box from "@mui/material/Box";
import "../../style/gallery.css";
import FreeUser from "./free";
import { Link, useNavigate, useParams } from "react-router-dom";
import { logEvent } from "@firebase/analytics";
import {
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
} from "@mui/material";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import "../dashboard/Dashboard.css";
import Upload from "../gallery/upload";
import pause from "../../images/gallery/Pause.svg";

import { UploadContext } from "../App";
import { ArrowBack } from "@mui/icons-material";
import ModalEdition from "../common/ModalEditSession";
import ModalDeletion from "../common/ModalDeleteSession";
import Multiselect from "../gallery/multiselect";
import firestoreRepository, {
  listenDeviceSyncChanges,
  listenDeviceSyncChangesSession,
} from "../../repositories/firestoreRepository";
import Gallery from "../gallery/fileDetailedView";
import { downloadFileWithProgress } from "../gallery/downloadFile";
import info from "../subscription/info.svg";

function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "20px 0px" }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Dashboard() {
  let { id } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const {
    files,
    setFiles,
    downloadFiles,
    setDownloadFiles,
    uploadProgress,
    setUploadProgress,
    downloadProgress,
    setDownloadProgress,
    downloadThumbnails,
    setDownloadThumbnails,
    uploadThumbnails,
    setUploadThumbnails,
    remainingTimes,
    setRemainingTimes,
    renderVideo,
    setRenderVideo,
    remainingDownloadTime,
    setRemainingDownloadTime,
  } = useContext(UploadContext);
  const [downloadingFiles, setDownloadingFiles] = useState(new Set());
  const [multiselectedFiles, setMultiselectedFiles] = useState([]);
  const [galleryVisible, setGalleryVisible] = useState(false);
  const [multiselectedUrls, setMultiselectedUrls] = useState([]);
  const [openedFile, setOpenedFile] = useState([]);
  const [allFiles, setAllFiles] = useState([]);
  const [session, setSession] = useState([]);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [patient, setPatient] = useState([]);
  const [allTags, setAllTags] = useState([]);
  const [userData, setUserData] = useState([]);
  const [needReload, setNeedReload] = useState(false);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();
  const [value, setValue] = useState("All");
  const lastDeviceSyncRef = useRef();
  const setLastDeviceSync = (data) => {
    lastDeviceSyncRef.current = data;
  };
  const debounceTimer = useRef(null);

  useEffect(() => {
    loadData(currentUser.uid, id);
  }, [needReload]);

  useEffect(() => {
    loadUserData(currentUser.uid).then((data) => {
      setUserData(data);
      setLastDeviceSync(data.lastDeviceSync);
      setLargestSubscriptionId(
        Math.max(
          data.stripeSubscriptionId || 0,
          data.iosSubscriptionId || 0,
          data.androidSubscriptionId || 0,
          data.premiumAndroid ? 2 : 0,
          data.premiumIOS ? 2 : 0
        )
      );
    });
    const fetchData = async () => {
      const unsubscribe = await listenDeviceSyncChangesSession(
        currentUser.uid,
        id,
        debounceTimer,
        lastDeviceSyncRef,
        setLastDeviceSync,
        loadData
      );

      return () => unsubscribe();
    };

    fetchData();
  }, []);

  const handleSelectImage = (media) => {
    if (multiselectedFiles.find((item) => item.globalId === media.globalId)) {
      setMultiselectedFiles(
        multiselectedFiles.filter((item) => item.globalId !== media.globalId)
      );
    } else {
      setMultiselectedFiles([...multiselectedFiles, media]);
    }
  };

  function handleImageClick(url) {
    if (multiselectedUrls.includes(url)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== url)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, url]);
    }
  }

  const handleDownload = (file) => {
    downloadFileWithProgress(
      file,
      setDownloadFiles,
      setDownloadProgress,
      setDownloadThumbnails,
      setRemainingDownloadTime,
      downloadingFiles,
      setDownloadingFiles
    );
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setMultiselectedFiles([]);
    setMultiselectedUrls([]);
  };

  const loadData = async (userId, globalId) => {
    loadSessions(userId, globalId).then((session) => {
      setSession(session);

      loadImages(userId, session).then((images) => {
        setImages(images);
      });
      loadVideos(userId, session).then((videos) => {
        setVideos(videos);
      });
      loadPatients(userId, session).then((patient) => {
        setPatient(patient);
      });
    });
    // Since we need all tags, they are independent from the session query
    loadAllTags(userId).then((tags) => {
      setAllTags(tags);
    });
  };

  /**
   * Loads session from the Firestore database.
   *
   * @param {string} userId - The ID of the user.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Array} - An array of session data.
   */
  const loadSessions = async (userId, globalId) => {
    const sessionData = await firestoreRepository.getSessionById(
      userId,
      globalId
    );
    return sessionData;
  };

  /**
   * Loads images for a given user and session data.
   *
   * @param {string} userId - The ID of the user.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Object} - An object mapping session global IDs to session images.
   */
  const loadImages = async (userId, globalId) => {
    const sessionImages = await firestoreRepository.getImagesBySession(
      userId,
      globalId
    );
    return sessionImages;
  };

  /**
   * Loads videos for a given user and session data.
   *
   * @param {string} userId - The ID of the user.
   * @param {string} globalId - The global ID of the session loaded.
   * @returns {Object} - An object mapping session global IDs to session videos.
   */
  const loadVideos = async (userId, globalId) => {
    const sessionVideos = await firestoreRepository.getVideosBySession(
      userId,
      globalId
    );

    return sessionVideos;
  };

  const loadPatients = async (userId, globalId) => {
    const sessionPatient = await firestoreRepository.getPatientBySession(
      userId,
      globalId
    );
    return sessionPatient;
  };

  const loadAllTags = async (userId) => {
    const tags = await firestoreRepository.getAllTags(userId);
    return tags;
  };

  const loadUserData = async (userId) => {
    const data = await firestoreRepository.getUserData(userId);
    return data;
  };

  const openGallery = (media, mediaArray) => {
    logEvent(analytics, "file_open", {
      button_name: "file_open",
    });
    if (multiselectedUrls.includes(media.downloadURL)) {
      setMultiselectedUrls((prevUrls) =>
        prevUrls.filter((prevUrl) => prevUrl !== media.downloadURL)
      );
    } else {
      setMultiselectedUrls((prevUrls) => [...prevUrls, media.downloadURL]);
    }
    setAllFiles(mediaArray);
    setOpenedFile([media]);
    setMultiselectedFiles([]);

    if (media.globalId.includes("VIDEO")) {
      setGalleryVisible(true);
      navigate("?video");
    } else if (media.globalId.includes("IMAGE")) {
      setGalleryVisible(true);
      navigate("?image");
    } else {
      setGalleryVisible(false);
    }
  };

  return (
    <Layout>
      {largestSubscriptionId > 0 ? (
        <>
          {session.deleted === true ? (
            <Container maxWidth="lg" className="container_top">
              <div>
                <a href="/" className="back_link">
                  <IconButton size="small">
                    <ArrowBack />
                  </IconButton>
                </a>
                <div className="deleted_session">This session was deleted</div>
              </div>
            </Container>
          ) : (
            <Container maxWidth="lg" className="container_bottom">
              {multiselectedFiles.length != 0 && (
                <Multiselect
                  setDownloadFiles={setDownloadFiles}
                  setDownloadProgress={setDownloadProgress}
                  setDownloadThumbnails={setDownloadThumbnails}
                  setRemainingDownloadTime={setRemainingDownloadTime}
                  multiselectedUrls={multiselectedUrls}
                  currentUser={currentUser}
                  reloadCallback={() => setNeedReload(!needReload)}
                  multiselectedFiles={multiselectedFiles}
                  setMultiselectedFiles={setMultiselectedFiles}
                  setMultiselectedUrls={setMultiselectedUrls}
                  sessionId={id}
                  downloadingFiles={downloadingFiles}
                  setDownloadingFiles={setDownloadingFiles}
                />
              )}
              <div className="flex_container">
                <Link to="/" className="back_link">
                  <IconButton size="small">
                    <ArrowBack />
                  </IconButton>
                </Link>

                <div>
                  <div className="flex_div_center">
                    {(() => {
                      const mediaArray = [
                        ...(videos || []),
                        ...(images || []),
                      ].sort(
                        (a, b) =>
                          new Date(b.createdAt.toDate()) -
                          new Date(a.createdAt.toDate())
                      );

                      return (
                        <button
                          className="button_no_style edit_session_button"
                          value={session}
                          onClick={() => {
                            mediaArray.forEach((media) => {
                              if (!downloadingFiles.has(media.globalId)) {
                                handleDownload({
                                  name: media.globalId,
                                  url: media.downloadURL,
                                  size: media.size,
                                  thumbnail: media.thumbnailURL,
                                });
                              }
                            });
                          }}
                        >
                          <img
                            src={DownloadIconBlack}
                            className="edit_session_icon"
                          />
                        </button>
                      );
                    })()}

                    <>
                      <ModalEdition
                        key={`${patient?.patientId}-${allTags?.length}`}
                        patientName={patient ? patient.patientId : undefined}
                        description={session.description}
                        selectedTags={session.tags}
                        tags={allTags}
                        sessionId={session.globalId}
                        userId={currentUser.uid}
                        reloadCallback={() => setNeedReload(!needReload)}
                      />
                      <ModalDeletion
                        imageId={images}
                        videoId={videos}
                        sessionId={session.globalId}
                        userId={currentUser.uid}
                        reloadCallback={() => setNeedReload(!needReload)}
                      />
                    </>
                  </div>
                </div>
              </div>
              <>
                <div className="data_flex_session data_flex_session_inside">
                  <div>
                    {patient ? (
                      <p className="session_patient_id">{patient.patientId}</p>
                    ) : (
                      ""
                    )}
                    <div className="timestamp">
                      {session.createdAt?.toDate().toLocaleDateString("de-DE")}
                    </div>
                    <div className="tags_block">
                      {session.tags?.map((tag) => (
                        <div
                          className=" margin_top_wrap tags_div"
                          style={{ width: "auto" }}
                        >
                          {`${tag}`
                            ? `${tag}`.length > 25
                              ? `${tag}`.slice(0, 25) + "..."
                              : `${tag}`
                            : ""}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div>
                    <p className="comment_title">Comment</p>
                    <p className="description-wrap">
                      {session.description?.length > 0
                        ? session.description
                        : "A comment or note about a patient, photo or video description"}
                    </p>
                  </div>
                </div>
              </>
              <Upload
                setUploadProgress={setUploadProgress}
                setUploadThumbnails={setUploadThumbnails}
                setFiles={setFiles}
                setRemainingTimes={setRemainingTimes}
                currentUser={currentUser}
                reloadCallback={() => setNeedReload(!needReload)}
                renderVideo={renderVideo}
                setRenderVideo={setRenderVideo}
                sessionId={id}
                largestSubscriptionId={largestSubscriptionId}
                userData={userData}
              />
              <div className="margin_left_1">
                <FormControl>
                  <RadioGroup
                    onChange={handleChange}
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="All"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      value="All"
                      control={
                        <Radio
                          {...a11yProps(0)}
                          sx={{
                            color: "#8F4FFF",
                            "&.Mui-checked": {
                              color: "#8F4FFF",
                            },
                          }}
                        />
                      }
                      label="All"
                    />
                    <FormControlLabel
                      value="Videos"
                      control={
                        <Radio
                          {...a11yProps(1)}
                          sx={{
                            color: "#8F4FFF",
                            "&.Mui-checked": {
                              color: "#8F4FFF",
                            },
                          }}
                        />
                      }
                      label="Videos"
                    />
                    <FormControlLabel
                      value="Images"
                      control={
                        <Radio
                          {...a11yProps(2)}
                          sx={{
                            color: "#8F4FFF",
                            "&.Mui-checked": {
                              color: "#8F4FFF",
                            },
                          }}
                        />
                      }
                      label="Images"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
              {images && videos ? (
                <TabPanel
                  value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                  index={0}
                >
                  <div className="images_page">
                    {(() => {
                      const mediaArray = [
                        ...(largestSubscriptionId === 1 ? [] : videos || []),
                        ...(images || []),
                      ].sort(
                        (a, b) =>
                          new Date(b.createdAt.toDate()) -
                          new Date(a.createdAt.toDate())
                      );
                      return [...Array(mediaArray.length)].map((_, index) => {
                        const media = mediaArray[index];
                        return (
                          <React.Fragment key={`${id}_${index}`}>
                            <div>
                              <div className="hover_over">
                                <link
                                  rel="preload"
                                  as="image"
                                  href={media.thumbnailSessionURL}
                                ></link>
                                <div className="image-container">
                                  {media.globalId.includes("VIDEO") && (
                                    <div className="pause_button">
                                      <img src={pause}></img>
                                    </div>
                                  )}
                                  <img
                                    src={media.thumbnailSessionURL}
                                    alt=""
                                    className="myimg"
                                    onError={async (e) => {
                                      try {
                                        const refetchedMedia =
                                          await firestoreRepository.refetchDownloadUrls(
                                            currentUser.uid,
                                            media
                                          );
                                        if (refetchedMedia) {
                                          const isImage =
                                            refetchedMedia.globalId.includes(
                                              "IMAGE"
                                            );
                                          e.target.src =
                                            refetchedMedia.thumbnailSessionURL;
                                          if (isImage) {
                                            setImages((prevImages) => ({
                                              ...prevImages,
                                              [session.globalId]: [
                                                ...(prevImages[session.globalId]
                                                  ? prevImages[
                                                      session.globalId
                                                    ].filter(
                                                      (image) =>
                                                        image.globalId !==
                                                        media.globalId
                                                    )
                                                  : []),
                                                refetchedMedia,
                                              ],
                                            }));
                                          } else {
                                            setVideos((prevVideos) => ({
                                              ...prevVideos,
                                              [session.globalId]: [
                                                ...(prevVideos[session.globalId]
                                                  ? prevVideos[
                                                      session.globalId
                                                    ].filter(
                                                      (video) =>
                                                        video.globalId !==
                                                        media.globalId
                                                    )
                                                  : []),
                                                refetchedMedia,
                                              ],
                                            }));
                                          }
                                        }
                                      } catch (error) {
                                        console.error(
                                          "Error refreshing URL:",
                                          error
                                        );
                                      }
                                    }}
                                  />
                                  <div
                                    className="overlay"
                                    onClick={() =>
                                      openGallery(media, mediaArray)
                                    }
                                  ></div>
                                  <input
                                    type="checkbox"
                                    id="myCheckbox"
                                    checked={
                                      !!multiselectedFiles.find(
                                        (item) =>
                                          item.globalId === media.globalId
                                      )
                                    }
                                    onChange={() => {
                                      handleSelectImage(media);
                                      handleImageClick(media.downloadURL);
                                    }}
                                  />
                                  <label htmlFor="myCheckbox"></label>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        );
                      });
                    })()}
                  </div>
                </TabPanel>
              ) : (
                ""
              )}
              {videos && largestSubscriptionId === 1 ? (
                <TabPanel
                  value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                  index={1}
                >
                  <div className="mini-panel-container">
                    <img src={info} className="mini-panel-img"></img>
                    <div>
                      <div className="mini-panel-header">
                        Video uploads are available for Standard and Premium
                        Tiers.
                      </div>
                      <div>
                        To access your videos in MicroREC Connect, check out the{" "}
                        <a
                          href="/subscription/#subs"
                          className="mini-panel-link"
                        >
                          upgrade options.
                        </a>
                      </div>
                    </div>
                  </div>
                </TabPanel>
              ) : (
                <TabPanel
                  value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                  index={1}
                >
                  <div className="images_page">
                    {(() => {
                      const mediaArray = [...(videos || [])].sort(
                        (a, b) =>
                          new Date(b.createdAt.toDate()) -
                          new Date(a.createdAt.toDate())
                      );
                      return [...Array(mediaArray.length)].map((_, index) => {
                        const media = mediaArray[index];
                        return (
                          <React.Fragment key={`${id}_${index}`}>
                            <div>
                              <>
                                <div className="hover_over">
                                  <>
                                    <link
                                      rel="preload"
                                      as="image"
                                      href={media.thumbnailSessionURL}
                                    ></link>
                                    <div className="image-container">
                                      <div className="pause_button">
                                        <img src={pause}></img>
                                      </div>
                                      <img
                                        src={media.thumbnailSessionURL}
                                        alt=""
                                        className="myimg"
                                        onError={async (e) => {
                                          try {
                                            const refetchedMedia =
                                              await firestoreRepository.refetchDownloadUrls(
                                                currentUser.uid,
                                                media
                                              );

                                            setVideos((prevVideos) => ({
                                              ...prevVideos,
                                              [session.globalId]: [
                                                ...(prevVideos[session.globalId]
                                                  ? prevVideos[
                                                      session.globalId
                                                    ].filter(
                                                      (video) =>
                                                        video.globalId !==
                                                        media.globalId
                                                    )
                                                  : []),
                                                refetchedMedia,
                                              ],
                                            }));
                                          } catch (error) {
                                            console.error(
                                              "Error refreshing URL:",
                                              error
                                            );
                                          }
                                        }}
                                      ></img>
                                      <div
                                        className="overlay"
                                        onClick={() =>
                                          openGallery(media, mediaArray)
                                        }
                                      ></div>
                                      <input
                                        type="checkbox"
                                        id="myCheckbox"
                                        checked={
                                          !!multiselectedFiles.find(
                                            (item) =>
                                              item.globalId === media.globalId
                                          )
                                        }
                                        onChange={() => {
                                          handleSelectImage(media);
                                          handleImageClick(media.downloadURL);
                                        }}
                                      />
                                      <label for="myCheckbox"></label>
                                    </div>
                                  </>
                                </div>
                              </>
                            </div>
                          </React.Fragment>
                        );
                      });
                    })()}
                  </div>
                </TabPanel>
              )}
              {images ? (
                <TabPanel
                  value={value === "All" ? 0 : value === "Videos" ? 1 : 2}
                  index={2}
                >
                  <div className="images_page">
                    {(() => {
                      const mediaArray = [...(images || [])].sort(
                        (a, b) =>
                          new Date(b.createdAt.toDate()) -
                          new Date(a.createdAt.toDate())
                      );
                      return [...Array(mediaArray.length)].map((_, index) => {
                        const media = mediaArray[index];
                        return (
                          <React.Fragment key={`${id}_${index}`}>
                            <div>
                              <>
                                <div className="hover_over">
                                  <>
                                    <link
                                      rel="preload"
                                      as="image"
                                      href={media.thumbnailSessionURL}
                                    ></link>
                                    <div className="image-container">
                                      <img
                                        src={media.thumbnailSessionURL}
                                        alt=""
                                        className="myimg"
                                        onError={async (e) => {
                                          try {
                                            const refetchedMedia =
                                              await firestoreRepository.refetchDownloadUrls(
                                                currentUser.uid,
                                                media
                                              );
                                            setImages((prevImages) => ({
                                              ...prevImages,
                                              [session.globalId]: [
                                                ...(prevImages[session.globalId]
                                                  ? prevImages[
                                                      session.globalId
                                                    ].filter(
                                                      (image) =>
                                                        image.globalId !==
                                                        media.globalId
                                                    )
                                                  : []),
                                                refetchedMedia,
                                              ],
                                            }));
                                          } catch (error) {
                                            console.error(
                                              "Error refreshing URL:",
                                              error
                                            );
                                          }
                                        }}
                                      ></img>
                                      <div
                                        className="overlay"
                                        onClick={() =>
                                          openGallery(media, mediaArray)
                                        }
                                      ></div>
                                      <input
                                        type="checkbox"
                                        id="myCheckbox"
                                        checked={
                                          !!multiselectedFiles.find(
                                            (item) =>
                                              item.globalId === media.globalId
                                          )
                                        }
                                        onChange={() => {
                                          handleSelectImage(media);
                                          handleImageClick(media.downloadURL);
                                        }}
                                      />
                                      <label htmlFor="myCheckbox"></label>
                                    </div>
                                  </>
                                </div>
                              </>
                            </div>
                          </React.Fragment>
                        );
                      });
                    })()}
                  </div>
                </TabPanel>
              ) : (
                ""
              )}
              {galleryVisible ? (
                <Gallery
                  sessionId={id}
                  galleryVisible={galleryVisible}
                  currentUser={currentUser}
                  reloadCallback={() => setNeedReload(!needReload)}
                  openedFile={openedFile}
                  setOpenedFile={setOpenedFile}
                  setGalleryVisible={setGalleryVisible}
                  multiselectedUrls={multiselectedUrls}
                  multiselectedFiles={multiselectedFiles}
                  setMultiselectedFiles={setMultiselectedFiles}
                  setMultiselectedUrls={setMultiselectedUrls}
                  allFiles={allFiles}
                  downloadingFiles={downloadingFiles}
                  setDownloadingFiles={setDownloadingFiles}
                />
              ) : (
                ""
              )}
            </Container>
          )}
        </>
      ) : (
        <FreeUser />
      )}
    </Layout>
  );
}
