import { IconButton, Tooltip } from "@mui/material";
import { Close, ExpandLess, ExpandMore, GridView } from "@mui/icons-material";
import { NavLink } from "react-router-dom";
import success_upload from "../../images/upload/success_upload.svg";
import select from "../../images/upload/Select.svg";
import error_img from "../dashboard/error_storage.svg";
import { UploadContext } from "../App";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

const UploadModal = () => {
  const { files, setFiles } = useContext(UploadContext);
  const { uploadProgress, setUploadProgress } = useContext(UploadContext);
  const { renderVideo, setRenderVideo } = useContext(UploadContext);
  const { sessionId } = useContext(UploadContext);
  const [hover, setHover] = useState({});
  const { uploadThumbnails } = useContext(UploadContext);
  const { remainingTimes, setRemainingTimes } = useContext(UploadContext);
  const [statusMessage, setStatusMessage] = useState("Starting upload...");
  const [collapsedUpload, setCollapsedUpload] = useState(() => {
    const storedValue = localStorage.getItem("isCollapsed");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const { closeUpload, setCloseUpload } = useContext(UploadContext);

  const [completedFiles, setCompletedFiles] = useState([]);

  useEffect(() => {
    const completed = Object.values(uploadProgress).filter(
      ({ progress }) => progress === 100
    );
    setCompletedFiles(completed);
  }, [uploadProgress]);

  useEffect(() => {
    const values = Object.values(remainingTimes);
    const maxTime = values.length > 0 ? Math.max(...values) : 0;
    if (maxTime <= 0) {
      setStatusMessage("Less than a minute left...");
    } else if (maxTime === 1) {
      setStatusMessage("1 minute left...");
    } else {
      setStatusMessage(`${maxTime} minutes left...`);
    }
  }, [remainingTimes]);

  useEffect(() => {
    localStorage.setItem("isCollapsed", JSON.stringify(collapsedUpload));
  }, [collapsedUpload]);

  useEffect(() => {
    if (files.length !== 0) {
      setCloseUpload(false);
    }
    localStorage.setItem("isClosed", JSON.stringify(closeUpload));
  }, [closeUpload, files]);

  function CircularProgress({ progress }) {
    const radius = 10;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        style={{ transform: "rotate(-90deg)" }}
      >
        <circle
          stroke="#E1AFD1"
          fill="transparent"
          strokeWidth="4"
          r={radius}
          cx="12"
          cy="12"
        />
        <circle
          stroke="#8f4fff"
          fill="transparent"
          strokeWidth="4"
          strokeDasharray={circumference}
          style={{ strokeDashoffset }}
          r={radius}
          cx="12"
          cy="12"
        />
      </svg>
    );
  }
  return (
    <>
      {!closeUpload && uploadProgress.length !== 0 ? (
        <div className="border-div">
          <div>
            <div className="flex-space-between">
              <div className="bold-text">Uploads</div>
              <div className="flex-center">
                <IconButton
                  className="button_no_style"
                  onClick={() => setCollapsedUpload(!collapsedUpload)}
                >
                  {!collapsedUpload && uploadProgress.length !== 0 ? (
                    <ExpandMore
                      fontSize="medium"
                      style={{ color: "#333333" }}
                    />
                  ) : (
                    <ExpandLess
                      fontSize="medium"
                      style={{ color: "#333333" }}
                    />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => {
                    setCloseUpload(true);
                    setUploadProgress([]);
                  }}
                >
                  <Close fontSize="medium" style={{ color: "#333333" }} />
                </IconButton>
              </div>
            </div>
            <hr className="custom-hr"></hr>
            <div className="flex-center-bg">
              {completedFiles.length > 0 &&
              Object.values(uploadProgress).every(
                ({ progress }) => progress === 100
              ) ? (
                <>
                  <img
                    src={success_upload}
                    className="fixed-width-img-40"
                  ></img>
                  <div className="padding-10-font-15">
                    {completedFiles.length > 0 &&
                      `${completedFiles.length} out of ${
                        Object.keys(uploadProgress).length
                      } ${
                        completedFiles.length > 1 ? "items" : "item"
                      } uploaded successfully!`}
                  </div>
                </>
              ) : (
                <div className="padding-left-25">
                  {Object.keys(remainingTimes).length === 0
                    ? "Starting upload..."
                    : statusMessage}
                </div>
              )}
            </div>
          </div>
          {collapsedUpload ? (
            ""
          ) : (
            <div>
              <div className="upload_progress scrollable-div">
                {Object.entries(uploadProgress).map(
                  ([fileName, { progress, sessionId, error }]) => {
                    return (
                      <div
                        key={fileName}
                        className="flex-wrap-center"
                        style={{
                          backgroundColor: hover[fileName]
                            ? "#F6F6F6"
                            : "white",
                        }}
                        onMouseEnter={() =>
                          setHover((prevHover) => ({
                            ...prevHover,
                            [fileName]: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setHover((prevHover) => ({
                            ...prevHover,
                            [fileName]: false,
                          }))
                        }
                      >
                        {fileName.endsWith(".MP4") ||
                        fileName.endsWith(".mp4") ? (
                          <div className="relative-inline-block">
                            <video
                              src={uploadThumbnails[fileName]}
                              className="thumbnail_image"
                            ></video>
                            <svg className="play_button" viewBox="0 0 24 24">
                              <path d="M8 5v14l11-7z" />
                              <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                          </div>
                        ) : (
                          <img
                            src={uploadThumbnails[fileName]}
                            className="thumbnail_image"
                          />
                        )}
                        <div className="margin-right-left-1em">
                          <Tooltip title={fileName || ""}>
                            <div
                              style={{
                                color: error?.length > 0 ? "#FF284F" : "#333",
                              }}
                            >
                              {fileName.length > 20
                                ? fileName.substring(0, 20) + "..."
                                : fileName}
                            </div>
                          </Tooltip>
                          {error?.length > 0 ? (
                            <div className="font-13-color-dark">
                              Upload failed. Please try again
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="margin-left-auto">
                          {error?.length > 0 ? (
                            <img src={error_img}></img>
                          ) : (
                            <>
                              {progress === 100 ? (
                                hover[fileName] ? (
                                  <Tooltip title="Go to file location">
                                    <Link
                                      to={`/sessions/${sessionId}`}
                                      style={{ color: "#333333" }}
                                    >
                                      <GridView fontSize="medium" />
                                    </Link>
                                  </Tooltip>
                                ) : (
                                  <img
                                    src={select}
                                    className="fixed-width-img-24"
                                  ></img>
                                )
                              ) : (
                                <CircularProgress progress={progress} />
                              )}
                            </>
                          )}{" "}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default UploadModal;
