import React, { useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import { useAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import bg1 from "../../images/login/bg1.svg";
import bg2 from "../../images/login/bg2.svg";
import logo from "../../images/dashboard/MicroREC Connect Logo (1).png";
import "../../style/login.css";
import { auth, db } from "../../firebase";
import { useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";

export default function Login() {
  const [usersV2, setUsersV2] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (auth.currentUser && auth.currentUser.uid) {
      const fetchDocument = async () => {
        setIsLoading(true);
        const docRef = doc(db, "usersV2", auth.currentUser.uid);
        const docSnapV2 = await getDoc(docRef);
        if (docSnapV2.exists()) {
          setUsersV2(docSnapV2.data());
        }
        setIsLoading(false);
      };
      fetchDocument();
    } else {
    }
  }, []);

  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get("code");
  const information = code ? `/information?code=${code}` : "/information";
  const dashboard = code ? `?code=${code}` : "/";

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const credential = await auth.signInWithEmailAndPassword(
        emailRef.current.value,
        passwordRef.current.value
      );
      await credential.user.reload();
      if (credential.user.emailVerified === false) {
        setError("You must verify your email first");
        return;
      }
      const docRef = doc(db, "usersV2", auth.currentUser.uid);
      const docSnapV2 = await getDoc(docRef);

      if (docSnapV2.exists()) {
        const userData = docSnapV2.data();

        if (
          userData.country !== "" &&
          userData.speciality !== "" &&
          userData.firstName !== ""
        ) {
          navigate(dashboard);
        } else {
          navigate(information);
        }
      }
    } catch (error) {
      if (error.code === "auth/user-not-found") {
        setError(
          "Error logging in: The user does not exist. Please sign up first."
        );
      } else if (error.code === "auth/wrong-password") {
        setError(
          "Error logging in: The password is incorrect. Please try again."
        );
      } else {
        setError(
          "Error logging in: The user does not exist or has been deleted"
        );
      }
    }
  };

  if (!isMounted) {
    return null;
  }

  if (isLoading) {
  }

  if (!usersV2) {
  }

  return (
    <>
      <div className="login_main">
        <div className="login">
          <div className="bg_login">
            <img src={bg1}></img>
            <img src={bg2}></img>
          </div>
          <div className="login_content">
            <img src={logo} className="logo_login"></img>
            <h2
              style={{
                textAlign: "center",
                marginBottom: "1em",
                fontWeight: "600",
                color: "#3D3838",
              }}
            >
              Enter Your Password
            </h2>
            {error && <Alert severity="error">{error}</Alert>}
            <form onSubmit={handleSubmit}>
              <div id="email">
                <input
                  type="email"
                  ref={emailRef}
                  placeholder="Email"
                  required
                  className="email-input"
                ></input>
              </div>
              <div id="password">
                <input
                  type="password"
                  ref={passwordRef}
                  placeholder="Password"
                  required
                  className="email-input"
                ></input>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <button
                  disabled={loading}
                  type="submit"
                  className="login-button"
                >
                  Continue
                </button>
              </div>
              <div className="link_forgot" style={{ marginTop: "1em" }}>
                {code ? (
                  <a href={`/forgot-password?code=${code}`}>
                    Forgot your password?
                  </a>
                ) : (
                  <a href="/forgot-password">Forgot your password?</a>
                )}{" "}
              </div>
              <div
                style={{
                  marginTop: "1em",
                  textAlign: "center",
                  color: "#797979",
                }}
              >
                No account?{" "}
                {code ? (
                  <a href={`/login?code=${code}`}>Create one</a>
                ) : (
                  <a href="/login"> Create one</a>
                )}{" "}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
