import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem } from "@mui/material";
import { doc, updateDoc, serverTimestamp, getDoc } from "@firebase/firestore";
import { db } from "../../firebase";
import DeleteIcon from "../../images/dashboard/delete.svg";
import firestoreRepository from "../../repositories/firestoreRepository";
import DeleteIconBlack from "../../images/gallery/delete_black.svg";
import { useLocation } from "react-router-dom";
import "../dashboard/Dashboard.css";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ModalDeleteSession = ({
  sessionId,
  userId,
  imageId,
  videoId,
  reloadCallback,
}) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);

  const deleteSession = async (id) => {
    const docRef = doc(db, `usersV2/${userId}/sessions/`, id);
    await updateDoc(docRef, {
      deleted: true,
      modifiedAt: serverTimestamp(),
    });
    await firestoreRepository.updateLastDeviceSync(userId);
  };

  const deleteImages = async (ids) => {
    for (const id of ids) {
      const docRef = doc(db, `usersV2/${userId}/images/`, id.globalId);
      await updateDoc(docRef, {
        deleted: true,
        modifiedAt: serverTimestamp(),
      });
    }
  };

  const deleteVideos = async (ids) => {
    for (const id of ids) {
      const docRef = doc(db, `usersV2/${userId}/videos/`, id.globalId);
      await updateDoc(docRef, {
        deleted: true,
        modifiedAt: serverTimestamp(),
      });
    }
  };

  const location = useLocation();
  const isSessionUrl = location.pathname.includes("sessions");

  return (
    <>
      <div>
        {isSessionUrl ? (
          <button
            className="button_no_style edit_session_button"
            onClick={() => setOpen(true)}
          >
            <img src={DeleteIconBlack} className="edit_session_icon" />
          </button>
        ) : (
          <MenuItem
            style={{
              display: "flex",
              borderTop: "1px solid #C4C4C4",
              justifyContent: "space-between",
            }}
            onClick={() => setOpen(true)}
          >
            <div className="menu_edit_button_text">Delete</div>
            <img src={DeleteIcon} className="menu_edit_button_icon" />
          </MenuItem>
        )}
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"sm"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box style={{ fontSize: "18px" }}>
                <div className="modal_header_div">
                  <div className="modal_subheader_div">
                    <div className="modal_subheader_text">Delete Session</div>
                  </div>
                  <p className="modal_deletion_text">
                    Are you sure you want to delete this session?
                  </p>
                  <div className="modal_deletion_buttons_div">
                    <button
                      className="button_no_style modal_deletion_button_cancel"
                      onClick={() => {
                        handleClose();
                      }}
                    >
                      Cancel
                    </button>

                    <button
                      className="button_no_style modal_deletion_button_delete"
                      onClick={async () => {
                        await Promise.all([
                          deleteImages(imageId),
                          deleteVideos(videoId),
                          deleteSession(sessionId),
                        ]);

                        setTimeout(() => {
                          reloadCallback();
                          handleClose();
                        }, 200);

                        if (isSessionUrl) {
                          window.location.href = "/";
                        }
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default ModalDeleteSession;
