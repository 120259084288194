import React, { useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box, MenuItem } from "@mui/material";
import { auth, db } from "../../firebase";
import { EmailAuthProvider } from "firebase/auth";
import Alert from "@mui/material/Alert";
import { deleteDoc, doc } from "@firebase/firestore";
import { useNavigate } from "react-router";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const DeleteAccount = ({ usersV2 }) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const handleDeleteAccount = async () => {
    try {
      const user = auth.currentUser;
      const credentials = EmailAuthProvider.credential(email, currentPassword);

      if (user) {
        // Reauthenticate the user first
        if (
          usersV2.signinMethod !== "google" &&
          usersV2.signinMethod !== "apple"
        ) {
          await user.reauthenticateWithCredential(credentials);
        }

        // Delete the Firestore document
        await deleteDoc(doc(db, "usersV2", user.uid));

        // Delete the Firebase Authentication account
        await user.delete();

        // Sign the user out
        await auth.signOut();

        // Redirect to the login page or any other desired destination
        navigate("/login");

        console.log("Account deleted successfully.");
      } else {
        setError("Email or password don't match");
      }
    } catch (error) {
      setError("Error deleting account: " + error.message);
    }
  };

  return (
    <>
      <div>
        <MenuItem
          style={{
            color: "#8F4FFF",
            textDecoration: "underline",
            fontWeight: "700",
            padding: "0",
            justifyContent: "end",
          }}
          onClick={() => setOpen(true)}
        >
          Delete Account
        </MenuItem>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"sm"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "30px 20px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#FDFDFD",
                      padding: "30px 0px",
                    }}
                  >
                    <label
                      className="label"
                      style={{
                        color: "#8F4FFF",
                        textAlign: "center",
                      }}
                    >
                      Delete this Account?
                    </label>
                    <div
                      style={{
                        marginTop: "2em",
                        marginBottom: "3em",
                      }}
                    >
                      All user's data and media files from cloud storage will be
                      deleted.
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "1em",
                      }}
                    >
                      <button
                        className="button_no_style"
                        onClick={() => {
                          handleClose();
                        }}
                        style={{ fontSize: "16px" }}
                      >
                        Cancel
                      </button>

                      <button
                        className="button_no_style"
                        style={{
                          fontWeight: "600",
                          color: "#8F4FFF",
                          fontSize: "16px",
                        }}
                        onClick={() => {
                          handleDeleteAccount();
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default DeleteAccount;
