import React, { useState } from "react";
import devices from "../../images/dashboard/devices.png";
import "../../style/dashboard.css";
import { useAuth } from "../../context/AuthContext";
import freeUser from "../../images/dashboard/freeUser.png";
import { useLayoutEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import example from "../../images/dashboard/image (1).png";
import storage from "../../images/dashboard/Upload (1).png";
import safe from "../../images/dashboard/Secure (1).png";
import organize from "../../images/dashboard/Organize (1).png";
import platform from "../../images/dashboard/Devices (1).png";
import Activate from "../subscription/activation";

const FreeUser = () => {
  const { currentUser } = useAuth();
  const [usersV2, setUsersV2] = useState(null);

  useLayoutEffect(() => {
    const fetchDocument = async () => {
      const docRef = doc(db, "usersV2", currentUser.uid);
      const docSnapV2 = await getDoc(docRef);
      if (docSnapV2.exists()) {
        setUsersV2(docSnapV2.data());
      }
    };
    fetchDocument();
  }, []);

  return (
    <>
      {usersV2 && (
        <>
          <div className="container">
            <div className="content-overlay">
              <div className="content">
                <h1 style={{ fontWeight: "600", marginTop: "0em" }}>
                  DATA MANAGEMENT
                </h1>
                <h1 style={{ fontWeight: "600", marginTop: "0em" }}>
                  Made easy
                </h1>
                <p style={{ fontSize: "20px" }}>
                  Your data is kept safe and secure, access it from{" "}
                  <b>any device, any time from anywhere.</b>
                </p>
                <div style={{ display: "flex" }}>
                  <a
                    href="#subs"
                    style={{
                      backgroundColor: "#8F4FFF",
                      border: "solid 1px white",
                      padding: "10px 20px",
                      borderRadius: "20px",
                      fontWeight: "600",
                      fontSize: "20px",
                      textDecoration: "none",
                      color: "white",
                      marginTop: "1em",
                      marginRight: "1em",
                    }}
                  >
                    Get it now
                  </a>
                  <Activate />
                </div>
              </div>
              <img src={devices} alt="Devices" style={{ maxWidth: "40%" }} />
            </div>
            <img src={freeUser} alt="Free User" className="freeuser_banner" />
          </div>

          <h1
            style={{ marginTop: "1em", textAlign: "center", padding: "20px" }}
          >
            Focus on deliverying exceptional care while we take care of your
            data needs!
          </h1>
          <div className="flex_free_banner">
            <div className="flex_image">
              <img src={example} style={{ maxWidth: "100%" }}></img>
            </div>
            <div className="flex_text_block">
              <div className="flex_block">
                <div className="flex_sub_block">
                  <img style={{ width: "40px" }} src={storage}></img>
                  <h3>Upload and store videos and images</h3>
                  <div>Never worry about running out of phone storage.</div>
                </div>
                <div className="flex_sub_block">
                  <img style={{ width: "40px" }} src={safe}></img>
                  <h3>Keep your data secure</h3>
                  <div>
                    Keep your data safeguarded against any potential threats.
                  </div>
                </div>
              </div>
              <div className="flex_block">
                <div className="flex_sub_block">
                  <img style={{ width: "40px" }} src={organize}></img>
                  <h3>Manage and organize</h3>
                  <div>
                    Manage your media within a single, convenient platform.
                  </div>
                </div>
                <div className="flex_sub_block">
                  <img style={{ width: "40px" }} src={platform}></img>
                  <h3>Easily access</h3>
                  <div>
                    Access your data on any device, from anywhere, at any time.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="subs"></div>

          <div className="pricing_table_border">
            <div className="pricing_table_text">
              Unlock the ease of data management with MicroREC Connect.
            </div>

            <stripe-pricing-table
              pricing-table-id={process.env.REACT_APP_STRIPE_TABLE_ID}
              publishable-key={process.env.REACT_APP_STRIPE_PUBLISH_KEY}
              client-reference-id={usersV2.uid}
              customer={usersV2.stripeCustomerId}
              customer-email={usersV2.email}
              customer-creation="if_required"
            ></stripe-pricing-table>
          </div>
        </>
      )}
    </>
  );
};

export default FreeUser;
