import React, { useEffect, useState } from "react";
import "../../style/dashboard.css";
import Layout from "../Layout";
import { Container } from "@mui/system";
import axios from "axios";
import { useAuth } from "../../context/AuthContext";
import Activate from "./activation";
import activation from "../../images/login/activation.svg";
import info from "./info.svg";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import MobileSubs from "./mobile_subs";
import { HashLink } from "react-router-hash-link";
import { Box, Grid } from "@mui/material";

const SubsPage = () => {
  const [loading, setLoading] = useState(false);
  const [authToken, setAuthToken] = useState(null);
  const { currentUser } = useAuth();
  const [usersV2, setUsersV2] = useState(null);
  const [largestSubscriptionId, setLargestSubscriptionId] = useState();

  useEffect(() => {
    const fetchDocument = async () => {
      const docRef = doc(db, "usersV2", currentUser.uid);
      const docSnapV2 = await getDoc(docRef);
      if (docSnapV2.exists()) {
        setUsersV2(docSnapV2.data());
        setLargestSubscriptionId(
          Math.max(
            docSnapV2.data().stripeSubscriptionId || 0,
            docSnapV2.data().iosSubscriptionId || 0,
            docSnapV2.data().androidSubscriptionId || 0,
            docSnapV2.data().premiumAndroid ? 2 : 0,
            docSnapV2.data().premiumIOS ? 2 : 0
          )
        );
      }
    };
    fetchDocument();
  }, [currentUser.uid]);

  useEffect(() => {
    // Check if the user is authenticated
    if (currentUser) {
      currentUser
        .getIdToken(/* forceRefresh */ true)
        .then((token) => {
          // Token obtained successfully
          setAuthToken(token);
        })
        .catch((error) => {
          console.error("Error getting authentication token:", error);
        });
    }
  }, [currentUser]);

  const createBillingPortalSession = async () => {
    if (!authToken) {
      console.error("User is not authenticated.");
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/createStripeBillingPortalConfig`,

        {
          // Any data you want to send to the Cloud Function
          // For example, you can send an empty object if no data is needed.
        },
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      handleBillingPortalClick(response.data.portalSession);
    } catch (error) {
      console.error(error);
    } finally {
      // Hide the loading indicator whether the request succeeds or fails
      setLoading(false);
    }
  };

  const handleBillingPortalClick = (billingPortalUrl) => {
    if (billingPortalUrl) {
      window.location.href = billingPortalUrl;
    }
  };

  return (
    <Layout>
      <Container maxWidth="lg" style={{ marginTop: "2em" }}>
        {usersV2 && (
          <>
            <>
              <div style={{ fontWeight: "700", fontSize: "20px" }}>
                Subscription{" "}
              </div>
              <div style={{ marginTop: "2em", fontWeight: "600" }}>
                CURRENT PLAN{" "}
              </div>
              <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
              <Grid
                container
                justifyContent={"space-between"}
                style={{
                  margin: "2em 0",
                }}
              >
                <Grid item sx={12} sm={5} md={5}>
                  {largestSubscriptionId === 0 ? (
                    <div
                      style={{
                        padding: "30px 0",
                        width: "100%",
                        borderRadius: "20px",
                        fontWeight: "700",
                        boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                        margin: "2.5em 0",
                        textAlign: "center",
                        color: "#999999",
                      }}
                    >
                      You don’t have any subscription
                    </div>
                  ) : (
                    <div
                      style={{
                        padding: "30px 0",
                        width: "100%",
                        borderRadius: "20px",
                        fontWeight: "700",
                        boxShadow: "0px 2px 8px 0px rgba(58, 92, 157, 0.14)",
                        margin: "2.5em 0",
                        textAlign: "center",
                      }}
                    >
                      {largestSubscriptionId === 2 ||
                      largestSubscriptionId === 9
                        ? "MicroREC Connect - Standard"
                        : ""}
                      {largestSubscriptionId === 1
                        ? "MicroREC Connect - Mini"
                        : ""}
                      {largestSubscriptionId === 3
                        ? "MicroREC Connect - Premium"
                        : ""}
                    </div>
                  )}

                  {largestSubscriptionId === 0 ? (
                    <HashLink
                      style={{
                        padding: "15px 40px",
                        color: "white",
                        backgroundColor: "#8F4FFF",
                        borderRadius: "20px",
                        fontSize: "16px",
                        display: "flex",
                        alignItems: "center",
                        textDecoration: "none",
                        fontWeight: "700",
                        justifyContent: "center",
                        marginBottom: "2.5em",
                      }}
                      to="/subscription#subs"
                    >
                      Check Subscription Plans
                    </HashLink>
                  ) : (
                    <>
                      {usersV2.premiumIOS === true ||
                      usersV2.premiumAndroid === true ||
                      usersV2.iosSubscriptionId >
                        usersV2.stripeSubscriptionId ||
                      usersV2.androidSubscriptionId >
                        usersV2.stripeSubscriptionId ? (
                        <HashLink
                          to="/subscription#subs"
                          style={{
                            padding: "15px 40px",
                            color: "white",
                            backgroundColor: "#8F4FFF",
                            borderRadius: "20px",
                            fontSize: "16px",
                            display: "flex",
                            alignItems: "center",
                            textDecoration: "none",
                            fontWeight: "700",
                            justifyContent: "center",
                            marginBottom: "2.5em",
                          }}
                        >
                          Check Subscription Plans
                        </HashLink>
                      ) : (
                        <>
                          <button
                            style={{ width: "100%" }}
                            className="button_no_style"
                            onClick={createBillingPortalSession}
                          >
                            <div
                              style={{
                                padding: "15px 40px",
                                color: "white",
                                backgroundColor: "#8F4FFF",
                                borderRadius: "20px",
                                fontSize: "16px",
                                display: "flex",
                                alignItems: "center",
                                textDecoration: "none",
                                fontWeight: "700",
                                justifyContent: "center",
                                marginBottom: "2.5em",
                              }}
                            >
                              {loading ? "Loading..." : "Manage subscription"}
                            </div>
                          </button>{" "}
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <div>Want to upgrade?</div>
                            <HashLink
                              style={{
                                color: "#8F4FFF",
                                textDecoration: "underline",
                                fontWeight: "700",
                              }}
                              to="/subscription/#subs"
                            >
                              Check plans
                            </HashLink>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </Grid>{" "}
                {largestSubscriptionId === 0 ? (
                  ""
                ) : (
                  <>
                    <Box
                      sx={{ display: { xs: "none", sm: "block" } }}
                      style={{
                        backgroundColor: "#D9D9D9",
                        width: "2px",
                      }}
                    ></Box>
                    <Grid
                      xs={12}
                      sm={5}
                      md={5}
                      sx={{ marginTop: { xs: "2em", sm: "0" } }}
                    >
                      <ul style={{ padding: "0" }}>
                        What benefits are included:
                      </ul>
                      {largestSubscriptionId === 2 ||
                      largestSubscriptionId === 9 ? (
                        <li>500 GB of cloud storage.</li>
                      ) : (
                        ""
                      )}
                      {largestSubscriptionId === 1 ? (
                        <li>10 GB of cloud storage.</li>
                      ) : (
                        ""
                      )}
                      {largestSubscriptionId === 3 ? (
                        <li>2 TB of cloud storage.</li>
                      ) : (
                        ""
                      )}
                      <li style={{ marginTop: "1em" }}>Multi-device access.</li>
                      <li style={{ marginTop: "1em" }}>
                        Unlimited link share of images and video.
                      </li>
                      <li style={{ marginTop: "1em" }}>
                        In-app video edition.
                      </li>
                    </Grid>
                  </>
                )}
              </Grid>
              {usersV2.premiumIOS === true ||
              usersV2.premiumAndroid === true ||
              usersV2.iosSubscriptionId > usersV2.stripeSubscriptionId ||
              usersV2.androidSubscriptionId > usersV2.stripeSubscriptionId ? (
                <Grid
                  container
                  justifyContent={{ xs: "space-between", md: "center" }}
                  style={{
                    marginTop: "1em",
                    backgroundColor: "#E6D8FF",
                    padding: "10px",
                    borderRadius: "10px",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={1} md={1}>
                    <img src={info}></img>
                  </Grid>
                  <Grid item xs={9} md={7}>
                    <div style={{ fontWeight: "700", marginBottom: "0.5em" }}>
                      What happens if you already have a subscription with the
                      App or Play Store?
                    </div>{" "}
                    <div>
                      You can always cancel those subscriptions, and subscribe
                      here to get the best prices.
                    </div>{" "}
                  </Grid>{" "}
                  <Grid item xs={12} md={3} marginTop={{ xs: "1em", md: "0" }}>
                    <MobileSubs />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <div
                style={{ display: "flex", marginTop: "2em", fontWeight: "600" }}
              >
                <img src={activation} style={{ marginRight: "0.5em" }}></img>
                <div>ACTIVATE LICENSE CODE</div>
              </div>{" "}
              <hr style={{ backgroundColor: "#D9D9D9" }}></hr>
              <Grid
                container
                justifyContent={"space-between"}
                style={{ marginTop: "1em" }}
              >
                <Grid item xs={12} md={5} style={{ marginBottom: "1em" }}>
                  <div>
                    If you have a license activation code for MicroREC Connect,
                    you can activate it here.
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <Activate />
                </Grid>
              </Grid>
            </>
          </>
        )}
      </Container>
    </Layout>
  );
};

export default SubsPage;
