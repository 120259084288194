import axios from "axios";

/************** SUBSCRIPTIONDATA ******************/
export async function getSubscriptionData(authToken) {
  let subscriptionData = {
    hasActiveSubscription: null,
    validPaymentMethod: null,
    currentPeriodEnd: null,
  };

  if (authToken) {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/getSubscriptionDetails`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
            // "Content-Type": "application/json",
          },
        }
      );
      // Handle the response
      subscriptionData.hasActiveSubscription =
        response.data.hasActiveSubscription;
      subscriptionData.validPaymentMethod = response.data.validPaymentMethod;
      const originalDate = new Date(response.data.currentPeriodEnd);
      const formattedDate = originalDate.toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      subscriptionData.currentPeriodEnd = formattedDate;
    } catch (error) {
      // Handle errors
      console.error("Error getting subscription data:", error);
    }
  }
  return subscriptionData;
}

export async function getIntercomHash(authToken) {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_CLOUD_FUNCTION_URL}/generateIntercomHash`,
      {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error getting intercom hash:", error);
  }
}

export default {
  getSubscriptionData,
  getIntercomHash,
};
