import React, { useContext, useEffect, useRef, useState } from "react";
import "../../style/sessionDetailedView.css";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import DownloadIcon from "../../images/gallery/download.svg";
import DownloadIconDisabled from "../../images/gallery/DownloadIconDisabled.svg";
import { IconButton, Tooltip } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { ArrowBack, ArrowBackIosNew } from "@mui/icons-material";
import Controls from "./zoom";
import Share from "../common/ModalShareFile";
import Delete from "../common/ModalDeleteFiles";
import VisibilityIcon from "../../images/gallery/odos.svg";
import { useNavigate } from "react-router-dom";
import { downloadFileWithProgress } from "./downloadFile";
import { UploadContext } from "../App";
import firestoreRepository from "../../repositories/firestoreRepository";

const DetailedView = ({
  sessionId,
  galleryVisible,
  multiselectedUrls,
  setMultiselectedUrls,
  currentUser,
  reloadCallback,
  openedFile,
  setOpenedFile,
  setGalleryVisible,
  setMultiselectedFiles,
  multiselectedFiles,
  allFiles,
  downloadingFiles,
  setDownloadingFiles,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [zoomInClicked, setZoomInClicked] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const topBar = useRef(null);
  const previousBtn = useRef(null);
  const nextBtn = useRef(null);
  const fileCounter = useRef(null);
  const resetTransformRef = useRef(null);
  const navigate = useNavigate();
  const hasPreviousFiles = currentIndex > 0;
  const hasNextFiles = currentIndex < allFiles.length - 1;

  const {
    files,
    downloadFiles,
    setDownloadFiles,
    uploadProgress,
    downloadProgress,
    setDownloadProgress,
    downloadThumbnails,
    setDownloadThumbnails,
    remainingTimes,
    remainingDownloadTime,
    setRemainingDownloadTime,
  } = useContext(UploadContext);

  const handleDownload = (file) => {
    downloadFileWithProgress(
      file,
      setDownloadFiles,
      setDownloadProgress,
      setDownloadThumbnails,
      setRemainingDownloadTime,
      downloadingFiles,
      setDownloadingFiles
    );
  };

  useEffect(() => {
    let timer;
    const resetTimer = () => {
      if (timer) {
        clearTimeout(timer);
        if (topBar.current) topBar.current.classList.remove("fade-out");
        if (previousBtn.current)
          previousBtn.current.classList.remove("fade-out");
        if (nextBtn.current) nextBtn.current.classList.remove("fade-out");
        if (fileCounter.current)
          fileCounter.current.classList.remove("fade-out");
      }
      timer = setTimeout(() => {
        if (topBar.current) topBar.current.classList.add("fade-out");
        if (previousBtn.current) previousBtn.current.classList.add("fade-out");
        if (nextBtn.current) nextBtn.current.classList.add("fade-out");
        if (fileCounter.current) fileCounter.current.classList.add("fade-out");
      }, 7000);
    };

    window.addEventListener("mousemove", resetTimer);
    resetTimer();

    return () => {
      window.removeEventListener("mousemove", resetTimer);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, []);

  useEffect(() => {
    const initialIndex = allFiles.findIndex(
      (file) => file.downloadURL === openedFile[0].downloadURL
    );
    setCurrentIndex(initialIndex);
  }, [openedFile, allFiles]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      switch (event.key) {
        case "ArrowRight":
          handleNextFile();
          resetTransformRef.current && resetTransformRef.current();
          break;
        case "ArrowLeft":
          handlePreviousFile();
          resetTransformRef.current && resetTransformRef.current();
          break;
        default:
          break;
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);

  useEffect(() => {
    const onBackButtonEvent = (e) => {
      e.preventDefault();
      closeDetailedView();
    };

    window.addEventListener("popstate", onBackButtonEvent);

    return () => {
      window.removeEventListener("popstate", onBackButtonEvent);
    };
  }, []);

  const handleNextFile = (utils) => {
    if (currentIndex < allFiles.length - 1) {
      const newIndex = currentIndex + 1;
      setCurrentIndex(newIndex);
      setOpenedFile([allFiles[newIndex]]);
      if (utils) {
        utils.resetTransform();
      }
      setZoomLevel(1);
    }
  };

  const handlePreviousFile = (utils) => {
    if (currentIndex > 0) {
      const newIndex = currentIndex - 1;
      setCurrentIndex(newIndex);
      setOpenedFile([allFiles[newIndex]]);
      if (utils) {
        utils.resetTransform();
      }
      setZoomLevel(1);
    }
  };

  const closeDetailedView = () => {
    setGalleryVisible(false);
    navigate(`/sessions/${sessionId}`);
  };

  const closeGallery = (utils) => {
    closeDetailedView();
    utils.resetTransform();
    setZoomLevel(1);
  };

  return (
    <div>
      <TransformWrapper
        onZoom={(props) => {
          setZoomLevel(props.state.scale);
        }}
        panning={{
          disabled: zoomLevel <= 1 ? true : false,
        }}
        wheel={{
          step: 300,
          wheelEnabled: true,
          touchPadEnabled: true,
          limitsOnWheel: false,
        }}
        pinch={{ step: 100 }}
        onDoubleClick={({ scale, setTransform }) => {
          const maxScale = 2;
          const minScale = 1;
          if (scale === maxScale) {
            setTransform(minScale, 0, 0);
          }
        }}
      >
        {(utils) => (
          (resetTransformRef.current = utils.resetTransform),
          (
            <div className="gallery_overlay right_0">
              <div className="gallery">
                <div ref={topBar} className="top_bar">
                  <button
                    className="close-button button_no_style back_link white_button"
                    onClick={() => {
                      closeGallery(utils);
                    }}
                  >
                    <IconButton
                      size="medium"
                      sx={{
                        color: "white",
                        ":hover": {
                          backgroundColor: "rgba(255, 255, 255, 0.08)",
                        },
                      }}
                    >
                      <ArrowBack sx={{ color: "white" }} />
                    </IconButton>
                  </button>{" "}
                  <div className="os_od">
                    {openedFile[0].osOdFeature === "od" ? (
                      <div className="flex_center">
                        <img
                          src={VisibilityIcon}
                          className="visibility_icon"
                        ></img>{" "}
                        OD
                      </div>
                    ) : (
                      ""
                    )}
                    {openedFile[0].osOdFeature === "os" ? (
                      <div className="flex_center">
                        <img
                          src={VisibilityIcon}
                          className="visibility_icon"
                        ></img>{" "}
                        OS
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Controls
                      {...utils}
                      zoomLevel={zoomLevel}
                      zoomInClicked={zoomInClicked}
                      setZoomLevel={setZoomLevel}
                      setZoomInClicked={setZoomInClicked}
                    />
                    <Share
                      multiselectedUrls={
                        Array.isArray(openedFile[0].downloadURL)
                          ? openedFile[0].downloadURL
                          : [openedFile[0].downloadURL]
                      }
                      galleryVisible={galleryVisible}
                    />

                    <Tooltip
                      title="Save To Device"
                      slotProps={{
                        popper: {
                          modifiers: [
                            {
                              name: "offset",
                              options: {
                                offset: [0, -14],
                              },
                            },
                          ],
                        },
                      }}
                    >
                      <IconButton
                        size="medium"
                        sx={{
                          padding: "8px",
                          color: "white",
                          ":hover": {
                            backgroundColor: "rgba(255, 255, 255, 0.08)",
                          },
                        }}
                      >
                        <button
                          className="button_no_style download_button_file"
                          onClick={() => {
                            handleDownload({
                              name: openedFile[0].globalId,
                              url: openedFile[0].downloadURL,
                              size: openedFile[0].size,
                              thumbnail: openedFile[0].thumbnailURL,
                            });
                          }}
                          disabled={downloadingFiles.has(
                            openedFile[0].globalId
                          )}
                        >
                          <div className="flex_column">
                            <img
                              src={
                                downloadingFiles.has(openedFile[0].globalId)
                                  ? DownloadIconDisabled
                                  : DownloadIcon
                              }
                              style={{
                                maxWidth: "100%",
                                width: "24px",
                              }}
                            />
                          </div>
                        </button>
                      </IconButton>
                    </Tooltip>
                    <Delete
                      galleryVisible={galleryVisible}
                      multiselectedUrls={multiselectedUrls}
                      setMultiselectedUrls={setMultiselectedUrls}
                      currentUser={currentUser}
                      reloadCallback={reloadCallback}
                      sessionId={sessionId}
                      multiselectedFiles={multiselectedFiles}
                      setMultiselectedFiles={setMultiselectedFiles}
                      openedFile={
                        Array.isArray(openedFile) ? openedFile : [openedFile]
                      }
                      setOpenedFile={setOpenedFile}
                      setGalleryVisible={setGalleryVisible}
                    />
                  </div>
                </div>

                <div className="gallery_view">
                  <IconButton
                    size="medium"
                    sx={{
                      padding: "12px",
                      color: "white",
                      ":hover": {
                        zIndex: 3,
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                      },
                    }}
                  >
                    <button
                      ref={previousBtn}
                      onClick={() => {
                        handlePreviousFile(utils);
                      }}
                      className="button_no_style prev_btn"
                      disabled={!hasPreviousFiles}
                    >
                      <ArrowBackIosNew
                        sx={{ color: hasPreviousFiles ? "white" : "gray" }}
                      />
                    </button>
                  </IconButton>
                  <TransformComponent style={{ overflow: "visible" }}>
                    {openedFile[0].globalId.includes("VIDEO") ? (
                      <div>
                        <video
                          controls
                          alt=""
                          key={currentIndex}
                          id="myimg"
                          autoPlay
                          className="auto_margin"
                          onError={async (e) => {
                            try {
                              const refetchedMedia =
                                await firestoreRepository.refetchDownloadUrls(
                                  currentUser.uid,
                                  openedFile[0]
                                );

                              setOpenedFile((prevVideos) => [
                                ...prevVideos.filter(
                                  (video) =>
                                    video.globalId !== openedFile[0].globalId
                                ),
                                refetchedMedia,
                              ]);
                            } catch (error) {
                              console.error("Error refreshing video URL:", error);
                            }
                          }}
                        >
                          <source src={openedFile[0].downloadURL}/>
                        </video>
                      </div>
                    ) : (
                      <img
                        key={currentIndex}
                        src={openedFile[0].downloadURL}
                        className="auto_margin_img"
                        alt=""
                        id="myimg"
                        onError={async (e) => {
                          try {
                            const refetchedMedia =
                              await firestoreRepository.refetchDownloadUrls(
                                currentUser.uid,
                                openedFile[0]
                              );

                            setOpenedFile((prevImages) => [
                              ...prevImages.filter(
                                (image) =>
                                  image.globalId !== openedFile[0].globalId
                              ),
                              refetchedMedia,
                            ]);
                          } catch (error) {
                            console.error("Error refreshing image URL:", error);
                          }
                        }}
                      />
                    )}
                  </TransformComponent>
                  <IconButton
                    size="medium"
                    sx={{
                      padding: "12px",
                      color: "white",
                      ":hover": {
                        backgroundColor: "rgba(255, 255, 255, 0.08)",
                      },
                    }}
                  >
                    <button
                      ref={nextBtn}
                      onClick={() => {
                        handleNextFile(utils);
                      }}
                      className="button_no_style prev_btn"
                      disabled={!hasNextFiles}
                    >
                      <ArrowForwardIosIcon
                        sx={{ color: hasNextFiles ? "white" : "gray" }}
                      />
                    </button>
                  </IconButton>
                </div>
              </div>
              <div ref={fileCounter} className="file_counter">
                {currentIndex + 1} / {allFiles.length}
              </div>
            </div>
          )
        )}
      </TransformWrapper>
    </div>
  );
};
export default DetailedView;
