import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { db, storage } from "../../firebase";
import { doc, setDoc, updateDoc, arrayUnion } from "firebase/firestore";
import { uploadBytesResumable, getDownloadURL, ref } from "firebase/storage";
import { serverTimestamp } from "firebase/firestore";
import firestoreRepository from "../../repositories/firestoreRepository";
import addImage from "../../images/gallery/addImage.svg";
import addImageActive from "../../images/gallery/addImageActive.svg";
import addImageRejected from "../../images/gallery/addImageRejected.svg";
import { ClientJS } from "clientjs";
import { format } from "date-fns";

const baseStyleee = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 16,
  borderColor: "rgba(61, 56, 56, 0.58)",
  borderStyle: "dashed",
  backgroundColor: "rgba(239, 239, 239, 0.6)",
  color: "#3D3838",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const acceptStyle = {
  borderColor: "#8F4FFF",
  backgroundColor: "#F1E7FF",
};

const rejectStyle = {
  borderColor: "#ff1744",
  background: "rgba(255, 23, 68, 0.03)",
};

const Upload = ({
  setUploadProgress,
  setUploadThumbnails,
  setFiles,
  setRemainingTimes,
  currentUser,
  reloadCallback,
  renderVideo,
  setRenderVideo,
  sessionId,
  largestSubscriptionId,
  userData,
}) => {
  const [renderImage, setRenderImage] = useState(null);
  const fullStorage = userData && userData.storageEmailLastThreshold === 1;

  function getNewGlobalId(documentType) {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint().toString().slice(0, 8);
    const first2 = currentUser.uid.slice(0, 5);
    const datestring = format(new Date(), "yyyy-MM-dd_HH:mm:ss:SSS");
    const prefix = documentType.toUpperCase() + "_WEB_";
    return `${prefix}${first2}-${fingerprint}_${datestring}`;
  }

  const onDrop = useCallback(async (files) => {
    setFiles(files);

    const uploadFile = async (fileItem, i, type) => {
      const globalId = getNewGlobalId(type);
      const fileType = fileItem.type;
      const resultType = fileType.slice(6);
      const data = {
        url: `${currentUser.uid}/${type}s/${globalId}.${resultType}`,
        modifiedAt: serverTimestamp(),
        createdAt: serverTimestamp(),
        globalId: globalId,
        deleted: false,
        isInCloudStorage: false,
        keepMode: 0,
        osOdFeature: "none",
      };
      const docRef = doc(
        db,
        `usersV2/${currentUser.uid}/sessions/${sessionId}`
      );
      await setDoc(
        doc(db, `usersV2/${currentUser.uid}/${type}s/${globalId}`),
        data
      );
      const data2 = {
        [`${type}GlobalIds`]: arrayUnion(globalId),
        modifiedAt: serverTimestamp(),
      };
      await updateDoc(docRef, data2);

      const storageRef = ref(
        storage,
        `/${currentUser.uid}/${type}s/${globalId}.${resultType}`
      );
      const uploadTask = uploadBytesResumable(storageRef, fileItem);
      const uploadStartTime = new Date().getTime();

      uploadTask.on("state_changed", (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [fileItem.name]: { progress, sessionId: sessionId },
        }));
        type === "image" ? setRenderImage(0) : setRenderVideo(0);
        if (snapshot.state === "running" && progress > 1) {
          const timeElapsed = new Date().getTime() - uploadStartTime;
          const uploadSpeed = (snapshot.bytesTransferred / timeElapsed) * 1000;
          const bytesRemaining =
            snapshot.totalBytes - snapshot.bytesTransferred;
          const timeRemaining = bytesRemaining / uploadSpeed;
          const minutes = Math.floor(timeRemaining / 60);

          setRemainingTimes((prevTimes) => ({
            ...prevTimes,
            [fileItem.name]: minutes,
          }));
        }
      });

      uploadTask.on("state_changed", {
        complete: async function () {
          try {
            const docRef = doc(
              db,
              `usersV2/${currentUser.uid}/${type}s/${globalId}`
            );
            await updateDoc(docRef, { isInCloudStorage: true });
            await firestoreRepository.updateLastDeviceSync(currentUser.uid);
            reloadCallback();
          } catch (err) {
            console.log("Error displaying uploaded document: ", err);
          }

          type === "image" ? setRenderImage(i + 1) : setRenderVideo(i + 1);
        },
        error: function (err) {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [fileItem.name]: { error: err.message, sessionId: sessionId },
          }));
        },
      });
    };

    const uploadPromises = files.map(async (fileItem, i) => {
      setUploadThumbnails((prevThumbnails) => ({
        ...prevThumbnails,
        [fileItem.name]: URL.createObjectURL(fileItem),
      }));

      if (fileItem.type === "image/jpeg" || fileItem.type === "image/png") {
        await uploadFile(fileItem, i, "image");
      } else if (fileItem.type === "video/mp4") {
        await uploadFile(fileItem, i, "video");
      }
    });

    await Promise.all(uploadPromises);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (renderImage === 0 || renderVideo === 0) {
        e.preventDefault();
        e.returnValue = "";
        return "Please wait for the files to finish uploading.";
      }
    };
    window.addEventListener("hashchange", handleBeforeUnload);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("hashchange", handleBeforeUnload);
    };
  }, [renderImage, renderVideo]);

  const acceptedFiles =
    userData && userData.stripeSubscriptionId === 1
      ? { "image/jpeg": [], "image/png": [] }
      : { "image/jpeg": [], "image/png": [], "video/mp4": [] };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: acceptedFiles,
      disabled: fullStorage,
    });

  const getStyle = (isFocused, isDragAccept, isDragReject, fullStorage) => ({
    ...baseStyleee,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject || fullStorage ? rejectStyle : {}),
  });

  const style = useMemo(
    () => getStyle(isFocused, isDragAccept, isDragReject, fullStorage),
    [isFocused, isDragAccept, isDragReject, fullStorage]
  );

  return (
    <div>
      <section className="upload-section">
        <div
          {...getRootProps({
            style,
          })}
        >
          <input {...getInputProps()} />
          <div className="upload-div">
            {isFocused || isDragAccept ? (
              <img src={addImageActive}></img>
            ) : isDragReject || fullStorage ? (
              <img src={addImageRejected}></img>
            ) : (
              <img src={addImage}></img>
            )}
            <p className="upload-p">
              {largestSubscriptionId === 1
                ? "  Add images"
                : "  Add images/videos"}
            </p>
          </div>
          <div>or drop files to upload</div>
          <i className="upload-i">
            {largestSubscriptionId === 1
              ? "We currently support JPEG and PNG."
              : "We currently support JPEG, PNG and MP4."}
          </i>
        </div>
      </section>
    </div>
  );
};

export default Upload;
