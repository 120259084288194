import { IconButton, Tooltip } from "@mui/material";
import { Close, ExpandLess, ExpandMore } from "@mui/icons-material";
import success_upload from "../../images/upload/success_upload.svg";
import select from "../../images/upload/Select.svg";
import { UploadContext } from "../App";
import React, { useContext, useEffect, useState } from "react";
import { ClipLoader, MoonLoader } from "react-spinners";
import firestoreRepository from "../../repositories/firestoreRepository";
import { useAuth } from "../../context/AuthContext";
import pause from "../../images/gallery/Pause.svg";

const DownloadModal = () => {
  const { currentUser } = useAuth();
  const [refetchedFiles, setRefetchedFiles] = useState({});
  const { downloadFiles, setDownloadFiles } = useContext(UploadContext);
  const { downloadProgress, setDownloadProgress } = useContext(UploadContext);
  const { renderVideo, setRenderVideo } = useContext(UploadContext);
  const { sessionId } = useContext(UploadContext);
  const [hover, setHover] = useState({});
  const { downloadThumbnails } = useContext(UploadContext);
  const { remainingDownloadTime, setRemainingDownloadTime } =
    useContext(UploadContext);
  const [statusMessage, setStatusMessage] = useState("Starting upload...");
  const [collapsedUpload, setCollapsedUpload] = useState(() => {
    const storedValue = localStorage.getItem("isCollapsed");
    return storedValue ? JSON.parse(storedValue) : false;
  });
  const { closeUpload, setCloseUpload } = useContext(UploadContext);
  const [completedFiles, setCompletedFiles] = useState([]);

  useEffect(() => {
    const completed = Object.entries(downloadProgress).filter(
      ([fileName, progress]) => progress === 100
    );
    const completedFileNames = completed.map(
      ([fileName, progress]) => fileName
    );
    setCompletedFiles(completedFileNames);
  }, [downloadProgress]);

  useEffect(() => {
    const values = Object.values(remainingDownloadTime);
    const maxTime = values.length > 0 ? Math.max(...values) : 0;
    if (maxTime < 1) {
      setStatusMessage("Less than a minute left...");
    } else if (maxTime === 1) {
      setStatusMessage("1 minute left...");
    } else {
      setStatusMessage(`${maxTime} minutes left...`);
    }
  }, [remainingDownloadTime]);

  useEffect(() => {
    localStorage.setItem("isCollapsed", JSON.stringify(collapsedUpload));
  }, [collapsedUpload]);

  useEffect(() => {
    if (downloadFiles.length !== 0) {
      setCloseUpload(false);
    }
    localStorage.setItem("isClosed", JSON.stringify(closeUpload));
  }, [closeUpload, downloadFiles]);

  function CircularProgress({ progress }) {
    const radius = 10;
    const circumference = 2 * Math.PI * radius;
    const strokeDashoffset = circumference - (progress / 100) * circumference;

    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        style={{
          transform: "rotate(-90deg)",
        }}
      >
        <circle
          stroke="#E1AFD1"
          fill="transparent"
          strokeWidth="4"
          r={radius}
          cx="12"
          cy="12"
        />
        <circle
          stroke="#8f4fff"
          fill="transparent"
          strokeWidth="4"
          strokeDasharray={circumference}
          style={{ strokeDashoffset }}
          r={radius}
          cx="12"
          cy="12"
        />
      </svg>
    );
  }
  return (
    <>
      {!closeUpload && downloadProgress.length !== 0 ? (
        <div className="border-div">
          <div>
            <div className="flex-space-between">
              <div className="bold-text">Downloads</div>
              <div className="flex-center">
                <IconButton
                  className="button_no_style"
                  onClick={() => setCollapsedUpload(!collapsedUpload)}
                >
                  {!collapsedUpload && downloadProgress.length !== 0 ? (
                    <ExpandMore
                      fontSize="medium"
                      style={{ color: "#333333" }}
                    />
                  ) : (
                    <ExpandLess
                      fontSize="medium"
                      style={{ color: "#333333" }}
                    />
                  )}
                </IconButton>
                <IconButton
                  onClick={() => {
                    setCloseUpload(true);
                    setDownloadProgress([]);
                  }}
                >
                  <Close fontSize="medium" style={{ color: "#333333" }} />
                </IconButton>
              </div>
            </div>
            <hr className="custom-hr"></hr>

            <div className="flex-center-bg">
              {completedFiles.length > 0 &&
              Object.values(downloadProgress).every(
                (progress) => progress === 100
              ) ? (
                <>
                  <img
                    src={success_upload}
                    className="fixed-width-img-40"
                  ></img>
                  <div className="padding-10-font-15">
                    {completedFiles.length > 0 &&
                      `${completedFiles.length} out of ${
                        Object.keys(downloadProgress).length
                      } ${
                        completedFiles.length > 1 ? "items" : "item"
                      } downloaded successfully!`}
                  </div>
                </>
              ) : (
                <div className="padding-left-25">
                  {Object.keys(remainingDownloadTime).length === 0
                    ? "Starting download..."
                    : statusMessage}
                </div>
              )}
            </div>
          </div>
          {collapsedUpload ? (
            ""
          ) : (
            <div>
              <div className="upload_progress scrollable-div">
                {Object.entries(downloadProgress).map(
                  ([fileName, progress]) => {
                    return (
                      <div
                        key={fileName}
                        className="flex-wrap-center"
                        style={{
                          backgroundColor: hover[fileName]
                            ? "#F6F6F6"
                            : "white",
                        }}
                        onMouseEnter={() =>
                          setHover((prevHover) => ({
                            ...prevHover,
                            [fileName]: true,
                          }))
                        }
                        onMouseLeave={() =>
                          setHover((prevHover) => ({
                            ...prevHover,
                            [fileName]: false,
                          }))
                        }
                      >
                        {fileName.startsWith("VIDEO") ? (
                          <div className="relative-inline-block">
                            <svg className="play_button" viewBox="0 0 24 24">
                              <path d="M8 5v14l11-7z" />
                              <path d="M0 0h24v24H0z" fill="none" />
                            </svg>
                            <img
                              src={downloadThumbnails[fileName]}
                              className="thumbnail_image"
                              onError={async (e) => {
                                try {
                                  const refetchedFile =
                                    await firestoreRepository.refetchDownloadUrls(
                                      currentUser.uid,
                                      fileName
                                    );
                                  if (refetchedFile) {
                                    e.target.src = refetchedFile.downloadURL;
                                    setRefetchedFiles((prevFiles) => ({
                                      ...prevFiles,
                                      [fileName]: refetchedFile.downloadURL,
                                    }));
                                  }
                                } catch (error) {
                                  console.error("Error refreshing URL:", error);
                                }
                              }}
                            ></img>
                          </div>
                        ) : (
                          <img
                            src={downloadThumbnails[fileName]}
                            className="thumbnail_image"
                            onError={async (e) => {
                              try {
                                const refetchedFile =
                                  await firestoreRepository.refetchDownloadUrls(
                                    currentUser.uid,
                                    fileName
                                  );
                                if (refetchedFile) {
                                  e.target.src = refetchedFile.downloadURL;
                                  setRefetchedFiles((prevFiles) => ({
                                    ...prevFiles,
                                    [fileName]: refetchedFile.downloadURL,
                                  }));
                                }
                              } catch (error) {
                                console.error("Error refreshing URL:", error);
                              }
                            }}
                          />
                        )}
                        <div className="margin-right-left-1em">
                          <Tooltip title={fileName || ""}>
                            <div
                              style={{
                                color: "#333",
                              }}
                            >
                              {fileName.length > 20
                                ? fileName.substring(0, 20) + "..."
                                : fileName}
                            </div>
                          </Tooltip>
                        </div>
                        <div className="margin-left-auto">
                          {progress === "none" ? (
                            <MoonLoader
                              color="#8f4fff"
                              size={20}
                              loading={true}
                            />
                          ) : progress === 100 ? (
                            <img
                              src={select}
                              className="fixed-width-img-24"
                            ></img>
                          ) : (
                            <CircularProgress progress={progress} />
                          )}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default DownloadModal;
