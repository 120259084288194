import { getDownloadURL, getMetadata, ref } from "firebase/storage";
import { storage } from "../firebase";

/**
 * Retrieves the download URLs for the given video.
 * @param {Object} video - The video object.
 * @returns {Promise<Object>} - A promise that resolves to an object containing the download URLs.
 */
export async function getDownloadUrlsFromVideo(video) {
  if (video && video.url) {
    try {
      const videoRef = ref(storage, video.url); // Create a reference to the video
      try {
        await getMetadata(videoRef); // Check if the video reference is valid
      } catch (error) {
        console.error(
          `Error getting download URL for video path ${video.url}: Video reference is invalid.`,
          error
        );
        return { success: false, failureType: "invalidRef" };
      }
      const downloadUrl = await getDownloadURL(videoRef);
      const thumbnailUrl = getVideoThumbnailUrl(video.url);
      const thumbnailRef = ref(storage, thumbnailUrl);
      // const thumbnailDownloadUrl = await getDownloadURL(thumbnailRef);
      let thumbnailDownloadUrl;
      const maxRetries = 3; // Set a maximum number of retries
      let attempts = 0;

      while (attempts < maxRetries) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 1000)); // Wait for 1 second before trying again
          thumbnailDownloadUrl = await getDownloadURL(thumbnailRef);
          break; // If the thumbnail exists, break the loop
        } catch (error) {
          attempts++;
          if (attempts >= maxRetries) {
            console.error(
              `Error getting download URL for thumbnail path ${thumbnailUrl}: Thumbnail does not exist after ${maxRetries} attempts.`,
              error
            );
            return { success: false, failureType: "thumbnailNotFound" };
          }
        }
      }
      return {
        success: true,
        downloadUrl,
        thumbnailDownloadUrl,
        sessionThumbnailDownloadUrl: thumbnailDownloadUrl,
      };
    } catch (error) {
      console.error(
        `Error getting download URL for video path ${video.url}:`,
        error
      );
      return { success: false, failureType: "fetchError" };
    }
  }
}

/**
 * Generates the URL for a thumbnail video based on the original video URL.
 * @param {string} url - The URL of the original video.
 * @returns {string} The URL of the thumbnail video.
 */
export function getVideoThumbnailUrl(url) {
  const index = url.lastIndexOf("/");
  const filename = url.slice(index + 1);
  const extensionIndex = filename.lastIndexOf(".");
  const extension = filename.slice(extensionIndex);
  const thumbnailFilename = filename.replace(extension, ".jpg");
  const thumbnailUrl = `${url.slice(
    0,
    index + 1
  )}thumbnails/${thumbnailFilename}`;
  return thumbnailUrl;
}

export default {
  getDownloadUrlsFromVideo,
  getVideoThumbnailUrl,
};
