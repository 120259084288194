import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import { useAuth } from "../../context/AuthContext";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "../../firebase";
import { useLayoutEffect } from "react";
import modal from "../../images/login/modal.png";
import smallmodal from "../../images/login/smallmodal.png";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "black",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Subscribe = ({ postName, tag, ...props }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => setOpen(false);
  const [error, setError] = useState("");
  const code = urlParams.get("code");
  const [activationCode, setActivationCode] = useState(code);
  const [open, setOpen] = useState(code != null);
  const [authToken, setAuthToken] = useState(null);
  const { currentUser } = useAuth();
  const [usersV2, setUsersV2] = useState(null);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useLayoutEffect(() => {
    const fetchDocument = async () => {
      setIsLoading(true);
      const docRef = doc(db, "usersV2", currentUser.uid);
      const docSnapV2 = await getDoc(docRef);
      if (docSnapV2.exists()) {
        setUsersV2(docSnapV2.data());
      }
      setIsLoading(false);
    };
    fetchDocument();
  }, []);

  if (!isMounted) {
    return null;
  }

  if (isLoading) {
    return null;
  }
  if (!usersV2) {
    return null;
  }

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <button
          onClick={() => setOpen(true)}
          style={{
            padding: "10px 40px",
            color: "white",
            backgroundColor: "#8F4FFF",
            borderRadius: "20px",
            fontSize: "16px",
            display: "flex",
            alignItems: "center",
            textDecoration: "none",
            fontWeight: "600",
            justifyContent: "center",
            border: "none",
          }}
        >
          Get more info
        </button>

        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"lg"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box
                sx={{
                  display: { xs: "none", md: "block", lg: "block" },
                  paddingLeft: 4,
                  paddingTop: 4,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "2em",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Manage Subscription
                  </div>
                </div>
                <div style={{ display: "flex" }}>
                  <div>
                    <div>
                      You can only manage your subscription here if you have
                      subscribed on MicroREC Connect.
                    </div>
                    <p style={{ marginBottom: "2em" }}>
                      You can subscribe here.
                    </p>
                    <a
                      href="/subscription"
                      style={{
                        padding: "10px 20px",
                        backgroundColor: "#8F4FFF",
                        color: "white",
                        borderRadius: "20px",
                        textDecoration: "none",
                        fontWeight: "600",
                        width: "max-content",
                      }}
                    >
                      Try for Free & Subscribe
                    </a>
                    <div style={{ marginTop: "2em" }}>
                      If you have subscribed in the MicroREC App through the
                      <b> App Store</b> or the <b>Play Store</b>, you can always
                      cancel those subscriptions, and subscribe here to get the
                      best prices.{" "}
                    </div>
                    <div style={{ marginTop: "2em", fontWeight: "600" }}>
                      FAQs
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <a
                        href="https://support.apple.com/en-us/HT202039"
                        target="_blank"
                        style={{
                          fontStyle: "italic",
                          color: "#3D3838",
                        }}
                      >
                        How to cancel my subscription on the App Store (iPhone
                        or iPad devices)?
                      </a>
                    </div>
                    <div style={{ marginTop: "1em" }}>
                      <a
                        style={{
                          fontStyle: "italic",
                          color: "#3D3838",
                        }}
                        href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid#zippy=%2Ccancel-a-subscription-on-the-google-play-app"
                        target="_blank"
                      >
                        How to cancel my subscription on the Play Store (Android
                        devices)?
                      </a>
                    </div>
                  </div>
                  <img
                    src={modal}
                    style={{ maxHeight: "500px", objectFit: "contain" }}
                  ></img>
                </div>
              </Box>
              <Box
                sx={{
                  display: { xs: "block", md: "none", lg: "none" },

                  paddingLeft: 2,
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "1em",
                  }}
                >
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      fontWeight: "600",
                    }}
                  >
                    Manage Subscription
                  </div>
                </div>
                <div style={{ display: "flex", alignItems: " center" }}>
                  <div>
                    <div>
                      You can only manage your subscription here if you have
                      subscribed on MicroREC Connect.
                    </div>
                    <p style={{ marginBottom: "2em" }}>
                      You can subscribe here.
                    </p>
                    <a
                      href="/subscription"
                      style={{
                        padding: "10px 15px",
                        backgroundColor: "#8F4FFF",
                        color: "white",
                        borderRadius: "20px",
                        textDecoration: "none",
                        fontWeight: "600",
                        width: "max-content",
                        display: "block",
                      }}
                    >
                      Try for Free & Subscribe
                    </a>
                  </div>
                  <img src={smallmodal}></img>
                </div>

                <div style={{ marginTop: "1em" }}>
                  If you have subscribed in the MicroREC App through the
                  <b> App Store</b> or the <b>Play Store</b>, you can always
                  cancel those subscriptions, and subscribe here to get the best
                  prices.{" "}
                </div>
                <div style={{ marginTop: "1em", fontWeight: "600" }}>FAQs</div>
                <div style={{ marginTop: "1em" }}>
                  <a
                    href="https://support.apple.com/en-us/HT202039"
                    target="_blank"
                    style={{
                      fontStyle: "italic",
                      color: "#3D3838",
                    }}
                  >
                    How to cancel my subscription on the App Store (iPhone or
                    iPad devices)?
                  </a>
                </div>
                <div style={{ marginTop: "1em" }}>
                  <a
                    style={{
                      fontStyle: "italic",
                      color: "#3D3838",
                    }}
                    href="https://support.google.com/googleplay/answer/7018481?hl=en&co=GENIE.Platform%3DAndroid#zippy=%2Ccancel-a-subscription-on-the-google-play-app"
                    target="_blank"
                  >
                    How to cancel my subscription on the Play Store (Android
                    devices)?
                  </a>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Subscribe;
