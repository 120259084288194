import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import {
  doc,
  getDocs,
  serverTimestamp,
  setDoc,
  collection,
  updateDoc,
} from "@firebase/firestore";
import { db } from "../../firebase";
import { ClientJS } from "clientjs";
import { useNavigate } from "react-router-dom";
import { getCanonicalPatientId } from "../../utils/patientUtils";
import firestoreRepository from "../../repositories/firestoreRepository";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(3),
    color: "#3D3838",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "&.MuiDialog-paper": {
    borderRadius: "30px",
  },
  "&.MuiPaper-root": {
    borderRadius: "30px",
  },
}));

const BootstrapDialogContent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogContent sx={{ m: 0, p: 2 }} {...other}>
      {onClose ? (
        <IconButton
          aria-labels="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#3D3838",
            zIndex: "10",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
      {children}
    </DialogContent>
  );
};

BootstrapDialogContent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Modal = ({ tags, userId }) => {
  const handleClose = () => setOpen(false);
  const [open, setOpen] = useState(false);
  const [newTag, setNewTag] = useState("");
  const [allTags, setAllTags] = useState(tags);
  const [patientId, setPatientId] = useState("");
  const [description, setDescription] = useState("");
  const [patientIdDuplicate, setPatientIdDuplicate] = useState();
  const [tagsSelected, setTagsSelected] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(
        collection(db, "usersV2/" + `${userId}` + "/patients/")
      );
      var tempPatients = {};
      querySnapshot.forEach((patient) => {
        const globalId = patient.data().globalId;
        const canonicalId = patient.data().canonicalPatientId;
        tempPatients[globalId] = canonicalId;
      });
      setPatientIdDuplicate(tempPatients);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setAllTags(tags);
  }, [tags]);

  const handleTextAreaChangePatient = (e) => {
    setPatientId(e.target.value);
  };

  const handleTextAreaChangeDescription = (e) => {
    setDescription(e.target.value);
  };

  function getNewGlobalId(documentType) {
    const client = new ClientJS();
    const fingerprint = client.getFingerprint().toString();
    const slicedFingerpring = fingerprint.slice(0, 8);
    const str = `${userId}`;
    const first2 = str.slice(0, 5);
    const d = new Date();
    const datestring =
      d.getFullYear() +
      "-" +
      ("0" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("0" + d.getDate()).slice(-2) +
      "_" +
      ("0" + d.getHours()).slice(-2) +
      ":" +
      ("0" + d.getMinutes()).slice(-2) +
      ":" +
      ("0" + d.getSeconds()).slice(-2) +
      ":" +
      ("0" + d.getMilliseconds()).slice(-3);
    if (documentType === "session") {
      return (
        "SESSION_WEB_" +
        `${first2}` +
        "-" +
        slicedFingerpring +
        "_" +
        datestring
      );
    } else if (documentType === "patient") {
      return (
        "PATIENT_WEB_" +
        `${first2}` +
        "-" +
        slicedFingerpring +
        "_" +
        datestring
      );
    }
  }

  const createNewSession = async () => {
    const updateTime = serverTimestamp();
    const canonicalId = getCanonicalPatientId(patientId);
    const sessionId = getNewGlobalId("session");
    const patientGlobalId = getNewGlobalId("patient");

    const duplicatedPatient = Object.entries(patientIdDuplicate).find(
      ([_, value]) => value === canonicalId
    );

    const sessionRef = doc(
      db,
      "usersV2/" + `${userId}` + "/sessions/" + sessionId
    );
    const patientRef = doc(
      db,
      "usersV2/" + `${userId}` + "/patients/" + patientGlobalId
    );

    if (!duplicatedPatient) {
      const sessionData = {
        description: description,
        modifiedAt: updateTime,
        createdAt: updateTime,
        tags: tagsSelected,
        deleted: false,
        globalId: sessionId,
        imageGlobalIds: [],
        patientGlobalId: patientGlobalId,
        sessionName: "",
        videoGlobalIds: [],
      };

      await setDoc(sessionRef, sessionData);

      const patientData = {
        patientId: patientId,
        globalId: patientGlobalId,
        modifiedAt: updateTime,
        createdAt: updateTime,
        deleted: false,
        canonicalPatientId: canonicalId,
      };

      await setDoc(patientRef, patientData);
    } else {
      const [duplicatedPatientGlobalId, _] = duplicatedPatient;
      const duplicatedPatientRef = doc(
        db,
        "usersV2/" + `${userId}` + "/patients/" + duplicatedPatientGlobalId
      );
      const sessionData = {
        description: description,
        modifiedAt: updateTime,
        createdAt: updateTime,
        tags: tagsSelected,
        deleted: false,
        globalId: sessionId,
        imageGlobalIds: [],
        patientGlobalId: duplicatedPatientGlobalId,
        sessionName: "",
        videoGlobalIds: [],
      };

      await setDoc(sessionRef, sessionData);

      const patientData = {
        patientId: patientId,
        modifiedAt: updateTime,
      };

      await updateDoc(duplicatedPatientRef, patientData, { merge: true });
    }
    await firestoreRepository.updateLastDeviceSync(userId);
    window.Intercom("trackEvent", "create-new-session");
    navigateToSession(sessionId);
  };

  const navigateToSession = (id) => {
    const path = `/sessions/${id}`;
    navigate(path);
  };

  const handleOptionClick = (option) => {
    const index = tagsSelected.indexOf(option);

    if (index === -1) {
      setTagsSelected([...tagsSelected, option]);
    } else {
      let newTagsSelected = [...tagsSelected];
      newTagsSelected.splice(index, 1);
      setTagsSelected(newTagsSelected);
    }
  };

  const optionStylesActive = {
    backgroundColor: "#8F4FFF",
    color: "white",
    fontSize: "15px",
    padding: "5px 10px",
    borderRadius: "10px",
    border: "1px solid #8F4FFF",
  };
  const optionStyles = {
    backgroundColor: "white",
    color: "#8F4FFF",
    fontSize: "15px",
    padding: "5px 10px",
    borderRadius: "10px",
    border: "1px solid #8F4FFF",
  };

  const handleTagStyle = (tags) => {
    if (tagsSelected.includes(tags)) {
      return optionStylesActive;
    } else {
      return optionStyles;
    }
  };

  function addNewTag() {
    window.Intercom("trackEvent", "add-new-tag");

    const regex = /^\s+$/;
    if (regex.test(newTag) !== true && newTag !== "") {
      setAllTags((prevTags) => [...prevTags, newTag]);
      setTagsSelected([...tagsSelected, newTag]);
      setNewTag("");
    }
  }

  function handleKeyDownTag(event) {
    const regex = /^\s+$/;
    if (regex.test(newTag) !== true && newTag !== "") {
      if (event.key === "Enter") {
        event.preventDefault();
        addNewTag();
      }
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      event.preventDefault();
    }
  }

  return (
    <>
      <div>
        <button
          className="button_no_style"
          style={{
            marginRight: "1em",
            boxShadow: " 0px 2px 8px rgba(58, 92, 157, 0.14)",
            borderRadius: "24px",
            padding: "0px 20px",
            backgroundColor: "#8F4FFF",
            color: "white",
          }}
          onClick={() => setOpen(true)}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <AddIcon sx={{ color: "white" }} />
            </IconButton>
            <div style={{ fontSize: "16px" }}>New Session</div>
          </div>
        </button>
        <BootstrapDialog
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={open}
          maxWidth={"md"}
          fullWidth={true}
          alignItems="center"
          className="modal-sub"
          style={{ borderRadius: "30px", margin: "0" }}
        >
          <BootstrapDialogContent
            onClose={handleClose}
            dividers
            style={{
              backgroundColor: "#fff",
              padding: "0",
            }}
          >
            <>
              <Box>
                <div
                  style={{
                    backgroundColor: "#FDFDFD",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      paddingBottom: "1em",
                      boxShadow: "0px 4px 4px 0px rgba(50, 50, 71, 0.08)",
                    }}
                  >
                    <div
                      style={{
                        fontWeight: "700",
                      }}
                    >
                      Create New Session
                    </div>
                  </div>
                  <div
                    style={{
                      marginTop: "1em",
                      padding: "30px",
                    }}
                  >
                    <label className="label">Patient Id</label>
                    <div>
                      <textarea
                        value={patientId}
                        onKeyDown={handleKeyDown}
                        onChange={handleTextAreaChangePatient}
                      ></textarea>
                    </div>
                    <label className="label">Comment</label>
                    <div>
                      <textarea
                        value={description}
                        onChange={handleTextAreaChangeDescription}
                      ></textarea>
                    </div>
                    <label className="label">Tags</label>
                    <div
                      style={{
                        textAlign: "center",
                        marginBottom: "1em",
                        marginTop: "1em",
                        display: "flex",
                        flexWrap: "wrap",
                        boxShadow: "0px 2px 8px rgba(58, 92, 157, 0.14)",
                        padding: "0px 20px",
                      }}
                    >
                      {allTags.map((tag, index) => (
                        <div
                          style={{
                            display: "flex",
                            marginRight: "0.5em",
                            marginBottom: "20px",
                          }}
                          className="margin_top_wrap"
                        >
                          <button
                            key={index}
                            className="button_no_style"
                            onClick={() => {
                              handleOptionClick(tag);
                            }}
                            style={handleTagStyle(tag)}
                          >
                            <div key={tag}>
                              {`${tag}`
                                ? `${tag}`.length > 24
                                  ? `${tag}`.slice(0, 24) + "..."
                                  : `${tag}`
                                : ""}
                            </div>
                          </button>
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        textAlign: "right",
                        marginTop: "1em",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          width: "70%",
                          marginRight: "1em",
                        }}
                      >
                        <textarea
                          style={{
                            marginBottom: "1em",
                          }}
                          value={newTag}
                          onKeyDown={handleKeyDownTag}
                          onChange={(e) => setNewTag(e.target.value)}
                        ></textarea>
                      </div>

                      <button
                        className="button_link"
                        onClick={() => {
                          addNewTag();
                        }}
                      >
                        Add New Tag
                      </button>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className="button"
                        onClick={() => {
                          createNewSession();
                        }}
                      >
                        Create New Session
                      </button>
                    </div>
                  </div>
                </div>
              </Box>
            </>
          </BootstrapDialogContent>
        </BootstrapDialog>
      </div>
    </>
  );
};

export default Modal;
